.deceased-full-profile-wrapper {
  .deceased-full-profile-container {
    display: flex;
    align-items: flex-start;
    .item-card {
      word-break: break-word;
    }
    @media (max-width: 767px) {
      flex-direction: column;
      .dropdown.dropdown-search-filter .dropdown-menu {
        left: -50px !important;
        top: 20px !important;
      }
    }
  }
  .deceased-sidebars-wrapper {
    background: $white;
    min-width: 278px;
    max-height: calc(100vh - 70px - 60px - 41px - 37px);
    overflow-y: auto;
    margin-right: 16px;
    @media (max-width: 1023px) {
      min-width: 250px;
    }
    @media (max-width: 767px) {
      min-width: 250px;
      margin: 0 0 25px;
      padding: 10px;
      width: 100%;
      position: relative;
      max-height: unset;
      overflow-x: hidden;
    }
    .sidebar-icon-deceased {
      display: none;
      cursor: pointer;
      @media (max-width: 767px) {
        position: absolute;
        right: 20px;
        top: 20px;
        display: block;
      }
    }
    .deceased-profile-wrapper {
      background: $theme-table-nth;
      padding-top: 12px;
      @media (max-width: 767px) {
        max-width: 280px;
        margin: 0 auto;
      }
      .img-container {
        padding: 0 16px;
        @media (max-width: 767px) {
          padding: 0 10px;
        }
        .profile-picture-wrapper {
          max-width: 246px;
          background-color: #eeeeee;
          height: 226px;
          @include flex(center, center);
          @extend .lato-bold;
          font-size: 20px;
          opacity: 0.5;
          @media (max-width: 767px) {
            max-width: 200px;
            height: 190px;
          }
          &.map {
            max-width: 278px;
            height: 256px;
            @media (max-width: 767px) {
              max-width: 200px;
              height: 190px;
            }
          }
        }
      }
      .profile-name-wrapper {
        @extend .lato-bold;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        padding: 16px 0 20px;
        margin: 0;
        color: $theme-text;
      }
      .location-wrapper {
        padding: 18px 16px 10px;
        border-top: 1px solid rgba($theme-tertiary-text, 0.2);
        border-bottom: 1px solid rgba($theme-tertiary-text, 0.2);
        p {
          font-style: normal;
          @extend .lato-bold;
          font-size: 12px;
          line-height: 14px;
          cursor: pointer;
          text-transform: uppercase;
          color: $brand-primary;
          margin-bottom: 0;
        }
        a {
          @extend .lato-regular;
          font-style: normal;
          font-size: 13px;
          line-height: 22px;
          margin: 0 0 10px;
          display: block;
          text-decoration-line: underline;
          padding-top: 8px;
          color: $theme-secondary-text;
        }
        .burial-wrapper {
          padding: 12px 0 6px;
          @extend .lato-regular;
          font-style: normal;
          font-size: 13px;
          line-height: 22px;
          margin: 0;
          padding-top: 8px;
          color: $theme-secondary-text;
          border-top: 1px solid rgba($theme-tertiary-text, 0.2);
          &.burial-wrapper-without-line {
            border-top: 0;
          }
          &.super-admin-reserve-status {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .button.btn {
              font-size: 13px;
              display: flex;
              align-items: center;

              img {
                margin-right: 12px;
              }
            }

            .save-container {
              display: flex;
            }
          }
        }
        .select-wrapper {
          margin-bottom: 0px;
        }
        .burial-value-wrapper {
          font-style: normal;
          font-size: 14px;
          line-height: 17px;
          margin: 0;
          padding-bottom: 20px;
          @extend .lato-bold;
          color: $theme-secondary-text;
          @media (max-width: 767px) {
            padding-bottom: 5px;
          }
        }
        .burial-reservation-status.burial-reservation-status-with-edit {
          line-height: 36px !important;
        }
        hr {
          margin: 1rem 0 0;
        }
        &.property-wrapper {
          border-top: 0;
          padding-top: 8px;
        }
        //   span {
        //     @extend .lato-regular;
        //     font-style: normal;
        //     font-size: 12px;
        //     line-height: 14px;
        //     float: right;
        //     margin: 4px 8px 0 0;
        //     color: $theme-tertiary-text;
        //   }
        //   .toggle-switch input[type='checkbox'] {
        //     display: none;
        //   }
        //   .toggle-switch {
        //     display: inline-block;
        //     float: right;
        //   }
        //   .toggle-switch label {
        //     cursor: pointer;
        //     margin: 0;
        //   }
        //   .toggle-switch label .toggle-track {
        //     display: block;
        //     height: 10px;
        //     width: 26px;
        //     background: #b0b0b0;
        //     border-radius: 20px;
        //     position: relative;
        //     opacity: 0.5;
        //     margin: 0;
        //   }

        //   .toggle-switch .toggle-track:before {
        //     content: '';
        //     display: inline-block;
        //     height: 16px;
        //     width: 16px;
        //     background: $theme-text;
        //     box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.237602),
        //       0px 0px 1px rgba(0, 0, 0, 0.12);
        //     border-radius: 20px;
        //     position: absolute;
        //     top: -2px;
        //     right: 0;
        //     transition: right 0.2s ease-in;
        //   }

        //   .toggle-switch
        //     input[type='checkbox']:checked
        //     + label
        //     .toggle-track:before {
        //     right: 10px;
        //   }
        .activity-wrapper {
          cursor: pointer;
          margin: 0;
          font-size: 14px;
          border-top: 1px solid rgba($theme-tertiary-text, 0.2);
          &:active {
            color: $brand-primary;
            font-weight: 700 !important;
          }
          &:hover {
            color: $brand-primary;
            font-weight: 700 !important;
          }
          .active {
            color: $brand-primary;
            font-weight: 700 !important;
          }
        }
      }
    }
    .sidebar-menu-wrapper {
      list-style: none;
      padding: 10px 0;
      li {
        @extend .lato-bold;
        font-style: normal;
        font-size: 14px;
        line-height: 17px;
        padding: 12px 36px;
        cursor: pointer;
        color: $theme-secondary-text;
        margin-bottom: 3px;
        &:hover,
        &:active {
          background: #f3f3f3;
          color: $brand-primary;
          font-size: 16px;
          line-height: 19px;
        }
        &.active {
          background: #f3f3f3;
          color: $brand-primary;
          font-size: 16px;
          line-height: 19px;
        }
      }
      @media (max-width: 767px) {
        max-height: 250px;
        width: calc(100% - 20px);
        overflow-y: auto;
        position: absolute;
        right: 10px;
        top: 42px;
        transform: translate(calc(100% + 20px));
        background-color: $white;
        box-shadow: 0 3px 10px 0 $brand-default;
        display: none;
        &.active {
          transform: translate(0);
          display: block;
        }
      }
    }
  }
  .profile-modules-container {
    flex-grow: 1;
    max-height: calc(100vh - 70px - 60px - 41px - 37px);
    overflow-y: auto;
    @media (max-width: 767px) {
      max-height: unset;
      width: 100%;
      overflow: hidden;
    }
    .panel-container {
      .panel-body {
        padding: 0;
        > div:not(.add-note-wrapper) {
          border-top: 1px solid rgba($theme-tertiary-text, 0.2);
        }
      }
    }
    .container-profile-details {
      @media (max-width: 767px) {
        min-height: unset !important;
      }
      .panel-container {
        margin-bottom: 15px;
      }
      .card-details-container {
        padding: 25px 70px 0;
        display: flex;
        flex-wrap: wrap;
        @media (max-width: 1100px) {
          padding: 25px 38px 0;
        }
        @media (max-width: 767px) {
          padding: 20px 15px 0;
        }
        .item-card {
          width: 25%;
          padding-right: 15px;
          margin-bottom: 25px;
          @media (max-width: 767px) {
            margin-bottom: 20px;
            line-height: 20px;
            width: 50%;
          }
        }
        .comments-details-wrapper {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 24px;
          .clipped-text {
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            /* autoprefixer: off */
            -webkit-box-orient: vertical;
            /* autoprefixer: on */
            overflow: hidden;
            word-break: break-word;
          }
          .item-card-wrapper {
            padding-right: 15px;
            margin-bottom: 25px;
            width: 100%;
            display: flex;
            flex-direction: column;
            font-weight: 400;
            font-size: 16px;
            line-height: 25px;
            color: $theme-tertiary-text;
            .item-card-wrapper-value {
              color: $theme-text;
              word-break: break-word;
            }
          }
          &.interment-comment {
            border-bottom: 1px solid $theme-border-gray-fade;
            .item-card-wrapper-value {
              margin-bottom: 20px;
              line-height: 27px;
              @media (max-width: 767px) {
                margin-bottom: 0;
              }
            }
          }
          &.obituary-wrapper {
            .clipped-text {
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 5;
              /* autoprefixer: off */
              -webkit-box-orient: vertical;
              /* autoprefixer: on */
              overflow: hidden;
            }

            .item-card-wrapper {
              padding-right: 0;
              line-height: 27px;
              .item-card-wrapper-value {
                margin-bottom: 20px;
                line-height: 27px;
                a {
                  font-size: 14px;
                  line-height: 17px;
                  font-style: normal;
                  @extend .lato-bold;
                  color: $theme-tertiary-text;
                  text-decoration-line: underline;
                }
                @media (max-width: 767px) {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
      .card-details-container-wrapper {
        width: 100%;
        padding: 10px 2px 0 50px;
        @media (max-width: 1100px) {
          padding-left: 20px;
        }
        @media (max-width: 767px) {
          padding-left: 5px;
        }
        .card-heading {
          background: $theme-light-gray;
          padding: 10px 18px;
          font-style: normal;
          @extend .lato-bold;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.4px;
          text-transform: uppercase;
          color: $theme-tertiary-text;
          border-top: 1px solid rgba($theme-tertiary-text, 0.2);
          border-bottom: 1px solid rgba($theme-tertiary-text, 0.2);
        }
        .card-details-wrapper {
          width: 100%;
          display: flex;
          padding: 29px 18px 5px;
          flex-wrap: wrap;
          @media (max-width: 767px) {
            padding: 20px 10px 0;
          }
          .item-card-wrapper {
            padding-right: 15px;
            margin-bottom: 25px;
            width: 33.33%;
            display: flex;
            flex-direction: column;
            font-weight: 400;
            font-size: 16px;
            line-height: 25px;
            color: $theme-tertiary-text;
            .item-card-wrapper-value {
              color: $theme-text;
              word-break: break-all;
            }
            &.address-wrapper {
              width: 67%;
            }
            &.address-wrapper-full {
              width: 33%;
            }
            @media (max-width: 767px) {
              width: 50%;
              margin-bottom: 20px;
              line-height: 20px;
              &.address-wrapper {
                width: 50%;
              }
              &.address-wrapper-full {
                width: 50%;
              }
            }
          }
        }
      }
      &.container-genealogy-details {
        .notes-list-wrapper {
          padding: 10px 0;
          .no-data-message-comments {
            font-size: 18px;
            line-height: 27px;
            margin: 0;
            text-align: center;
            padding: 3rem 0;
            @media (max-width: 767px) {
              padding: 1rem 0;
            }
          }
          .title-comments {
            display: none;
          }
          .notes-item-group .notes-item {
            border-top: 0;
            &:not(:last-child) {
              border-bottom: 1px solid rgba($theme-tertiary-text, 0.2);
            }
            .dropdown {
              display: none;
            }
            .notes-item-left {
              max-width: 100%;
            }
          }
        }
      }
      &.container-custom-details {
        .item-card {
          width: 33.33%;
          @media (max-width: 767px) {
            width: 50%;
          }
        }
      }
      .table-container {
        tbody tr td,
        thead tr th {
          &:nth-of-type(1) {
            padding-left: 30px;
          }
          .checkbox-wrapper {
            margin-bottom: 0;
            margin-top: -2px;
            input[type='checkbox'] + span {
              margin: 0 20px 0 0 !important;
              width: 16px;
              height: 16px;
            }
          }
        }
      }
      .table-container-contacts {
        tbody tr td,
        thead tr th {
          &:nth-of-type(odd) {
            width: 30%;
          }
          &:nth-of-type(even) {
            width: 20%;
          }
        }
        @media (max-width: 767px) {
          .table {
            display: block;
            overflow-x: auto;
            tbody tr td,
            thead tr th {
              &:nth-of-type(odd) {
                width: 250px;
                min-width: 250px;
              }
              &:nth-of-type(even) {
                width: 150px;
                min-width: 150px;
              }
            }
          }
        }
      }
      .table-container-services {
        tbody tr td,
        thead tr th {
          &:nth-of-type(1) {
            width: 24%;
          }
          &:nth-of-type(2) {
            width: 14%;
          }
          &:nth-of-type(n + 3):not(:last-of-type) {
            width: 13%;
          }
          &:nth-of-type(6) {
            width: 22%;
          }
        }
        @media (max-width: 767px) {
          .table {
            display: block;
            overflow: auto;
            tbody tr td,
            thead tr th {
              &:nth-of-type(1) {
                width: 200px;
                min-width: 200px;
              }
              &:nth-of-type(n + 2) {
                width: 150px;
                min-width: 150px;
              }
            }
          }
        }
      }
      &.agreement-wrapper {
        .table-container-services {
          tbody tr td,
          thead tr th {
            &:nth-of-type(1) {
              width: 27%;
            }
            &:nth-of-type(2) {
              width: 27%;
            }
            &:nth-of-type(3) {
              width: 19%;
            }
            &:nth-of-type(4) {
              width: 27%;
            }
          }
          @media (max-width: 767px) {
            .table {
              display: block;
              overflow: auto;
              tbody tr td,
              thead tr th {
                &:nth-of-type(1),
                &:nth-of-type(2),
                &:nth-of-type(4) {
                  width: 220px;
                  min-width: 220px;
                }
                &:nth-of-type(3) {
                  width: 150px;
                  min-width: 150px;
                }
              }
            }
          }
        }
      }
      .property-details-details {
        @extend .lato-bold;
        font-style: normal;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: $theme-tertiary-text;
        margin: 2px 0 15px;
        text-transform: uppercase;
        span {
          text-transform: capitalize;
        }
      }
      .owner-edit-personal {
        .details-title {
          font-size: 16px;
        }
        .react-datepicker__input-container input {
          padding-right: 35px;
        }
        @media (max-width: 1023px) {
          .section-card-details {
            .row > div {
              max-width: 50%;
              flex: 0 0 50%;
            }
          }
          .react-datepicker-popper {
            left: auto !important;
            right: 0;
            .react-datepicker:before {
              left: auto;
              right: 60px;
            }
          }
        }
        @media (max-width: 767px) {
          .section-card-details {
            .row > div {
              max-width: 100%;
              flex: 0 0 100%;
            }
          }
        }
      }
    }
    .search-results-wrapper .header-search-results {
      @media (max-width: 1023px) {
        flex-direction: column;
        align-items: flex-start !important;
        h2 {
          margin: 0 0 15px !important;
        }
        .pagination-section {
          justify-content: flex-start;
          min-width: 0;
        }
      }
    }
    #activity-history-section {
      @media (max-width: 1023px) {
        table {
          display: block;
          overflow-x: auto;
          thead tr th,
          tbody tr td {
            width: 200px;
            min-width: 200px;
            &:nth-of-type(1),
            &:nth-of-type(3) {
              width: 150px;
              min-width: 150px;
            }
          }
        }
      }
    }
  }
}
