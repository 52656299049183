.tab-headers {
  display: flex;
  margin: 8px 0 24px 0;
  border: 0;
  justify-content: space-evenly;
  background-color: $white;

  &.pull-left {
    justify-content: flex-start;

    .tab-header.nav-item {
      flex-grow: 0;
    }
  }

  .tab-header.nav-item {
    width: auto;
    flex-grow: 1;
    .nav-link {
      color: rgba($theme-secondary-text, 0.5);
      border: 0;
      height: 70px;
      cursor: pointer;
      position: relative;
      font-size: 18px;
      line-height: 22px;
      @extend .d-flex;
      @extend .justify-content-center;
      @extend .align-items-center;
      &.active {
        color: $theme-secondary-text;
        background-color: transparent;
        @extend .lato-bold;
        &::after {
          background-color: $theme-secondary-text;
        }
      }
      &::after {
        left: 0;
        width: 100%;
        bottom: 0;
        height: 4px;
        content: '';
        position: absolute;
        background-color: transparent;
      }
    }
  }
}
