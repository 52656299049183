.mausoleum-wrapper {
  @mixin background($bg) {
    background: rgba($bg, 0.32);
    &:before {
      background: rgba($bg, 9);
    }
  }
  @mixin backgroundColor($bg-light, $bg) {
    background: $bg;
    &:before {
      background: $bg-light;
    }
  }
  .map-building-module {
    height: 100vh;
    display: flex;
    // max-height: 100vh;
    position: relative;
    .table-container-map-building {
      padding: 16px 0 0 42px;
      &.table-scroll-wrapper {
        width: 100%;
        max-width: 610px;
      }
      .scrollable-data-wrapper {
        overflow: auto;
        max-height: calc(100vh - 85px);
        width: 100%;
      }
      .table-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 18px;
        p {
          margin-bottom: 0;
          @include font($lato, 500, 16px, $black);
          font-style: normal;
          line-height: 19px;
          margin-right: 5px;
        }
      }
      .table {
        // margin-top: 18px;
        // margin-left: 3px;
        tbody {
          tr {
            td {
              font-family: 'Lato', sans-serif;
              font-weight: 700;
              font-size: 12px;
              color: $white;
              text-decoration-line: underline;
              line-height: 160%;
              vertical-align: middle;
              min-height: 68px;
              min-width: 71px;
              white-space: nowrap;
              text-align: center;
              cursor: pointer;
              padding: 22px 21px;
              border-right: 1px solid $white;
              border-bottom: 1px solid $white;
              &.for.sale {
                background: $theme-for-sale;
              }
              &.sold {
                background: $theme-sold;
              }
              &.non-exist {
                background: gray;
              }
              &.occupied {
                background: $theme-occupied-box;
              }
              &.reserved {
                background: $theme-reserved;
              }
              &.ti {
                background: $theme-TI;
              }
              &.tb {
                background: $theme-TB;
              }
              &.admin.hold {
                background: $theme-admin-hold;
              }
              &.pipe {
                background: $theme-pipe;
              }
              &.obstruct {
                background: $theme-obstruct;
              }
              &.tree {
                background: $theme-tree;
              }
              &.active {
                border: 2px solid #000000;
              }
              &.multiGrave {
                min-width: 140px !important;
              }
              p {
                display: inline-block;
                margin-bottom: 0;
                text-decoration-line: underline;
                &:nth-of-type(2) {
                  padding: 0 1px;
                }
              }
            }
          }
        }
      }
      .dropdown {
        display: flex;
        justify-content: space-between;
        background: $white;
        border: 1px solid $theme-tertiary-text;
        border-radius: 4px;
        span {
          @include font($lato, 400, 16px, $theme-tertiary-text);
          font-style: normal;
          line-height: 19px;
          padding: 12px;
          min-width: 211px;
          cursor: pointer;
          .dropdown-arrow {
            float: right;
            margin-top: 6px;
          }

          &[disabled] {
            cursor: not-allowed;
          }
        }
        .dropdown-menu {
          @include font($lato, 400, 16px, $theme-tertiary-text);
          font-style: normal;
          line-height: 19px;
          min-width: 211px;
          border-radius: 4px;
          box-shadow: none;
          padding: 12px 12px 0;
          cursor: pointer;
          max-height: 250px;
          overflow: auto;
          .dropdown-content-wrapper {
            padding-bottom: 12px;
          }
          &::before {
            display: none;
          }
        }
      }
    }
    .container-property {
      padding: 12px 0 10px 16px;
      width: 670px;
      .cemetery-panoramic-view,
      .fa-times {
        cursor: pointer;
      }
      .section-property-title {
        @include flex(space-between, center);
        min-height: 60px;
        padding: 10px 25px;
        margin-bottom: 15px;
        position: relative;
        &:before {
          position: absolute;
          content: '';
          height: 100%;
          width: 9px;
          top: 0;
          left: 0;
        }
        &.for.sale {
          @include backgroundColor($theme-for-sale, $theme-for-sale-light);
        }
        &.sold {
          @include backgroundColor($theme-sold, $theme-sold-light);
        }
        &.occupied {
          @include backgroundColor($theme-occupied, $theme-occupied-light);
        }
        &.reserved {
          @include backgroundColor($theme-reserved, $theme-reserved-light);
        }
        &.ti {
          @include backgroundColor($theme-TI, $theme-TI-light);
        }
        &.tb {
          @include backgroundColor($theme-TB, $theme-TB-light);
        }
        &.pipe {
          @include backgroundColor($theme-pipe, $theme-pipe-light);
        }
        &.obstruct {
          @include backgroundColor($theme-obstruct, $theme-obstruct-light);
        }
        &.tree {
          @include backgroundColor($theme-tree, $theme-tree-light);
        }
        &.admin.hold {
          @include backgroundColor($theme-admin-hold, $theme-admin-hold-light);
        }
        .propert-title-left {
          display: flex;
          flex-direction: column;
          span {
            @include font($lato, 400, 13px, $theme-secondary-text);
            line-height: 22px;
            margin-bottom: 1px;
          }
          strong {
            @include font($lato, 700, 14px, $theme-secondary-text);
            line-height: 17px;
            text-decoration: underline;
          }
          a {
            @include font($lato, 700, 14px, $theme-secondary-text);
            line-height: 17px;
            margin-top: 1px;
            text-decoration: underline;
            &.view-document-wrapper {
              font-size: 13px;
              padding-top: 23px;
              line-height: 16px;
              font-weight: 400;
              color: $theme-text;
              text-decoration: underline;
            }
          }
          .property-options {
            margin-top: 20px;
            display: flex;
            .property-options-left {
              display: flex;
            }
            a.view-document-wrapper {
              padding-top: 0;
            }
            &.property-options-with-agreements-button {
              @media (max-width: 500px) {
                flex-direction: column;
                .property-options-left {
                  margin-bottom: 10px;
                  .seperator-agreements {
                    display: none;
                  }
                }
              }
            }
          }
          .view-document-wrapper-cancel {
            font-size: 13px !important;
            line-height: 16px !important;
            font-weight: 400 !important;
            color: $theme-text !important;
            text-decoration: underline !important;
          }
        }
        .link-documents {
          font-size: 13px;
          color: $theme-text;
          border-color: $theme-text;
          padding-bottom: 0;
          padding-top: 18px;
          line-height: 16px;
          text-align: left;
          border: none;
          text-decoration: underline;
          &:hover,
          &:focus,
          &:active {
            color: $theme-text !important;
          }
        }
      }
      .section-property-details {
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid $theme-border-gray-fade;
        padding: 16px;
        .section-property-details {
          padding: 16px 0;
        }
        @media (max-width: 767px) {
          padding: 15px 0;
        }
        .item-card {
          width: 50%;
          margin-bottom: 21px;
          &.full-width {
            width: 100%;
          }
          .item-card-name {
            font-size: 16px;
            line-height: 19px;
            color: $theme-tertiary-text;
          }
          .item-card-value {
            padding-top: 6px;
            font-size: 16px;
            line-height: 19px;
            color: $theme-text;
            a {
              line-height: 12px !important;
              text-decoration-line: underline;
              @include font($lato, 400, 13px, $theme-text);
            }
          }
        }
        .comments-wrapper {
          width: 100%;
        }
        .view-document-wrapper {
          font-size: 13px;
          margin-top: -22px;
          line-height: 16px;
          color: $theme-text;
          text-decoration: underline;
        }
        &.no-border {
          border-bottom: none;
        }
        .reservation-flow-wrapper {
          width: 100%;
          td {
            .radio {
              padding-left: unset;
              padding-bottom: unset;
              &:nth-of-type(2) {
                padding-left: unset;
              }
              &:last-of-type {
                margin-left: unset !important;
              }
              label {
                color: unset;
              }
            }
          }
          label {
            line-height: 17px;
            font-size: 16px;
            color: $theme-tertiary-text;
          }
          .radio {
            padding-left: 20px;
            padding-bottom: 20px;
            label {
              font-size: 16px;
              line-height: 19px;
              color: $theme-tertiary-text;
              &.active {
                color: $theme-text;
              }
            }
          }
          .button {
            margin-top: 16px;
            // &.reserve-btn {
            //   margin-top: 42px;
            // }
            &.reserve-opi-btn {
              margin-top: 0;
              max-width: 200px;
            }
            &:nth-of-type(2) {
              margin-left: 30px;
            }
          }
          .contract-details-wrapper {
            padding: 12px 36px 9px;
            margin: 16px -36px;
            background: $theme-light-gray;
            font-style: normal;
            line-height: 17px;
            letter-spacing: 1px;
            text-transform: uppercase;
            @include font($lato, 700, 14px, $theme-tertiary-text);
            p {
              margin-bottom: 0;
            }
          }
          .contract-details-value-wrapper {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            .cards-wrapper {
              display: flex;
              width: 100%;
              @media (max-width: 767px) {
                flex-wrap: wrap;
              }
            }
            .item-card {
              width: 50%;
              margin-bottom: 0;
              padding-bottom: 24px;
              @media (max-width: 767px) {
                width: 50% !important;
              }
            }
            .section-linked-properties .item-card,
            .container-cards .item-card {
              @media (max-width: 767px) {
                width: 100% !important;
              }
            }
            .select-wrapper {
              font-weight: 400;
              font-size: 16px;
              line-height: 25px;
              color: #979797;
              width: 50%;
              margin-bottom: 0;
              padding-bottom: 24px;
              max-width: 125px;
            }
            .opi-id-table-wrapper {
              .item-card {
                width: 50%;
                margin-bottom: 0;
                padding-bottom: 11px;
              }
              .table {
                margin: 0;
                width: 100%;
                display: table;
                thead {
                  tr {
                    max-width: 100%;
                  }
                }
              }
            }
            .submitted-message {
              // .item-card-value {
              color: $error-msg;
              font-size: 15px;
              // }
            }
            .reserve-property-btn {
              width: 100%;
            }
            .reserve-card {
              width: 25% !important;
              @media (max-width: 767px) {
                width: 50% !important;
              }
            }
            .small-card {
              width: 75% !important;
              display: flex;
            }
          }
          .mousoleum-form {
            // max-width: 300px;
            .search-opi-id {
              display: block !important;
              max-width: 300px;
            }
            .contract-details-wrapper {
              margin: 16px 0;
              padding: 12px 10px 9px;
            }
            .radio {
              padding-left: 0;
              padding-bottom: 0;
              margin-left: 0 !important;
            }
            // options-wrapper
            .options-wrapper {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              width: 100%;
              .select-wrapper {
                display: flex;
                flex-direction: column;
                font-weight: 400;
                font-size: 16px;
                line-height: 25px;
                color: #979797;
                width: 50%;
                margin-bottom: 0;
                padding-bottom: 24px;
                max-width: 100px;
              }
              // .item-card {
              //   display: inline-block !important;
              //   .item-card-value {
              //     display: block;
              //   }
              // }
            }
            // options-wrapper
            button {
              min-width: 121px;
              height: 49px;
              font-weight: 700 !important;
              font-size: 16px;
              line-height: 19px;
            }
          }
        }
        &.property-content-scroll {
          overflow-y: auto;
          overflow-x: hidden;
          max-height: calc(100vh - 117px - 67px - 25px);
        }
      }
      .table-container.table-container-map-results {
        // overflow-y: auto;
        // max-height: 157px;
        margin-bottom: 20px;
        &.opi-id-table-wrapper {
          .table {
            thead tr th,
            tbody tr td {
              padding: 5px 5px 5px 10px;
              word-break: break-word;
              font-size: 11px;
              line-height: 13px;
              letter-spacing: 0.4px;
              &:nth-of-type(1) {
                width: 180px;
                // padding-left: 0;
              }
              &:nth-of-type(2) {
                width: 140px;
              }
              &:nth-of-type(3) {
                width: 100px;
              }
              &:nth-of-type(4) {
                width: 98px;
              }
              &:nth-of-type(5) {
                width: 110px;
              }
              .radio {
                label {
                  font-size: 13px;
                  line-height: 17px;
                }
                input + span {
                  width: 16px;
                  height: 16px;
                }
              }
            }
            tr.selected {
              td,
              label {
                font-weight: 700;
              }
            }
            tbody tr td {
              font-size: 13px;
              line-height: 16px;
              &.column-status {
                display: flex;
              }
              &.selected {
                font-weight: bold;

                .input-radio {
                  label.active {
                    font-weight: bold;
                  }
                }
              }
            }
            @media (max-width: 767px) {
              display: block;
              overflow-x: auto;
              thead tr th,
              tbody tr td {
                width: 180px;
                min-width: 180px;
                &:nth-of-type(3) {
                  width: 250px;
                  min-width: 250px;
                }
              }
            }
          }
        }

        .non-reserved-table {
          margin-bottom: 16px !important;
          &.table {
            tbody {
              tr {
                td {
                  font-size: 14px !important;
                }
              }
            }
          }
          @media (max-width: 767px) {
            display: block;
            overflow-x: auto;
          }
        }
      }
      .success-btn {
        margin-right: 50px !important;
      }
    }
    .building-heading {
      @include font($lato, 400, 14px, $theme-tertiary-text);
      font-style: normal;
      line-height: 17px;
      margin-bottom: 0;
      padding-bottom: 30px;
    }
    .title-results {
      background: $theme-table-nth;
      min-height: 54px;
      .title-results-left {
        display: flex;
        align-items: center;
        .cl-logo {
          margin-right: 30px;
        }
        // a {
        //   margin-right: 30px;
        // }
      }
    }
    .title-search {
      @include flex(space-between, center);
      min-height: 58px;
      padding: 10px 25px;
      margin-bottom: 9px;
      h4 {
        @include font($lato, 700, 16px, $theme-tertiary-text);
        line-height: 19px;
        margin-bottom: 0;
      }
    }
    .cancel-popup {
      color: $theme-tertiary-text;
      font-size: 16px;
      padding: 16px 16px 0 12px;
      line-height: normal;
      cursor: pointer;
    }
    @media (max-width: 1279px) {
      flex-direction: column;
      .table-container-map-building {
        max-width: 100% !important;
        padding: 20px 15px 15px;
        .scrollable-data-wrapper {
          max-height: 35vh;
        }
        .table-heading {
          padding-right: 20px;
        }
      }
      .container-property {
        .section-property-details.property-content-scroll {
          max-height: calc(
            65vh - 35px - 18px - 30px - 24px - 58px - 9px - 103px - 30px
          );
        }
      }
      .cancel-popup {
        position: absolute;
        right: 10px;
        top: 0;
        padding: 2px;
      }
    }
    @media (max-width: 767px) {
      .container-property {
        width: 100%;
        padding: 10px;
        .title-results {
          min-height: 45px;
          margin-bottom: 5px;
        }
        .section-property-title-container {
          .section-property-title {
            padding: 5px 20px;
            margin-bottom: 10px;
            .propert-title-left {
              overflow: hidden;
              > a {
                word-break: break-word;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 12px;
              }
              > div {
                margin-top: 5px !important;
              }
            }
          }
        }
        .section-property-details.property-content-scroll {
          max-height: calc(100vh - 262px - 20px - 50px - 95px);
        }
      }
      .table-container-map-building {
        padding: 10px;
        .scrollable-data-wrapper {
          max-height: 150px;
        }
        .table-heading {
          flex-direction: column;
          align-items: flex-start;
          p {
            margin: 0 0 10px;
          }
        }
        .table tbody tr td {
          min-width: 40px;
          min-height: 40px;
          height: auto;
          padding: 5px !important;
        }
      }
      .cancel-popup {
        top: 10px;
      }
    }
  }
  .modal-content {
    border: none !important;
    height: auto;
    background: $white !important;
    .modal-body {
      padding: 0 !important;
    }
  }
  &.modalProperties {
    max-width: 100% !important;
  }

  .super-admin-reserve-status {
    justify-content: flex-start;

    span {
      margin-right: 48px;
    }
  }

  .reservation-status-dropdown-container {
    width: 145px;
  }
}
.reservation-confirmation {
  padding: 119px 163px;
  text-align: center;
  .check-circle-img {
    margin-bottom: 12px;
  }
  p {
    line-height: 175%;
    max-width: 100%;
    text-align: center;
    margin-bottom: 21px;
    @include font($lato, 400, 20px, $theme-text);
  }
  .success-btn {
    margin-right: 50px;
  }
  .btn-back {
    color: $modal-dialog-title;
    background: $white !important;
    margin-top: 0;
    margin-bottom: 0;
    border: 1px solid $modal-dialog-title;
    &:hover {
      color: $modal-dialog-title;
      background: $white !important;
      border: 1px solid $modal-dialog-title;
    }
  }
  @media (max-width: 767px) {
    padding: 20px;
    p {
      font-size: 18px;
      line-height: 140%;
    }
    .button.btn.md:not(.btn-back) {
      min-width: 100px;
    }
    .success-btn {
      margin-right: 20px;
    }
  }
}
