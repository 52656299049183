.radio {
  margin-right: 20px;
  display: inline-block;
  margin-bottom: $form-control-margin-bottom;

  cursor: pointer;
  font-size: 16px;

  @extend .lato-regular;

  &[disabled] {
    span {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
  &:last-of-type {
    margin-left: 20px !important;
  }

  input {
    display: none;

    + span {
      @include border-radius(50%);
      @include transition(all 0.3s ease-in-out);

      width: 20px;
      border: 1px solid $theme-tertiary-text;
      height: 20px;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      background: $white;

      // todo (harsh) check this
      margin-top: -3px;
      margin-right: 5px;

      i {
        @include translate(-50%, -50%);
        @include transition(all 0.3s ease-in-out);

        top: 50%;
        left: 50%;
        color: $white;
        display: block;
        position: relative;
        font-size: 8px;
      }
    }

    &:checked {
      + span {
        @include border-radius(50%);
        @include transition(all 0.3s ease-in-out);

        border: 1px solid $brand-primary;
        background-color: $brand-primary;
      }
    }
  }

  .label {
    line-height: 28px;
    vertical-align: middle;
    margin-left: 6px;
  }
}
