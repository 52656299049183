body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.azure-maps-control-container {
  flex-direction: row-reverse !important;
}
.zoom-out {
  margin-right: 10px !important;
}
.greyed {
  background-color: #f4f4f4 !important;
  pointer-events: none;
}
.camel-case {
  text-transform: capitalize !important;
}
