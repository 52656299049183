.deceased-wrapper.platted-wrapper.activity-history-wrapper {
  @media (max-width: 767px) {
    .comment-note-wrapper {
      margin-bottom: 0;
    }
    .btn-report,
    .btn-activity-history {
      top: 100px;
      right: auto;
      left: 0;
    }
    .page-section .page-header {
      margin-bottom: 80px;
    }
  }
  .search-results-wrapper .table-container-search-results-user .table {
    @media (max-width: 600px) {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
    thead tr th,
    tbody,
    tr td {
      @media (max-width: 1023px) {
        width: 30% !important;
        &:nth-of-type(2) {
          width: 40% !important;
        }
      }
      @media (max-width: 767px) {
        &:nth-of-type(1) {
          padding: 5px 10px;
        }
      }
      @media (max-width: 600px) {
        min-width: 170px !important;
        width: 170px !important;
        &:nth-of-type(2) {
          min-width: 250px !important;
          width: 250px !important;
        }
      }
    }
  }
}

.deceased-wrapper.search-wrapper.activity-search-wrapper {
  .simple-panel-container.deceased-search-wrapper.activity-history-search-wrapper {
    .form-elements-wrapper .row {
      > div {
        @media (max-width: 1024px) {
          max-width: 40%;
          flex: 0 0 40%;
        }
        @media (max-width: 767px) {
          max-width: 50%;
          flex: 0 0 50%;
        }
        @media (max-width: 550px) {
          max-width: 100%;
          flex: 0 0 100%;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .btn-report,
    .btn-activity-history {
      top: 100px;
      right: auto;
      left: 0;
    }
    .page-section .page-header {
      margin-bottom: 80px;
    }
  }
}

.deceased-wrapper {
  .header-search-results {
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start !important;
    }
    h2 {
      @media (max-width: 1023px) {
        margin: 0 30px 0 0 !important;
      }
      @media (max-width: 767px) {
        margin: 0 0 15px !important;
      }
    }
  }
  .table-container .table .no-results-container {
    @media (max-width: 767px) {
      padding: 1.2rem 0 !important;
      p {
        font-size: 16px;
        margin-bottom: 1rem !important;
      }
    }
  }
}

.deceased-wrapper.activity-table-wrapper {
  #activity-history-section {
    @media (max-width: 900px) {
      table {
        display: block;
        overflow-x: auto;
        thead tr th,
        tbody tr td {
          width: 200px;
          min-width: 200px;
          &:nth-of-type(1),
          &:nth-of-type(3) {
            width: 150px;
            min-width: 150px;
          }
        }
      }
    }
    @media (max-width: 767px) {
      .no-results-container P {
        margin-bottom: 0 !important;
      }
    }
  }
}
