.notes-wrapper {
  .notes-list-wrapper {
    border-top: 1px solid rgba(151, 151, 151, 0.2);
  }

  .panel-container {
    box-shadow: none;
    .panel-header {
      padding: 0;
    }
    .panel-body {
      box-shadow: none;
      .add-note-wrapper {
        padding: 25px 70px;
        @media (max-width: 1100px) {
          padding: 25px;
        }
        @media (max-width: 767px) {
          padding: 15px 10px;
        }
        .comments-buttons-wrapper {
          margin-top: 20px;
          position: relative;
          @media (max-width: 1100px) {
            margin-top: 40px;
          }
          @media (max-width: 767px) {
            margin: 40px 0 0;
            .col {
              padding: 0;
              button {
                min-width: 110px;
              }
            }
          }
          button {
            padding: 12px;
            min-width: 125px;
          }
          .message-wrapper {
            position: absolute;
            top: -5px;
            right: 15px;
            font-style: italic;
            line-height: 19px;
            @include font($lato, 400, 16px, $theme-tertiary-text);
            @media (max-width: 1100px) {
              top: -30px;
            }
          }
        }
      }
    }
  }
}
