.common-local-search {
  @extend .mr-3;
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;

  position: relative;

  .fas {
    top: 50%;
    left: 16px;
    color: $theme-placeholder-text;
    position: absolute;
    font-size: 16px;
    @include translate(0, -50%);
  }
  .local-search {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    width: 100%;
    height: 42px;
    font-size: 16px;
    max-width: 232px;
    line-height: 19px;
    padding-left: 40px;
    border: 1px solid $theme-placeholder-text;
    @include box-shadow(none);
    @include border-radius(4px);
  }
}
