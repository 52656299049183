// import library styles
// Font awesome moved to html
@import '~bootstrap/scss/bootstrap';

@import './assets/styles/_fonts.scss';
@import './assets/styles/_color.scss';
@import './assets/styles/_variables.scss';
@import './assets/styles/_mixins.scss';
@import './assets/styles/_common.scss';
@import './features/home/header/header.scss';
@import './features/home/home-content/home-content.scss';
// common components and features
@import './common/components/components.scss';
@import './common/features/common-features.scss';

// application features
@import './features/features.scss';
