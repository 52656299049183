// Import bootstrap so as to @extend the classes and utilities
// provided by bootstrap
@import './fonts';
@import './color';
@import './mixins';
@import './variables';
@import './utils';

.cursor-pointer {
  cursor: pointer;
}

* {
  &:focus {
    outline: none !important;
  }
}

input {
  color: $theme-text !important;

  @include placeholder-color($theme-tertiary-text !important);
  &[disabled] {
    color: $theme-tertiary-text !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.step-wrapper {
  margin-bottom: 30px;
  font-size: 16px;

  .step-number {
    &.active-step {
      display: inline-block;
      text-align: center;
      margin: 0 5px;
      color: $white;
      background-color: $brand-secondary;
      @include circle(22px);
    }
  }
}

.theme-success {
  color: $brand-tertiary !important;
}

.error-msg {
  color: $error-msg !important;
  padding: 5px;
  &:empty {
    padding: 0;
  }
}

.theme-white {
  color: $white !important;
}

.form-control {
  box-shadow: none !important;

  &:disabled {
    background-color: $theme-modal-tagbg;
  }
}

body {
  font-size: 0.875rem !important;
  background-color: $table-row-alt-bg !important;
  color: $theme-primary-text;
  @extend .lato-regular;
}

/////////---MODAL---///////// BEGINS ->
$nero: #282828;

.modal-open {
  .modal {
    display: flex !important;
    align-items: center;
    justify-content: center;
    .modal-dialog {
      margin: 0 auto;
      .modal-content {
        border: 1px solid transparent;
        background-color: $white;
        .modal-header {
          border: 0;
          padding: 20px 45px;
          background-color: $theme-table-nth;
          @include box-shadow(0 3px 10px 0 rgba(144, 137, 137, 0.18));
          > h5 {
            width: 100%;
            display: block;
            position: relative;
            font-weight: bold;
            .modal-headername {
              color: $brand-primary;
              display: block;
              font-size: 20px;
              line-height: 1;
              @extend .lato-bold;
            }
            .modal-close {
              top: 4px;
              right: 0;
              color: $nero;
              margin: 0;
              cursor: pointer;
              opacity: 1;
              z-index: 1;
              position: absolute;
              font-size: 16px;
              text-shadow: none;
            }
          }
        }
        .modal-body {
          padding: 20px 45px;
          position: relative;
          .delete-length-note-garden {
            font-style: italic;
            @include font($lato, 400, 16px, $theme-tertiary-text);
            position: absolute;
            bottom: 135px;
            right: 45px;
            @media (max-width: 767px) {
              bottom: 75px;
              right: 20px;
            }
          }
        }
        .modal-footer {
          > * {
            margin: 0;
          }
          border: 0;
          padding: 20px 45px;
          position: relative;
          // border-top: 1px solid rgba($theme-placeholder-text, 0.2);
          .button {
            &:last-child:not(:first-child) {
              margin-left: 15px;
            }
          }
          &:empty {
            padding: 0;
          }
        }
        @media (max-width: 767px) {
          .modal-header,
          .modal-body,
          .modal-footer {
            padding: 15px;
          }
        }
      }
    }
  }
}
/////////---MODAL---///////// ENDS <-

/////////---TABLE---///////// BEGINS ->
.table-container {
  .table {
    @include get-font($lato, 'regular');
    @extend .mb-0;

    thead {
      background-color: $theme-light-gray;
      tr {
        th {
          @include font($lato, 700, 13px, $theme-tertiary-text);
          line-height: 16px;
          vertical-align: middle;
          text-transform: uppercase;
          border-right: 1px solid rgba($theme-placeholder-text, 0.2);
          padding: 5px 15px;
          height: 42px;
          &:last-child {
            border-right: 0;
          }
          @media (max-width: 767px) {
            padding: 5px 10px !important;
          }
        }
      }
    }
    tbody {
      .no-results-container {
        @include flex-d(center, center, column);
        text-align: center;
        padding: 3rem 0;
        p {
          @include font($lato, 400, 18px, $theme-tertiary-text);
          line-height: 27px;
          margin: 0;
          @media (max-width: 1023px) {
            font-size: 16px;
          }
        }
        @media (max-width: 767px) {
          padding: 1rem 0;
        }
      }
      tr {
        background-color: $white;
        &:nth-child(2n) {
          background-color: $theme-table-nth;
        }

        td {
          @include font($lato, 400, 16px, $theme-text);
          line-height: 19px;
          vertical-align: middle;
          height: 55px;
          padding: 5px 15px;
          border-right: 1px solid rgba($theme-placeholder-text, 0.2);
          &:last-child {
            border-right: 0;
          }
          @media (max-width: 767px) {
            padding: 5px 10px !important;
          }
          > a {
            color: $theme-text;
            text-decoration: underline;
          }
          .table-row-actions {
            @extend .d-flex;
            @extend .align-items-center;
            @extend .justify-content-center;

            height: 100%;
          }
        }
      }
    }
  }

  &.table-with-scroll .table {
    display: flex;
    flex-flow: column;
    flex: 1;
    overflow: hidden;
    tbody {
      overflow-y: scroll;
      max-height: 300px;
      &::-webkit-scrollbar {
        width: 7px;
        background-color: $theme-light-gray;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $theme-tertiary-text;
        border-radius: 10px;
      }
    }
    thead {
      display: block;
      flex-shrink: 0;
      tr {
        max-width: calc(100% - 7px);
      }
    }
    tr {
      display: flex;
      width: 100%;
    }
    th,
    td {
      display: flex;
      align-items: center;
    }
    @media (max-width: 1023px) {
      display: block;
      tbody {
        max-height: unset;
        overflow: unset;
      }
      thead {
        display: table-row-group;
        tr {
          max-width: 100%;
        }
      }
      tr {
        display: table-row;
        th,
        td {
          display: table-cell;
        }
      }
    }
  }

  .actions-with-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border: 1px solid $table-border-color;
    border-top-width: 0;
    background-color: $white;
    height: 52px;
    background-color: $white;

    .actions {
      span {
        color: $brand-primary;
        padding: 0 2.125rem;

        @extend .lato-bold;

        font-size: 0.875rem;
        cursor: pointer;
      }
    }
  }
}

.btn-theme {
  color: $white;
  cursor: pointer;
  border: 0;
  padding: 15px 10px;
  font-size: 16px;
  min-width: 150px;
  min-height: 56px;
  line-height: 1.43;
  letter-spacing: 0.2px;
  text-decoration: none !important;
  background-color: $brand-primary;

  @include box-shadow(none);

  @include transition(all 0.3s ease-in-out);

  @include border-radius(4px);

  @extend .lato-bold;

  &.theme-transparent {
    color: $brand-primary;
    border: 1px solid $brand-primary;
    background-color: transparent;
  }

  &.btn-theme-success {
    text-transform: uppercase;
    background-color: $brand-tertiary !important;
  }

  &.btn-right {
    margin-left: 560px;
  }
  &.theme-orange {
    color: $white;
    background-color: $brand-secondary;

    &.orange-transparent {
      color: $brand-secondary;
      border: 1px solid $brand-secondary;
      background-color: transparent;
      &[disabled],
      &:disabled,
      &.disabled {
        background-color: transparent;
      }

      &.orange-only-text {
        padding: 0;
        border-width: 0;
        border-bottom-width: 1px;
        border-radius: 0;
        min-height: 0;
        min-width: 0;
        font-size: 14px;

        @extend .lato-regular;
      }
    }

    &:hover,
    &:active,
    &:focus:active,
    &:focus:hover,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus {
      opacity: 0.7;

      @include box-shadow(none);
    }

    &[disabled],
    &:disabled,
    &.disabled {
      background-color: $brand-secondary;
    }
  }

  &.theme-black {
    color: $theme-tertiary-text;
    background-color: $brand-default;

    &.black-transparent {
      color: $theme-tertiary-text;
      border: 1px solid $theme-tertiary-text;
      background-color: transparent;
    }

    &:hover,
    &:active,
    &:focus:active,
    &:focus:hover,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus {
      opacity: 0.7;

      @include box-shadow(none);
    }

    &[disabled],
    &:disabled,
    &.disabled {
      background-color: $brand-default;
    }
  }

  &.theme-cancel {
    color: $theme-text !important;
    border: 0 !important;
    text-transform: uppercase;
    background-color: transparent !important;
  }

  &.theme-back {
    color: $theme-placeholder-text !important;
    border: 0 !important;
    text-transform: uppercase;
    background-color: transparent !important;
  }

  &:hover,
  &:active,
  &:focus:active,
  &:focus:hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):active:focus {
    opacity: 0.7;

    @include box-shadow(none);
  }

  &[disabled],
  &:disabled,
  &.disabled {
    color: $white;
    opacity: 0.5;
    cursor: not-allowed;
    background-color: $brand-primary;
  }
}

// Smalltalk CSS
.smalltalk {
  &:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    z-index: 102;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .page {
    z-index: 103;
    position: relative;
    min-width: auto;
    max-width: 520px;
    @include border-radius(4px);
    .close-button {
      top: 35px;
      right: 30px;
      cursor: pointer;
      font-weight: 900;
      background-image: none;
      @extend .custom-fontawesome;
      &:hover {
        background-image: none;
      }
      &:before {
        content: '\f00d';
      }
    }
    header {
      color: $brand-primary;
      border: 0;
      padding: 30px;
      display: block;
      font-size: 24px;
      max-width: 100%;
      line-height: 1;
      background-color: $theme-table-nth;
      @extend .lato-bold;
      @include border-radius(4px);
    }
    .content-area {
      color: $theme-text;
      padding: 30px;
      font-size: 16px;
      > span {
        font-size: 16px;
      }
      input {
        color: $theme-text;
        width: 100%;
        height: 55px;
        border: 1px solid $theme-placeholder-text;
        padding: 0 1rem;
        font-size: 16px;
        margin-top: 17px;
        @include border-radius(4px);
      }
    }
    .action-area {
      border: 0;
      padding: 20px 30px;
      border-top: 1px solid $gainsboro !important;
      .button-strip {
        display: flex;
        button {
          height: 45px;
          padding: 0;
          min-height: auto;
          background-image: none;
          @extend .btn-theme;
          @extend .text-uppercase;
          &[data-name='js-ok'] {
            order: 1;
            @extend .btn-theme-success;
          }
          &[data-name='js-cancel'] {
            order: 0;
            border: 0;
            @extend .theme-black;
            @extend .black-transparent;
          }
        }
      }
    }
  }
}

.new-facility-button {
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  color: $brand-secondary;
}

.dropdown {
  > .btn {
    width: 45px;
    height: 27px;
    padding: 0;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    background: none !important;
    border: none !important;
    color: $black !important;
    box-shadow: none;
    float: right;
    .fas {
      color: #a2a1a1;
      font-size: 18px;
      line-height: 9px;
    }
    &:active {
      box-shadow: none !important;
      color: $black !important;
    }
  }

  &.show {
    > .btn .fas {
      color: $theme-text;
    }
  }

  .dropdown-menu {
    border-radius: 2px;
    margin: 0;
    padding: 0;
    z-index: 8;
    top: 3px !important;
    left: 0 !important;
    min-width: 250px;
    box-shadow: 0px 10px 15px rgba(199, 199, 199, 0.5);
    border: none;

    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      right: -2px;
      box-sizing: border-box;
      border: 7px solid black;
      border-color: transparent transparent $theme-light-gray $theme-light-gray;
      transform-origin: 0 0;
      transform: rotate(135deg);
    }

    &[x-placement='top-end'] {
      top: -3px !important;
      &::before {
        top: auto;
        right: 15px;
        bottom: -14px;
        transform: rotate(315deg);
      }
    }

    a {
      text-decoration: none;
    }

    & > button,
    a > button,
    button {
      color: $theme-text;
      border-bottom: 1px solid rgba($theme-placeholder-text, 0.3);
      padding: 1rem 1.5rem;
      font-weight: 400;
      .icon-holder {
        display: inline-block;
        text-align: center;
        margin-right: 10px;
        min-width: 20px;
        i {
          color: $theme-text;
        }
      }
      a {
        text-decoration: none;
        color: $theme-text;
      }
      &:active {
        background-color: transparent !important;
      }
      &.more-options {
        background: $theme-light-gray;
        font-family: 'Lato';
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        color: $theme-tertiary-text;
      }
    }

    & > button {
      &:last-child {
        border-bottom: none;
      }
    }

    .title-options {
      @include font($lato, 700, 13px, $theme-tertiary-text);
      display: flex;
      align-items: center;
      min-height: 50px;
      background-color: $theme-light-gray;
      padding: 5px 1.5rem;
      text-transform: uppercase;
    }
  }
  &.dropdown-without-more {
    .dropdown-menu::before {
      border-color: transparent transparent $white $white;
      box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.07);
    }
    .dropdown-item {
      @include font($lato, 400, 16px, $theme-text);
      line-height: 19px;
      .delete-icon {
        margin-right: 10px;
        margin-top: -5px;
      }
    }
  }
  &.dropdown-with-more {
    > .btn {
      @include font($lato, 700, 13px, $theme-tertiary-text !important);
      letter-spacing: 0.4px;
      line-height: 16px;
      text-transform: uppercase;
      width: 65px;
      .fas {
        color: $theme-tertiary-text;
        font-size: 14px;
        line-height: normal;
        padding-left: 6px;
        margin-top: 1px;
      }
    }
    .dropdown-menu {
      &:before {
        border-color: transparent transparent $white $white;
        box-shadow: 0px 10px 15px rgba(199, 199, 199, 0.5);
      }
    }
  }
}

.search-container-common {
  .local-search {
    min-width: 100%;
  }
}

.no-data-message {
  font-size: 16px;
  color: $theme-text;
}

.footer.footer-properties {
  @include flex(flex-end, center);
  position: fixed;
  width: calc(100% - 224px);
  bottom: 0;
  left: 224px;
  background: $white;
  box-shadow: 0 -5px 14px 0 rgba(213, 213, 213, 0.42);
  padding: 20px;
  .leftItems {
    height: auto;
  }
  button {
    margin-bottom: 0;
  }
  @media (min-width: 1440px) {
    width: calc(100% - 272px);
    left: 272px;
  }
}

.err-msg-custom {
  background-color: $theme-modal-tagbg;
  padding: 5px 10px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  span {
    @extend .lato-regular;
    color: $brand-secondary;
    font-size: 14px;
    line-height: 17px;
  }
  i {
    color: $brand-secondary;
    padding-right: 5px;
  }
}

.react-notification-root {
  z-index: 99999999;
  .notification-close:after {
    font-size: 25px;
  }
  .notification-title {
    font-size: 16px;
  }
  .notification-message {
    font-size: 15px;
  }
}

.display-none {
  display: none;
}

.full-forms-wrapper {
  font-style: normal;
  line-height: 14px;
  letter-spacing: 0.4px;
  padding: 16px 0;
  @include font($lato, 400, 12px, $theme-tertiary-text);
}

.mapbox-tooltip-wrapper {
  .mapboxgl-popup-content {
    width: 100%;
    min-width: 200px;
    padding: 10px;
    .input-field {
      width: 180px !important;
      label {
        font-size: 14px;
        margin-bottom: 0;
      }
      input {
        padding: 5px 10px !important;
        font-size: 14px !important;
      }
      .lg {
        height: 40px;
        margin: 5px 0;
      }
    }
    .select-wrapper {
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 10px;
      .select-dropdown--is-disabled {
        min-width: 100px;
      }
      label {
        line-height: 14px;
        font-size: 12px;
        margin-bottom: 0;
        width: 33.3333%;
        min-width: 85px;
        white-space: nowrap;
      }
      .select-dropdown__single-value {
        margin-left: unset !important;
        margin-right: unset !important;
        max-width: unset !important;
        font-size: unset !important;
      }
      .select-dropdown__indicators {
        max-height: 30px !important;
      }
      .select-dropdown__control {
        min-height: 30px;
        max-height: 30px;
        .select-dropdown__input {
          max-width: 20px !important;
        }
        .select-dropdown__single-value {
          font-size: 12px !important;
          // top: 40%;
        }
        .select-dropdown__placeholder {
          font-size: 12px;
          // top: 40%;
        }
      }
      &.lg {
        .select-dropdown__value-container {
          max-height: 30px;
          padding: 0px 6px 2px 6px;
        }
      }
      .select-dropdown__menu {
        .select-dropdown__menu-list {
          min-height: 90px;
          .select-dropdown__option {
            font-size: 12px;
            padding: 2px 20px 2px 6px;
            overflow: hidden;
            max-width: 100px;
            text-overflow: ellipsis;
          }
        }
      }
      .select-dropdown__option--is-selected {
        &::after {
          right: -6px;
          padding: 3px;
          font-size: 12px;
        }
      }
    }
    .button {
      min-width: 85px !important;
      font-size: 12px !important;
      border-radius: 3px;
      margin-top: 5px;
      padding: 5px !important;
    }
  }
  .rotation-radio-buttons {
    display: flex;
    flex-direction: column;
    margin: 8px;
    .radio:last-of-type {
      margin-left: 0 !important;
    }
  }
  .rotation-input-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 16px;
    }
    .input-field {
      width: 70px !important;
      input {
        min-width: 70px !important;
        height: 30px !important;
      }
    }
  }
  &.clockwise-wrapper {
    .rotation-radio-buttons {
      font-family: $inter;
      .input-radio {
        padding-bottom: 5px;
      }
    }
    .rotation-input-field {
      justify-content: flex-start;
      margin-bottom: 5px;
      span {
        font-size: 16px;
        margin: 0 5px 5px 33px;
        font-family: $inter;
      }
    }
  }
}
.mapboxgl-map {
  overflow: visible !important;
}

.popup-close {
  top: 3px !important;
  right: 5px !important;
}
