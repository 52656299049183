@import '../../../assets/styles/utils';

.page-section {
  padding: 30px 0;
  position: relative;
  @include get-font($lato, 'regular');
  @media (max-width: 767px) {
    padding: 20px 0;
  }

  .page-header {
    height: auto;
    position: relative;
    margin-bottom: 20px;

    > h1 {
      color: $brand-primary !important;
      margin: 1rem 0 0;
      padding: 0;
      font-size: 32px !important;
      line-height: 26px;
      font-weight: 700 !important;
      text-transform: capitalize;
      &:empty {
        margin: 0;
      }
    }
    > p {
      margin: 1rem 0 0;
    }

    .page-header-right {
      top: 50%;
      right: 16px;
      border: 0;
      position: absolute;
      @include translate(0, -50%);
    }
    .header-id {
      color: $black;
      border-radius: 1rem;
      background-color: $workOrder-headerText;
      padding: 5px 10px;
      font-size: 14px;
      line-height: 17px;
      margin-left: 10px;
      margin-bottom: 3px;
    }
  }
}
