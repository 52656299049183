.deceased-full-profile-wrapper.property-details-wrapper {
  .buttons-container {
    margin-top: -40px;
    margin-right: -18px;
    display: flex;

    .loader-container {
      .loader-section {
        border-radius: 4px !important;
      }

      .success-container {
        padding: 119px 163px;
        text-align: center;
        img {
          width: 60px;
          height: 60px;
          margin-bottom: 21px;
        }

        p {
          line-height: 175%;
          max-width: 100%;
          text-align: center;
          margin-bottom: 21px;
          font-family: 'Lato', sans-serif;
          font-weight: 400;
          font-size: 20px;
          color: #212121;
        }
      }
    }
  }
  .profile-modules-container {
    .container-burial-details {
      position: relative;
      .pagination-section {
        position: absolute;
        display: inline-flex !important;
        padding-right: 0;
        top: 13px;
        right: 70px;
        z-index: 9;
        @media (max-width: 1100px) {
          right: 20px;
        }
        @media (max-width: 900px) {
          flex-direction: column;
          align-items: flex-end !important;
          justify-content: flex-start;
          min-width: 0;
          > div:first-child {
            margin-bottom: 5px;
          }
        }
        @media (max-width: 500px) {
          right: auto;
          left: 15px;
          top: 40px;
          align-items: flex-start !important;
        }
      }
      .panel-header {
        min-height: 64px;
        @media (max-width: 900px) {
          min-height: 100px;
        }
        @media (max-width: 500px) {
          align-items: flex-start;
          min-height: 130px;
        }
      }
      .panel-container {
        .panel-header {
          .panel-header-icon {
            color: $theme-tertiary-text;
          }
        }
      }
    }
    .table-container-burial {
      @media (max-width: 1023px) {
        .table {
          display: block;
          overflow-x: auto;
        }
      }
      tbody tr td,
      thead tr th {
        width: 150px;

        &:nth-of-type(2) {
          width: calc(100% - (4 * 150px));
        }
        @media (max-width: 1023px) {
          min-width: 150px;
          &:nth-of-type(2) {
            width: 200px;
            min-width: 200px;
          }
        }
      }
      tbody tr {
        .no-results-column {
          width: 100% !important;
          .no-results-container {
            width: 100%;
          }
        }
      }
    }
    .table-container-ownership {
      tbody tr td,
      thead tr th {
        &:nth-of-type(1) {
          width: calc(100% - 200px);
          border-right: 0;
        }
        &:nth-of-type(2) {
          width: 200px;
        }
        @media (max-width: 767px) {
          &:nth-of-type(1) {
            width: calc(100% - 140px);
          }
          &:nth-of-type(2) {
            width: 140px;
          }
        }
      }
      tbody tr {
        .no-results-column {
          width: 100% !important;
          .no-results-container {
            width: 100%;
          }
        }
      }
    }
    #property .card-details-container {
      .item-card {
        @media (max-width: 1023px) {
          width: 50%;
        }
      }
    }
  }
  .deceased-sidebars-wrapper {
    &.property-sidebars-wrapper {
      .map-image {
        cursor: pointer;
        max-width: 100%;
        @media (max-width: 767px) {
          max-width: 200px;
        }
      }
      .deceased-profile-wrapper {
        .img-container {
          padding: 0;
          @media (max-width: 767px) {
            padding: 0 10px;
          }
        }
      }
    }
    .sidebar-menu-wrapper {
      padding: 2px 0 0;
      li {
        padding: 16px 36px;
      }
    }
  }
  .page-header {
    .breadcrumbs {
      max-width: calc(100% - 350px);
    }
    @media (max-width: 767px) {
      .breadcrumbs {
        max-width: 100%;
      }
      .page-header-right {
        position: static;
        transform: none;
        .buttons-container {
          margin: 15px 0 0;
          .button.btn.md {
            padding: 6px;
          }
        }
      }
    }
  }
}

.table-container-agreements {
  tbody tr td,
  thead tr th {
    width: calc((100% - 200px) / 4);
    &:nth-last-of-type(2) {
      width: 120px;
    }
    &:nth-last-of-type(1) {
      width: 80px;
    }
    .msg-status {
      padding: 1px 5px 2px;
      text-decoration: underline;
      display: inline-block;
      text-align: center;
      min-width: 90px;
      &.in-progress {
        background-color: $theme-status-inprogess;
      }
      &.completed {
        background-color: $theme-success;
      }
    }
    button.btn-default {
      box-shadow: none !important;
    }
  }
  @media (max-width: 1023px) {
    .table {
      display: block;
      overflow-x: auto;
      tbody tr td,
      thead tr th {
        width: 160px;
        min-width: 160px;
        &:nth-of-type(-n + 2) {
          width: 200px;
          min-width: 200px;
        }
        &:nth-last-of-type(1) {
          width: 80px;
          min-width: 80px;
        }
      }
    }
  }
}
