.container-documents-details {
  position: relative;
  .panel-container .panel-header {
    @media (max-width: 767px) {
      min-height: 72px;
    }
  }
  .panel-header-text-wrap.panel-header-text-wrap-documents {
    flex-direction: column;
    align-items: flex-start !important;
    width: auto;
    flex-grow: initial;
    .breadcrumbs {
      margin-top: 10px;
      .breadcrumb-item {
        &.active {
          cursor: default;
          span.breadcrumb-text-wrapper {
            color: $theme-tertiary-text;
            span {
              text-decoration: none;
              color: $theme-tertiary-text;
            }
          }
        }
        > span.breadcrumb-text-wrapper {
          span {
            text-decoration: underline;
          }
          > i {
            font-size: 14px;
            padding-right: 3px;
          }
        }
      }
    }
    @media (max-width: 767px) {
      flex-grow: 1;
      .breadcrumbs {
        max-width: calc(100% - 140px);
        .breadcrumb-item {
          line-height: 22px;
        }
      }
    }
  }
  .container-filter {
    display: flex;
    align-items: center;
    position: absolute;
    left: 180px;
    top: 12px;
    .btn-add-doc {
      font-weight: 400 !important;
      text-transform: none !important;
      padding-bottom: 0;
      line-height: 20px;
    }
    @media (max-width: 767px) {
      left: auto;
      right: 60px;
      top: 26px;
    }
    @media (max-width: 600px) {
      flex-direction: column;
      top: 15px;
    }
  }
  .filter-results-wrapper {
    padding: 20px 30px;
    border-top: 0;
    @media (max-width: 767px) {
      padding: 10px;
    }
  }
  &:not(.container-records-details) {
    .container-filter .dropdown-search-filter {
      margin-right: 40px;
      padding-right: 40px;
      border-right: 1px solid rgba($theme-tertiary-text, 0.2);
      @media (max-width: 1023px) {
        margin-right: 15px;
        padding-right: 15px;
      }
      @media (max-width: 600px) {
        margin-right: 0;
        padding-right: 0;
        border-right: none;
      }
    }
  }
  .table-container-documents,
  .table-container-books,
  .new-doc-style {
    tbody tr td,
    thead tr th {
      &:nth-last-of-type(1) {
        text-align: center;
        i {
          color: $theme-tertiary-text;
        }
      }
      &:nth-last-of-type(2) {
        text-align: center;
        border-right: 0;
        i {
          color: $theme-tertiary-text;
        }
      }
      &:nth-last-of-type(3) {
        text-align: center;
        border-right: 0;
        i {
          color: $theme-tertiary-text;
        }
      }
    }
  }
  .new-doc-style {
    tbody tr td,
    thead tr th {
      &:nth-of-type(1) {
        width: 50%;
        padding-left: 30px;

        > div {
          @include flex(flex-start, center);

          &.file-name {
            text-overflow: ellipsis;
            overflow: auto;
            white-space: nowrap;
            display: block;
            overflow-x: hidden;
          }

          &.folder-name {
            > span {
              text-decoration: underline;
              cursor: pointer;
            }
          }
          > i {
            color: $theme-tertiary-text;
            padding-right: 15px;
          }
        }
      }
      &:nth-of-type(2) {
        width: 15%;
      }
      &:nth-of-type(3) {
        width: 15%;
      }
      &:nth-of-type(4) {
        width: 15%;
        border-right: 0;
      }
      &:nth-of-type(5) {
        width: 5%;
      }
      &:nth-of-type(6) {
        width: 5%;
      }
      &:nth-of-type(7) {
        width: 5%;
      }
    }
    tbody tr {
      .disabled-link {
        opacity: 0.5;
        cursor: not-allowed;
      }
      &:hover {
        cursor: pointer;
        td {
          .file-name,
          .folder-name {
            i {
              color: $theme-text;
            }
          }
          &:nth-of-type(1) {
            font-weight: 700;
          }
          &:nth-last-of-type(1) {
            i {
              color: $theme-text;
            }
          }
          &:nth-last-of-type(2) {
            i {
              color: $theme-text;
            }
          }
          &:nth-last-of-type(3) {
            i {
              color: $theme-text;
            }
          }
        }
      }
    }
    @media (max-width: 767px) {
      .table {
        display: block;
        overflow-x: auto;
        tbody tr td,
        thead tr th {
          &:nth-of-type(1) {
            width: 350px;
            min-width: 350px;
          }
          &:nth-of-type(2) {
            width: 200px;
            min-width: 200px;
          }
          &:nth-of-type(3) {
            width: 100px;
            min-width: 100px;
          }
          &:nth-of-type(n + 4) {
            width: 50px;
            min-width: 50px;
          }
        }
      }
    }
  }
  .table-container-documents {
    tbody tr td,
    thead tr th {
      &:nth-of-type(1) {
        width: 50%;
        padding-left: 30px;

        > div {
          @include flex(flex-start, center);

          &.file-name {
            text-overflow: ellipsis;
            overflow: auto;
            white-space: nowrap;
            display: block;
            overflow-x: hidden;
          }

          &.folder-name {
            > span {
              text-decoration: underline;
              cursor: pointer;
            }
          }
          > i {
            color: $theme-tertiary-text;
            padding-right: 15px;
          }
        }
      }
      &:nth-of-type(2) {
        width: 20%;
      }
      &:nth-of-type(3) {
        width: 15%;
        border-right: 0;
      }
      &:nth-of-type(4) {
        width: 5%;
      }
      &:nth-of-type(5) {
        width: 5%;
      }
      &:nth-of-type(6) {
        width: 5%;
      }
    }
    tbody tr {
      &:hover {
        cursor: pointer;
        td {
          .file-name,
          .folder-name {
            i {
              color: $theme-text;
            }
          }
          &:nth-of-type(1) {
            font-weight: 700;
          }
          &:nth-last-of-type(1) {
            i {
              color: $theme-text;
            }
          }
          &:nth-last-of-type(2) {
            i {
              color: $theme-text;
            }
          }
          &:nth-last-of-type(3) {
            i {
              color: $theme-text;
            }
          }
        }
      }
    }
    @media (max-width: 767px) {
      .table {
        display: block;
        overflow-x: auto;
        tbody tr td,
        thead tr th {
          &:nth-of-type(1) {
            width: 350px;
            min-width: 350px;
          }
          &:nth-of-type(2) {
            width: 200px;
            min-width: 200px;
          }
          &:nth-of-type(3) {
            width: 100px;
            min-width: 100px;
          }
          &:nth-of-type(n + 4) {
            width: 50px;
            min-width: 50px;
          }
        }
      }
    }
  }
  .table-container-books {
    tbody tr td,
    thead tr th {
      word-break: break-all;
      &:nth-of-type(1) {
        width: 20%;
      }
      &:nth-of-type(2) {
        width: 40%;
        > div {
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          /* autoprefixer: off */
          -webkit-box-orient: vertical;
          /* autoprefixer: on */
          overflow: hidden;
        }
      }
      &:nth-of-type(3) {
        width: 15%;
      }
      &:nth-of-type(4) {
        width: 15%;
        border-right: 0;
        .button {
          font-weight: 400 !important;
          font-size: 14px;
          padding-bottom: 0;
          line-height: 17px;
        }
      }
      &:nth-of-type(5) {
        width: 10%;
      }
    }
    @media (max-width: 1100px) {
      .table {
        display: block;
        overflow-x: auto;
        tbody tr td,
        thead tr th {
          &:nth-of-type(1) {
            width: 200px;
            min-width: 200px;
          }
          &:nth-of-type(2) {
            width: 400px;
            min-width: 400px;
          }
          &:nth-of-type(n + 3) {
            width: 150px;
            min-width: 150px;
          }
        }
      }
    }
  }
  &.container-records-details {
    @media (max-width: 767px) {
      .container-filter {
        left: 160px;
        top: 12px;
      }
      .panel-container .panel-header {
        min-height: 0;
      }
    }
    .table-container-documents,
    .table-container-books {
      tbody tr td,
      thead tr th {
        &:nth-last-of-type(3) {
          border-right: 1px solid $theme-border-gray-fade;
        }
      }
    }
  }
}

.modal-open .modal .modal-dialog.modal-upload-document {
  @media (max-width: 767px) {
    padding: 0 10px;
    .modal-footer {
      padding-top: 30px;
    }
  }
  .modal-content {
    .modal-body {
      min-width: 700px;
      padding: 25px 45px 60px;
      .description {
        @include font($lato, 400, 16px, $theme-tertiary-text);
        line-height: 19px;
        margin: 0 0 20px;
      }
      .delete-length-note {
        left: auto;
        right: 45px;
      }
      @media (max-width: 767px) {
        min-width: 0;
        width: 100%;
        padding: 15px 15px 30px;
        max-height: 70vh;
        overflow-y: auto;
        .delete-length-note {
          right: 15px;
          bottom: 0;
        }
        .custom-file-upload .no-file {
          font-size: 15px;
          line-height: 18px;
        }
        .file-size-limit {
          position: absolute;
          top: 100px;
        }
      }
      @media (max-width: 400px) {
        .description {
          max-width: 90%;
        }
        .file-size-limit {
          top: 117px;
        }
      }
      @media (max-width: 767px) and (max-height: 600px) {
        .delete-length-note {
          bottom: -30px;
        }
      }
    }
  }
}

.length-display-platted {
  .length-parameter {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #979797;
    line-height: 17px;
    display: inline-block;
    margin-bottom: 0;
    padding-left: 25px;
    text-transform: uppercase;
  }
}

.edit-div {
  cursor: pointer;
}

.property-checkbox-parent {
  .checkbox-wrapper {
    display: flex;
    margin-bottom: 0;
    label.checkbox-inline {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      font-size: 13px;
      input[type='checkbox'] + span {
        margin-right: 10px !important;
        @media (max-width: 767px) {
          margin-left: 0 !important;
        }
      }
    }
    .row-text {
      font-size: 16px;
    }
    .label-platteddoc-table {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      .header-text {
        font-size: 13px;
      }
      input[type='checkbox'] + span {
        margin-right: 30px !important;
        @media (max-width: 767px) {
          margin-left: 0 !important;
        }
      }
    }
  }
}
