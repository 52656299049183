.platted-record-details-wrapper .platted-record-details-container {
  .container-garden-details {
    flex-grow: 1;
    max-height: calc(100vh - 70px - 60px - 41px - 37px - 100px - 8px);
    overflow-y: auto;
    @media (max-width: 767px) {
      max-height: unset;
    }
    .header-info {
      background: $theme-table-nth;
      padding: 10px 30px;
      border-bottom: 1px solid rgba($theme-tertiary-text, 0.1);
      min-height: 60px;
      border-radius: 4px 4px 0 0;
      @media (max-width: 1023px) {
        padding: 10px 15px;
      }
      h3 {
        @include font($lato, 700, 18px, $theme-secondary-text);
        line-height: 22px;
        margin: 0 0 2px;
        word-break: break-word;
      }
      span {
        @include font($lato, 700, 12px, $theme-tertiary-text);
        line-height: 14px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
      }
    }
    .section-garden-info {
      box-shadow: 0px 2px 4px rgba(231, 231, 231, 0.5);
      border-radius: 4px;
      margin-bottom: 15px;
      background: $white;
      .no-results-container {
        p {
          margin-bottom: 12px;
        }
        .button.btn.secondary.link {
          font-weight: 400;
          text-transform: none !important;
          padding-bottom: 0;
        }
      }
      .info-body {
        @include flex-d(center, center, column);
        padding: 40px 10%;
        input {
          display: none;
        }
        > img {
          max-width: 100%;
        }
        .container-options {
          margin-top: 30px;
          .button.btn {
            border-color: $gainsboro;
            text-transform: none !important;
            font-weight: 500 !important;
            line-height: 19px;
            i {
              padding-right: 8px;
            }
          }
          @media (max-width: 1023px) {
            display: flex;
            flex-direction: column;
            .button.btn {
              margin: 15px 0 0 !important;
            }
          }
        }
        @media (max-width: 767px) {
          padding: 20px 15px;
          .container-options {
            margin-top: 20px;
          }
        }
      }
    }
    .section-deceased-list {
      box-shadow: 0px 2px 4px rgba(231, 231, 231, 0.5);
      border-radius: 4px;
      margin-bottom: 15px;
      background: $white;
      .header-info {
        @include flex(space-between, center);
        min-height: 70px;
        h3 {
          margin-bottom: 0;
        }
        .container-link-buttons {
          display: flex;
          .button.btn {
            &:first-child {
              margin-right: 15px;
            }
          }
          @media (max-width: 850px) {
            flex-direction: column;
            align-items: center;
            .button.btn:first-child {
              margin: 0 0 8px;
            }
          }
          @media (max-width: 767px) {
            .button.btn {
              padding: 8px 10px;
            }
          }
        }
        .button.btn {
          font-size: 14px;
          letter-spacing: 0.5px;
          min-width: 160px;
          @media (max-width: 767px) {
            font-size: 13px;
          }
        }
      }
      .table-container table {
        .no-results-container p {
          margin-top: 12px;
        }
        tr:last-child {
          border-radius: 0 0 4px 4px;
        }
        tbody tr td,
        thead tr th {
          padding-left: 25px;
          &:nth-of-type(1) {
            width: 75%;
            padding-left: 30px;
          }
          &:nth-of-type(2) {
            width: 25%;
            span {
              text-decoration: underline;
              cursor: pointer;
            }
          }
          @media (max-width: 1100px) {
            &:nth-of-type(1) {
              width: calc(100% - 140px);
            }
            &:nth-of-type(2) {
              width: 140px;
            }
          }
          @media (max-width: 767px) {
            padding-left: 10px;
            &:nth-of-type(1) {
              padding-left: 10px;
            }
          }
        }
        &.table-linked-properties {
          .checkbox-wrapper {
            margin-bottom: 0;
            label {
              font-size: 13px;
            }
            .checkbox-inline {
              display: inline-flex;
              flex-direction: row-reverse;
              align-items: center;
              input[type='checkbox'] + span {
                margin: 0 10px 0 0 !important;
              }
            }
          }
          td .checkbox-wrapper label {
            font-size: 16px;
          }
          .properties-checkbox-link {
            display: flex;
            align-items: center;
            .checkbox-wrapper {
              line-height: 10px;
            }
            a {
              color: $theme-text;
              text-decoration: underline;
            }
          }
        }
      }
    }
    .property-message {
      @include font($lato, 700, 13px, $theme-tertiary-text);
      line-height: 16px;
      letter-spacing: 0.4px;
      margin: 25px 0 0;
    }
  }
}

.text-right {
  .assign-garden-note {
    font-style: italic;
    @include font($lato, 400, 16px, $theme-tertiary-text);
  }
}
