$secondary-bg: #fcf9f2;

.pagination-section {
  color: $theme-text;
  font-size: 16px;
  line-height: 19px;
  padding-right: 1rem;

  @extend .d-flex;
  @extend .align-items-center;
  @include get-font($lato, 'regular');

  > div {
    @extend .d-flex;
    @extend .align-items-center;
  }

  .pagination-label {
    padding-right: 1rem;
  }

  .per-page {
    .select-wrapper {
      width: 50px;
      height: 36px;
      border: 1px solid $theme-tertiary-text;
      display: inline-block;
      border-radius: 3px;
      @extend .mb-0;

      .select-dropdown__option--is-focused {
        background-color: transparent !important;
      }

      .select-dropdown__control {
        border: 0 !important;
        min-height: auto;
        height: auto;

        @include box-shadow(none !important);

        .select-dropdown__value-container {
          height: 34px;
          padding: 0;
          align-items: center;
          justify-content: center;

          .select-dropdown__placeholder {
            color: $theme-text;
            height: 34px;
            margin: 0;
            justify-content: center;

            @extend .lato-bold;

            @extend .d-flex;

            @extend .align-items-center;
          }

          .select-dropdown__single-value {
            height: 34px;
            margin: 0;
            justify-content: center;

            @extend .lato-bold;

            @extend .d-flex;

            @extend .align-items-center;
          }
        }
      }

      .select-dropdown__menu {
        width: 50px !important;
        @include box-shadow(0 0px 10px 0 rgba(0, 0, 0, 0.19));
        .select-dropdown__menu-list {
          .select-dropdown__option--is-selected {
            background-color: $table-border-color !important;
            &:after {
              display: none;
            }
          }
        }
      }

      .select-dropdown__indicators {
        display: none;
      }
    }

    .select-dropdown__indicator {
      padding: 4px;
    }
  }

  .records-page {
    padding: 0 1rem;
    span {
      font-weight: 700;
      color: $theme-secondary-text;
    }
  }

  nav.nav-pagination {
    display: inline-block;

    ul {
      margin: 0;

      .page-item {
        .page-link {
          color: $theme-tertiary-text;
          border: 1px solid $theme-border-light;
          background-color: $white;
          font-size: 1rem;
          @include box-shadow(none !important);
          cursor: pointer;
          button {
            border-radius: 2px;
            border: solid 1px $theme-border-light;
            background-color: transparent;
          }
          &:hover,
          &:active {
            border-color: $brand-tertiary;
            background-color: rgba(196, 255, 192, 0.2);
          }
        }
      }
    }
  }
}

.pagination-section {
  color: $theme-text;
  font-size: 16px;
  line-height: 19px;
  padding-right: 1rem;

  @extend .d-flex;
  @extend .align-items-center;

  .light-grey {
    color: $theme-text;
    padding-right: 1rem;

    @extend .lato-regular;
  }
}

.pagination-sm {
  .page-item {
    .page-link {
      background-color: $secondary-bg;
      color: #a2a1a1;
      font-size: 1.5rem;
      padding: 3px 12px;

      span:first-child {
        font-size: 0;

        &::after {
          font-size: 1.2rem;
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          color: #a2a1a1;
        }
      }
    }

    &:first-child {
      .page-link span:first-child {
        &::after {
          content: '\f104';
        }
      }
    }

    &:nth-child(2) {
      .page-link span:first-child {
        &::after {
          content: '\f105';
        }
      }
    }
  }
}

.deceased-wrapper,
.container-burial-details {
  .pagination-section {
    @media (max-width: 1100px) {
      padding-right: 0;
      min-width: 460px;
      justify-content: flex-end;
      .pagination-label {
        min-width: 120px;
      }
      .records-page {
        padding: 0 1rem;
      }
    }
    @media (max-width: 767px) {
      justify-content: flex-start;
      min-width: 0;
    }
    @media (max-width: 500px) {
      flex-direction: column;
      align-items: flex-start !important;
      > div:first-child {
        margin-bottom: 15px;
      }
      .records-page {
        padding-left: 0;
      }
    }
  }
}
