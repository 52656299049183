.dropdown.dropdown-search-filter {
  display: inline-block;
  cursor: pointer;
  @media (max-width: 767px) {
    min-width: 90px;
  }
  &.disabled {
    pointer-events: none;
  }
  .camel-case {
    text-transform: capitalize !important;
  }
  .btn-custom-text {
    p {
      @include font($lato, 700, 14px, $theme-tertiary-text);
      line-height: 17px;
      display: inline-block;
      margin-bottom: 0;
      text-transform: uppercase;
    }
    .dropdown-arrow {
      margin-left: 12px;
      display: inline-block;
      transition: all 0.5s;

      &.active {
        transform: rotate(180deg);
      }
    }
    .fa-filter {
      margin-right: 6px;
      color: $theme-tertiary-text;
    }
  }
  &.show {
    .btn-custom-text {
      p,
      .fa-filter {
        color: $brand-tertiary;
      }
    }
  }
  .dropdown-menu {
    width: 443px;
    height: 150px;
    background: $white;
    border: 1px solid $white;
    box-sizing: border-box;
    box-shadow: 0px 5px 14px $theme-dropdown-shadow;
    top: 14px !important;
    left: -20px !important;
    &:before {
      border-color: transparent transparent $white $white;
      right: auto;
      left: 36px;
    }
    &[x-placement='top-start'] {
      top: 225px !important;
      &:before {
        top: auto;
        bottom: -15px;
        left: 17px;
        transform: rotate(315deg);
      }
    }
    @media (max-width: 1100px) {
      left: 0 !important;
    }
    @media (max-width: 767px) {
      width: 100%;
      max-width: 440px;
    }
  }
}
.filter-search-wrapper {
  position: relative;
  .filter-search {
    i {
      position: absolute;
      top: 30px;
      left: 25px;
      color: $theme-tertiary-text;
      font-size: 13px;
    }
    input {
      width: 100%;
      border: none;
      padding: 27px 20px 20px 48px;
      border-bottom: 1px solid $theme-border-gray-fade;
      line-height: 19px;
      @include font($lato, 400, 16px, $theme-text !important);
    }
  }
  .filter-btn-wrapper {
    @include flex(flex-end, center);
    padding: 20px 23px;
    cursor: default;
    border-top: 1px solid $theme-border-gray-fade;
    .button {
      border: none;
      &.btn-apply {
        min-width: 110px;
        margin-left: 35px;
      }
    }
    @media (max-width: 767px) {
      padding: 15px;
      .button.btn-apply {
        min-width: 100px;
      }
    }
  }
  .filter-form-wrapper {
    padding: 24px 14px 4px 34px;
    .input-field {
      margin-bottom: 20px;
      label {
        font-family: 'Lato';
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        color: $theme-tertiary-text;
        margin-bottom: 11px;
      }
    }
    .select-wrapper {
      margin-bottom: 20px;
      label {
        font-family: 'Lato';
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        color: $theme-tertiary-text;
        margin-bottom: 11px;
      }
      .checkbox-wrapper {
        margin-bottom: 0;
        label {
          margin-bottom: 20px;
          &.checkbox-inline {
            input[type='checkbox']:checked + span {
              border: 1px solid $brand-tertiary;
              background-color: $brand-tertiary;
            }
          }
          span {
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            &.active {
              font-weight: 700;
            }
          }
        }
      }
      &.fitler-status {
        label {
          text-transform: capitalize;
        }
        .checkbox-wrapper {
          label {
            span {
              margin-bottom: 4px;
            }
          }
        }
      }
    }
  }
}
.filter-results-wrapper {
  min-height: 74px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background-color: $white;
  border-top: 1px solid rgba($theme-tertiary-text, 0.2);
  .filter-results-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .filter-label-wrapper {
      line-height: 19px;
      margin-right: 18px;
      @include font($lato, 400, 16px, $theme-secondary-text);
      min-width: 100px;
    }
    .wrapper-filter-values {
      display: flex;
      flex-wrap: wrap;
    }
    .filter-value-wrapper {
      background: $theme-modal-tagbg;
      padding: 8px 16px;
      border-radius: 16.5px;
      line-height: 17px;
      @include font($lato, 700, 14px, $theme-secondary-text);
      margin: 2px 12px 2px 0;
      img {
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }
  .filter-clear-wrapper .btn-filter {
    font-size: 14px;
    padding: 10px;
    min-width: 125px;
  }
  @media (max-width: 767px) {
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    .filters-buttons-container {
      margin-top: 10px;
    }
  }
}
