.deceased-wrapper {
  .container-garden-detail {
    @include flex(space-between, center);
    background-color: $white;
    box-shadow: 0px 3px 10px rgba(199, 199, 199, 0.25);
    padding: 20px 25px;
    margin-bottom: 25px;
    .garden-detail-left {
      @include flex(flex-start, center);
      .icon-holder {
        @include flex(center, center);
        height: 42px;
        width: 42px;
        background-color: rgba($theme-tertiary-text, 0.2);
        border-radius: 4px;
        i {
          font-size: 18px;
          color: $black;
        }
      }
      .garden-detail {
        padding: 0 30px;
        strong {
          font-size: 18px;
          line-height: 22px;
          color: $theme-text;
          display: block;
          margin-bottom: 8px;
        }
        span {
          font-size: 16px;
          line-height: 19px;
          color: $theme-tertiary-text;
        }
      }
    }
    .garden-detail-right {
      .btn {
        font-size: 14px;
        padding: 10px 15px !important;
        &:first-child {
          margin-right: 16px;
        }
      }
    }
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 15px;
      .garden-detail-left {
        margin-bottom: 15px;
        .garden-detail {
          padding: 0 0 0 20px;
          strong {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .table-container-search-results-property {
    .table {
      thead tr th,
      tbody tr td {
        &:nth-of-type(1) {
          width: 25%;
        }
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4) {
          width: 140px;
        }
        &:nth-of-type(5) {
          width: calc(75% - (3 * 140px) - 70px);
          border-right: 0;
        }
        &:nth-of-type(6) {
          width: 70px;
        }
      }
    }
  }
  .property-msg {
    @include font($lato, 700, 13px, $theme-tertiary-text);
    letter-spacing: 0.4px;
    line-height: 16px;
    margin: 25px 0 0;
  }
}

.dropdown.view-other-owners-dropdown,
.dropdown.view-other-properties-dropdown {
  display: inline;
  > .btn {
    width: auto;
    color: $brand-primary !important;
    text-decoration: underline;
    float: none;
    display: inline !important;
    height: auto;
    line-height: normal;
    margin-top: -4px;
  }
  .dropdown-menu {
    top: 12px !important;
    &[x-placement='top-end'] {
      top: -7px !important;
    }
    .items-wrapper {
      max-height: 275px;
      overflow-y: scroll;
    }
    button a {
      text-decoration: underline;
    }
  }
}
