.text-area {
  width: 100%;

  label {
    color: $theme-text;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;

    span {
      color: $error-msg;
    }
  }

  .error-msg:empty {
    padding: 0;
  }

  textarea {
    width: 100%;
    color: $theme-text;
    resize: none;
    border: 1px solid $theme-tertiary-text;
    padding: 15px;
    font-size: 16px;

    @include border-radius(4px);
    @include placeholder-color($theme-tertiary-text);

    &:focus {
      border: 1px solid $theme-tertiary-text;
    }
  }
}
