.back-btn-wrapper {
  padding-bottom: 20px;

  .back-btn {
    border: 0;
    padding: 0;
    background-color: transparent;

    @include box-shadow(none);

    > i {
      color: $theme-secondary-text;
      font-size: 14px;
      margin-right: 8px;
    }

    > span {
      color: $theme-secondary-text;
      font-size: 14px;
      line-height: 17px;

      @extend .lato-bold;
    }
  }
}
