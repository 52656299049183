@import '../../../assets/styles/color';

.checkbox-wrapper {
  margin-bottom: $form-control-margin-bottom;

  label {
    font-size: 16px;
    &.checkbox-inline {
      cursor: pointer;
      margin-bottom: 0;

      &.disabled {
        cursor: not-allowed;
      }
      .highlight {
        font-weight: 700 !important;
      }

      input[type='checkbox'] {
        display: none;

        &:checked {
          + span {
            border: 1px solid $brand-primary;
            padding: 3px;
            background-color: $brand-primary;

            @include border-radius(2px);

            @include transition(all 0.3s ease-in-out);

            i {
              opacity: 1;
            }
          }
        }

        + span {
          width: 20px;
          border: 1px solid $theme-tertiary-text;
          cursor: pointer;
          height: 20px;
          margin: -1px 10px 0 0;
          display: inline-block;
          padding: 3px;
          position: relative;
          text-align: center;
          line-height: 14px;
          margin-right: 0;
          vertical-align: middle;
          background-color: $white;

          @include border-radius(2px);

          @include transition(all 0.3s ease-in-out);

          i {
            color: $white;
            width: 100%;
            height: 100%;
            display: block;
            opacity: 0;
            overflow: visible;
            position: relative;
            font-size: 12px;
            font-weight: 400;

            @include transition(all 0.3s ease-in-out);

            &::after {
              top: 50%;
              left: 50%;
              content: '\f00c';
              position: absolute;
              font-weight: 900;

              @include translate(-50%, -50%);

              @extend .custom-fontawesome;
            }
          }
        }
        &[disabled] {
          + span {
            cursor: not-allowed;
            opacity: 0.5;
          }
        }
      }
    }
  }
}
