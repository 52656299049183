.geo-location-wrapper {
  &.indoor-maps-wrapper {
    .container-geo-file-upload {
      .geo-location-header {
        background: $white;
        padding: 16px 32px;
        .geo-location-header-left {
          padding-bottom: 16px;
          border-bottom: 1px solid $theme-light-gray;
        }
      }
      .upload-file-section {
        padding-top: 8px;
        p {
          font-family: $lato;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: $theme-obstruct;
        }
        .file-category {
          .select-wrapper {
            label {
              font-size: 16px;
              margin-bottom: 8px;
            }
          }
        }
        .upload-file-wrapper {
          padding: 2px 0 16px;
          font-family: $lato;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          margin-bottom: 0;
          color: $theme-tertiary-text;
        }
        .upload-content-wrapper {
          h6 {
            font-family: $lato;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: $theme-text;
            margin-bottom: 0;
            margin-right: 42px;
          }
        }
        .add-floor-wrapper {
          font-family: $lato !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-size: 16px !important;
          line-height: 19px;
          display: block;
          text-decoration: underline;
          margin: 32px 0;
          color: $brand-secondary;
          border-bottom: none !important;
        }
        .upload-container {
          padding-top: 20px;
          margin-top: 0;
          button {
            min-width: 140px;
            font-style: normal;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: $white;
            padding: 12px 20px;
            margin-right: 10px;
            &.clear {
              background: $white;
              color: rgba(132, 132, 132, 0.6);
            }
          }
        }
      }
      .select-wrapper {
        .select-dropdown__control {
          max-width: 290px;
        }
        .css-26l3qy-menu {
          max-width: 290px;
        }
      }
      .custom-file-upload {
        margin-bottom: 24px;
      }
    }
  }
}
