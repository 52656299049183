.loader-container {
  position: relative;
  // overflow: hidden;

  .success-container {
    padding: 119px 163px;
    text-align: center;
    img {
      width: 60px;
      height: 60px;
      margin-bottom: 21px;
    }
    p {
      line-height: 175%;
      max-width: 100%;
      text-align: center;
      margin-bottom: 21px;
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      font-size: 20px;
      color: #212121;
    }
  }
}

.loader-section {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  height: 100%;
  z-index: 2000;
  position: absolute;
  background-color: rgba($black, 0.2);
  &.loader-block {
    width: auto;
    height: auto;
    position: absolute;
    .loader {
      height: auto;
      > div {
        background-color: $white;
      }
    }
  }
  .loader {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      width: 13px;
      height: 13px;
      margin: 32px 3px;
      background: $white;
      border-radius: 50%;
      animation: bouncing-loader 0.6s infinite alternate;
      &:nth-child(2) {
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }
}
@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -16px, 0);
  }
}
