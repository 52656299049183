$padding: 30px;

.panel-container {
  background-color: $white;
  margin-bottom: 20px;
  @extend .simple-box-shadow;

  .panel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $theme-table-nth;
    padding: 11px $padding;
    @media (max-width: 767px) {
      padding: 11px 15px;
    }

    &.panel-primary {
      background-color: $theme-table-nth;
    }

    .panel-header-icon {
      color: $theme-tertiary-text;
      font-size: 18px;
      &.panel-header-icon-left {
        margin-right: 10px;
      }
      &.panel-header-icon-right {
        margin-left: auto;
      }
    }

    .panel-header-btn {
      &.panel-header-btn-right {
        margin-left: auto;
      }
    }

    .panel-header-text-wrap {
      height: 100%;
      width: 100%;
      flex-grow: 1;
      @extend .d-flex;
      @extend .align-items-center;
      @extend .justify-content-start;

      > span {
        color: $theme-secondary-text;
        font-size: 18px;
        line-height: 22px;
        @include get-font($lato, 'bold');
      }

      i:not(.crumb-separator),
      + i {
        font-size: 18px;
      }
    }
  }

  .panel-body {
    @extend .simple-box-shadow;
    padding: 0 $padding;
  }
  &.simple-panel-container {
    box-shadow: 0px 3px 10px rgba(199, 199, 199, 0.25);
    .panel-header {
      background-color: transparent;
      padding: 16px 25px;
      border-bottom: 1px solid rgba($theme-tertiary-text, 0.2);
      .panel-header-text-wrap span {
        font-size: 18px;
        line-height: 22px;
        color: $theme-tertiary-text;
      }
    }
  }
}
