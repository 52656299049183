.activity-comments-body {
  text-align: left;
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0;
  white-space: pre-wrap;

  &.details {
    text-align: left;
    line-height: 160%;
    word-break: break-word;
    white-space: pre-line !important;
  }

  &.full-text {
    text-overflow: unset;
    overflow: initial;
    display: block;
  }
}

.activity-comments-footer {
  > span {
    color: $theme-tertiary-text;
    display: inline-block;
    font-size: 16px;
    line-height: 19px;

    @extend .lato-regular;
  }
  > small {
    color: $theme-tertiary-text;
    font-size: 16px;
    line-height: 16px;

    @extend .lato-regular;
  }
  @include font($lato, 400, 16px, $theme-tertiary-text);
  line-height: 16px;
  margin-top: 16px;
}
