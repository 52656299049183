.input-field {
  &.with-icon.search-icon {
    position: relative;

    &::after {
      content: '\f002';
      font-weight: 900;
      font-size: 16px;
      color: $theme-tertiary-text;
      position: absolute;
      top: 29px;
      left: 15px;
      pointer-events: none;

      @extend .custom-fontawesome;
    }
  }

  label {
    color: $theme-text;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;

    span {
      color: $error-msg;
    }
  }

  .input-right-content {
    float: right;
    font-size: 14px;
  }

  input {
    @extend .lato-regular;

    outline: 0;
    font-weight: 400;
    font-size: 16px;
    min-width: 150px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid $theme-tertiary-text;
    padding: 15px;

    &[type='search'] {
      padding: 15px 15px 15px 40px;
      font-weight: 700 !important;
      &::placeholder {
        font-weight: 400 !important;
      }
      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
        height: 12px;
        width: 12px;
        background: url(../../../assets/images/cross-icon.svg) no-repeat;
        margin-left: 5px;
        cursor: pointer;
      }
    }

    &:focus {
      border: 1px solid $theme-tertiary-text;
    }

    &:disabled {
      cursor: not-allowed;
      color: $theme-tertiary-text !important;
      background-color: #f2f2f2;
    }

    &.lg {
      height: 56px;
    }

    &.md {
      height: 48px;
    }

    &.sm {
      height: 36px;
    }

    &.xs {
      height: 28px;
    }
  }
}
