.deceased-wrapper {
  position: relative;
  .err-msg-custom {
    position: absolute;
    top: calc(2rem + 45px - 10px);
    left: 290px;
  }
  .btn-activity-history {
    position: absolute;
    top: 45px;
    right: 5px;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
    padding: 12px 19px;
    color: $brand-secondary;
    background-color: $white;
    border: 1px solid $brand-secondary;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .deceased-search-wrapper {
    margin-bottom: 25px;
    .panel-body {
      padding: 0;
      .form-elements-wrapper {
        padding: 30px 25px 0;
        .input-field {
          margin-bottom: 30px;
        }
        .success-message-wrapper {
          font-style: normal;
          line-height: 27px;
          padding: 16px 0 50px;
          @include font($lato, 400, 18px, $theme-text);
        }
      }
      .buttons-footer {
        padding: 20px 25px;
        border-top: 1px solid rgba($theme-tertiary-text, 0.2);
        button:first-child {
          min-width: 120px;
          margin-right: 40px;
        }
      }
    }
    .advanced-search-wrapper {
      > button {
        margin-bottom: 30px;
        i {
          padding-left: 5px;
        }
      }
      h3 {
        @extend .lato-bold;
        font-size: 14px;
        color: $theme-tertiary-text;
        line-height: 17px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        margin: 0 0 18px;
      }
    }
  }
  .search-results-wrapper {
    .header-search-results {
      @include flex(space-between, center);
      background-color: $theme-table-nth;
      min-height: 66px;
      padding: 15px;
      h2 {
        @include font($lato, 700, 18px, $brand-primary);
        text-transform: uppercase;
        margin-bottom: 0;
      }
    }
    .table-container .table {
      thead tr th,
      tbody tr td {
        &:nth-of-type(1) {
          padding-left: 25px;
        }
      }
      .dropdown-with-dots {
        .dropdown-menu {
          left: 15px !important;
          &::before {
            right: 12px;
          }
        }
      }
      .no-results-container {
        padding: 7rem 0;
        p {
          margin-bottom: 5rem;
        }
      }
    }
    .table-container-search-results {
      .table {
        thead tr th,
        tbody tr td {
          &:nth-of-type(1) {
            width: 18%;
          }
          &:nth-of-type(2),
          &:nth-of-type(3),
          &:nth-of-type(4),
          &:nth-of-type(5) {
            width: 140px;
            text-align: center;
          }
          &:nth-of-type(6) {
            width: calc(82% - (4 * 140px) - 70px);
            border-right: 0;
          }
          &:nth-of-type(7) {
            width: 70px;
          }
        }
      }
      .btn-save-link {
        width: 94px;
        line-height: 14px;
        margin-top: 12px;
        @include font($lato, 700, 12px, $white);
      }
      textarea::-webkit-input-placeholder {
        font-size: 16px;
        line-height: 19px;
        color: $theme-tertiary-text;
        opacity: 0.5;
      }

      textarea:-moz-placeholder {
        /* Firefox 18- */
        font-size: 16px;
        line-height: 19px;
        color: $theme-tertiary-text;
        opacity: 0.5;
      }

      textarea::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 16px;
        line-height: 19px;
        color: $theme-tertiary-text;
        opacity: 0.5;
      }

      textarea:-ms-input-placeholder {
        font-size: 16px;
        line-height: 19px;
        color: $theme-tertiary-text;
        opacity: 0.5;
      }

      textarea::placeholder {
        font-size: 16px;
        line-height: 19px;
        color: $theme-tertiary-text;
        opacity: 0.5;
      }
    }
  }
  &.activity-table-wrapper {
    .search-results-wrapper {
      .table-container-search-results {
        .table {
          thead tr th,
          tbody tr td {
            &:nth-of-type(1) {
              width: 17%;
              text-align: left;

              &.map {
                width: 12% !important;
              }
            }
            &:nth-of-type(2) {
              width: 15%;
              text-align: left;

              &.map {
                width: 10% !important;
              }
            }
            &:nth-of-type(3) {
              width: 10%;
              text-align: left;

              &.map {
                width: 14% !important;
              }
            }
            &:nth-of-type(4) {
              width: 20%;
              text-align: left;
              line-height: 160%;
              word-break: break-word;
              white-space: pre-line;
              p {
                margin-bottom: 0;
              }

              &.map {
                width: 10% !important;
                line-height: inherit !important;
                word-break: unset !important;
              }
            }
            &:nth-of-type(5) {
              width: 38%;
              text-align: left;
              // font-size: 15px;
              padding: 10px 15px;
              line-height: 185%;

              &.map {
                width: 20% !important;
                line-height: 160% !important;
                word-break: break-word !important;
                p {
                  margin-bottom: 0 !important;
                }
              }
            }
            &.map:nth-of-type(6) {
              width: 34% !important;
              text-align: left !important;
              padding: 10px 15px !important;
              line-height: 185% !important;
            }
          }
          tbody tr td {
            vertical-align: baseline;
            &.deleted-record {
              color: $brand-secondary !important;
            }
            &.added-record {
              color: $green !important;
            }
            .activity-date-wrapper {
              @include font($lato, 400, 14px, $theme-tertiary-text);
              line-height: 17px;
              padding-top: 5px;
            }
            &:nth-of-type(3) {
              color: $black;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .deceased-wrapper .search-results-wrapper {
    #deceased-search-results {
      display: block;
      overflow: auto;
      thead tr th,
      tbody tr td {
        &:nth-of-type(1) {
          width: 200px;
          min-width: 200px;
        }
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          min-width: 150px;
          width: 150px;
          text-align: left;
        }
        &:nth-of-type(6) {
          width: 200px;
          min-width: 200px;
        }
        &:nth-of-type(7) {
          width: 70px;
          min-width: 70px;
        }
      }
    }
    #property-search-results {
      thead tr th,
      tbody tr td {
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4) {
          width: 100px;
        }
        &:nth-of-type(5) {
          width: calc(75% - 350px);
        }
        &:nth-of-type(6) {
          width: 50px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .deceased-wrapper {
    #deceased-search-form .form-elements-wrapper,
    #property-search-form .form-elements-wrapper,
    #owner-search-form .form-elements-wrapper {
      padding: 20px 15px;
      .input-field {
        margin-bottom: 20px;
      }
      .row {
        > div {
          max-width: 48%;
          flex: 0 0 48%;
        }
      }
      .advanced-search-wrapper {
        button {
          margin-bottom: 20px;
        }
      }
    }
    .search-results-wrapper #property-search-results {
      display: block;
      overflow-x: auto;
      thead tr th,
      tbody tr td {
        &:nth-of-type(1),
        &:nth-of-type(5) {
          width: 250px;
          min-width: 250px;
        }
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4) {
          width: 120px;
          min-width: 120px;
        }
        &:nth-of-type(6) {
          width: 70px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .deceased-wrapper {
    #deceased-search-form .form-elements-wrapper,
    #property-search-form .form-elements-wrapper,
    #owner-search-form .form-elements-wrapper {
      .row > div {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }
}
