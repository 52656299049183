.notes-list-wrapper {
  padding-top: 25px;
  @media (max-width: 767px) {
    padding-top: 15px;
  }

  .title-comments {
    @include font($lato, 700, 18px, $theme-secondary-text);
    margin: 0 0 25px 70px;
    @media (max-width: 1100px) {
      margin-left: 25px;
    }
    @media (max-width: 767px) {
      margin: 0 0 15px;
      padding-left: 10px;
    }
  }

  .no-data-message-comments {
    color: $theme-tertiary-text;
    margin: 0 0 0 70px;
    padding-bottom: 45px;
    @media (max-width: 1100px) {
      margin-left: 25px;
      padding-bottom: 20px;
    }
    @media (max-width: 767px) {
      margin-left: 10px;
    }
  }

  .notes-item-group {
    overflow: visible;

    .notes-item {
      display: flex;
      justify-content: space-between;
      padding: 20px 15px;
      margin: 0 70px;
      border-top: 1px solid rgba($theme-tertiary-text, 0.2);
      @media (max-width: 1100px) {
        margin: 0 25px;
      }
      @media (max-width: 767px) {
        margin: 0;
      }
      .notes-item-left {
        max-width: 85%;
        @media (max-width: 767px) {
          max-width: calc(100% - 50px);
        }
      }

      .notes-footer {
        background-color: transparent;
        margin-top: 16px;

        > span {
          color: $theme-tertiary-text;
          display: inline-block;
          font-size: 16px;
          line-height: 19px;

          @extend .lato-regular;
        }

        > small {
          color: $theme-tertiary-text;
          font-size: 16px;
          line-height: 16px;

          @extend .lato-regular;
        }
      }

      .notes-body {
        color: $theme-text;
        line-height: 27px;
        padding: 0;
        font-size: 16px;
        text-align: justify;
        word-break: break-word;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        overflow: hidden;
        margin-bottom: 0;
        min-width: 700px;
        white-space: pre-wrap;

        &.full-text {
          text-overflow: unset;
          overflow: initial;
          display: block;
        }
      }

      &.disabled {
        background-color: transparent;

        .notes-body {
          color: $theme-tertiary-text;
        }
      }
    }

    .button.btn.primary.text.btn-show-more {
      width: 100%;
      background-color: $theme-light-gray !important;
      border-radius: 0;
      min-height: 55px;
      font-weight: 400 !important;
      &:hover,
      &:active {
        background-color: $theme-light-gray !important;
      }
    }
  }
}

.option-read-more {
  @include font($lato, 400, 16px, $theme-tertiary-text);
  line-height: 17px;
  cursor: pointer;
  float: right;
  text-decoration: underline;
  @media (max-width: 767px) {
    width: 50px;
  }
}
