.deceased-full-profile-wrapper.owner-details-wrapper {
  .deceased-sidebars-wrapper.owners-sidebars-wrapper {
    max-height: calc(100vh - 70px - 60px - 41px - 37px - 105px);
    .sidebar-menu-wrapper {
      padding: 2px 0 0;
      li {
        padding: 16px 36px;
      }
    }
    @media (max-width: 767px) {
      max-height: unset;
      overflow: visible;
      padding: 20px 10px;
      .sidebar-menu-wrapper {
        top: 55px;
      }
      .deceased-profile-wrapper {
        max-width: 100%;
        padding-top: 0;
        background: none;
        .location-wrapper {
          border-bottom: none;
          padding: 0;
          @include flex(space-between, center);
          .sidebar-icon-deceased {
            position: static;
          }
          .activity-wrapper {
            border-top: none;
            padding: 5px;
            font-size: 16px;
          }
        }
      }
    }
  }
  .profile-modules-container.owner-module-container {
    .msg-status {
      &.in-progress {
        background-color: $theme-status-inprogess;
      }
      &.completed {
        background-color: $theme-success;
      }
    }
    max-height: calc(100vh - 70px - 60px - 41px - 37px - 105px);
    @media (max-width: 767px) {
      max-height: unset;
      width: 100%;
      overflow: hidden;
    }
  }
  .title-info-container {
    @include flex(space-between, center);
    background-color: $white;
    box-shadow: 0px 2px 4px rgba(231, 231, 231, 0.5);
    border-radius: 4px;
    padding: 5px 35px;
    min-height: 90px;
    margin-bottom: 15px;
    @media (max-width: 1023px) {
      padding: 5px 15px;
    }
    .title-info-left {
      h3 {
        @include font($lato, 700, 24px, $theme-text);
        margin: 0 0 5px;
      }
      span {
        @include font($lato, 400, 18px, $brand-primary);
      }
    }
    .contact-info {
      display: flex;
      margin-left: 20px;
      > div {
        display: flex;
        align-items: center;
        @include font($lato, 400, 16px, $theme-secondary-text);
        line-height: 19px;
        i {
          margin-right: 6px;
          margin-top: 3px;
          color: $theme-tertiary-text;
          &.fa-phone {
            @include transform(rotate(90deg));
          }
        }
        &:first-child {
          padding-right: 16px;
          margin-right: 16px;
          border-right: 1px solid rgba($theme-tertiary-text, 0.2);
        }
      }
    }
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      .title-info-left {
        h3 {
          margin-bottom: 0;
        }
      }
      .contact-info {
        margin: 15px 0 0;
      }
    }
  }
  .profile-modules-container {
    .container-personal-details {
      .card-details-container {
        padding: 0;
        .section-card {
          flex-grow: 1;
          > h3 {
            @include flex(flex-start, center);
            @include font($lato, 700, 14px, $theme-tertiary-text);
            background-color: $theme-light-gray;
            border-top: 1px solid rgba($theme-tertiary-text, 0.2);
            border-bottom: 1px solid rgba($theme-tertiary-text, 0.2);
            min-height: 38px;
            text-transform: uppercase;
            margin: 0 0 0 50px;
            padding: 5px 20px;
            @media (max-width: 1023px) {
              margin-left: 25px;
            }
            @media (max-width: 767px) {
              margin-left: 5px;
            }
          }
          .section-card-details {
            display: flex;
            flex-wrap: wrap;
            padding: 25px 60px 0 70px;
            &.section-card-deatils-resedential {
              .item-card {
                &:nth-of-type(1),
                &:nth-last-of-type(1) {
                  width: 50%;
                }
                &:nth-of-type(4) {
                  width: 35%;
                }
                &:nth-of-type(5) {
                  width: 15%;
                }
              }
            }
            &.section-card-deatils-ethnicity {
              .item-card {
                width: 50%;
              }
              .details-multiselect {
                .multi-select-wrap label {
                  margin-bottom: 10px;
                }
              }
            }
            @media (max-width: 1023px) {
              padding: 20px 35px 0;
            }
            @media (max-width: 767px) {
              padding: 20px 15px 0;
            }
          }
          @media (max-width: 1023px) {
            .item-card {
              width: 50% !important;
            }
          }
        }
      }
    }
    .table-container-owner-property {
      tbody tr td,
      thead tr th {
        &:nth-of-type(1) {
          width: 529px !important;
        }
        &:nth-of-type(2) {
          width: 215px;
        }
        &:nth-of-type(3) {
          width: 130px;
        }
        &:nth-of-type(4) {
          width: 150px;
        }
      }
      tbody tr {
        .no-results-column {
          width: 100% !important;
          .no-results-container {
            width: 100%;
          }
        }
      }
      @media (max-width: 1023px) {
        table {
          display: block;
          overflow-x: auto;
          tbody tr td,
          thead tr th {
            &:nth-of-type(1) {
              width: 350px;
              min-width: 350px;
            }
            &:nth-of-type(2) {
              width: 200px;
              min-width: 200px;
            }
            &:nth-of-type(3),
            &:nth-of-type(4) {
              width: 150px;
              min-width: 150px;
            }
          }
        }
      }
    }
    .table-container-contacts {
      @media (max-width: 1023px) {
        .table {
          display: block;
          overflow-x: auto;
          tbody tr td,
          thead tr th {
            &:nth-of-type(odd) {
              width: 250px;
              min-width: 250px;
            }
            &:nth-of-type(even) {
              width: 150px;
              min-width: 150px;
            }
          }
        }
      }
    }
  }
}
