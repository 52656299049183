@import '../../../assets/styles/fonts';
@import '../../../assets/styles/mixins';
@import '../../../assets/styles/color';

.timepicker-wrapper {
  .react-datepicker-ignore-onclickoutside,
  input {
    cursor: pointer;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker-popper {
    transform: translate(0) !important;
    top: 100% !important;
    background: transparent;
    z-index: 2;
    position: relative;
    .react-datepicker__time {
      padding: 0 !important;
    }
    .react-datepicker__time-box {
      width: 100% !important;
    }
    .react-datepicker__time-list {
      list-style: none;
      padding: 10px 0 !important;
      margin: 0;
      max-height: 250px;
      overflow-y: auto !important;
      .react-datepicker__time-list-item {
        @include font($lato, 500, 15px, $theme-text);
        margin-bottom: 10px;
        cursor: pointer;
        background-color: transparent !important;
        &.react-datepicker__time-list-item--selected {
          color: $brand-tertiary !important;
          background-color: transparent !important;
        }
      }
    }
    .react-datepicker__time-container {
      width: 120px;
    }
    .react-datepicker__header {
      @include font($lato, 700, 14px, $theme-text);
      padding: 8px;
      border-bottom: 1px solid $theme-tertiary-text;
      text-align: center;
    }
    .react-datepicker__time {
      padding: 15px;
    }
  }
}
