.menu-right-mapbox {
  position: absolute;
  top: 16px;
  right: 62px;
  background: #fff;
  border: 1px solid #979797;
  border-radius: 4px;
  padding: 5px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #000;
  width: auto;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .radio {
    line-height: 14px;
    font-size: 12px;
    margin-bottom: 0;
    margin-right: 5px !important;
    white-space: nowrap;
    color: #212121;
    &:nth-of-type(2) {
      margin-right: 0 !important;
    }
    input + span {
      width: 15px;
      height: 15px;
    }
    &:last-of-type {
      margin-left: 10px !important;
    }
  }
  .select-wrapper {
    width: auto;
    margin-bottom: 0;
    label {
      font-size: 14px !important;
    }
    .select-dropdown__control {
      min-height: 30px;
      max-height: 30px;
      .select-dropdown__indicators {
        max-height: 30px !important;
      }
      .select-dropdown__input {
        max-width: 20px !important;
        padding-top: 0px !important;
      }
      .select-dropdown__single-value {
        font-size: 12px !important;
        // top: 46%;
      }
      .select-dropdown__placeholder {
        font-size: 12px;
        // top: 46%;
      }
    }
    &.lg {
      .select-dropdown__value-container {
        max-height: 30px;
        padding: 0px 6px 2px 6px;
      }
    }
    .select-dropdown__menu {
      .select-dropdown__menu-list {
        min-height: 90px;
        .select-dropdown__option {
          font-size: 12px;
          padding: 6px 20px 2px 6px;
          overflow: hidden;
          max-width: 100px;
          height: 30px;
          text-overflow: ellipsis;
        }
      }
    }
    .select-dropdown__option--is-selected {
      &::after {
        right: -6px;
        padding: 3px;
        font-size: 12px;
      }
    }
  }
  @media (max-width: 767px) {
    right: 20px;
  }
}

.mapbox-delete {
  .buttons-container-confirm {
    padding-top: 30px;
    justify-content: center !important;
  }
  .modal-content {
    margin: auto;
    .modal-body {
      min-width: 300px !important;
    }
  }
  .buttons-container-reason {
    justify-content: unset !important;
    padding-top: 30px;
  }
}

.map-search-wrapper {
  position: relative;
  height: 100vh;
  .rectangle-icon-disabled {
    opacity: 0.3;
  }
  .map-search-module {
    position: absolute;
    right: 12px;
    top: 16px;
    min-width: 385px;
    background: $white;
    box-shadow: 0px 3px 10px rgba(199, 199, 199, 0.25);
    z-index: 99;
    @media (max-width: 1023px) {
      z-index: 2;
    }
    .title-search {
      @include flex(space-between, center);
      min-height: 58px;
      padding: 10px 22px;
      h4 {
        @include font($lato, 700, 16px, $theme-tertiary-text);
        line-height: 19px;
        margin-bottom: 0;
      }
      span {
        color: $theme-tertiary-text;
        font-size: 16px;
        padding: 0;
        line-height: normal;
        cursor: pointer;
      }
    }
    .body-search {
      padding: 20px 35px;
      border-top: 1px solid rgba($theme-tertiary-text, 0.2);
      border-bottom: 1px solid rgba($theme-tertiary-text, 0.2);
      .select-wrapper {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .footer-search {
      padding: 20px 35px;
      button:first-child {
        min-width: 115px;
        margin-right: 40px;
      }
    }
    &.map-building-module {
      max-width: 1415px;
      height: auto;
      display: flex;
      left: 65px;
      top: 130px;
    }

    .select-dropdown__control {
      max-width: 315px;
    }
    @media (max-width: 767px) {
      min-width: 0;
      width: 100%;
      max-width: 385px;
      background: transparent;
      padding: 0 10px;
      left: auto;
      right: auto;
      top: 60px;
      .map-search-module-inner {
        background: $white;
      }
      .body-search {
        max-height: calc(90vh - 150px);
        overflow-y: auto;
      }
      .select-dropdown__control {
        max-width: 100%;
      }
    }
  }
  .map-search-results-module {
    width: 660px;
    top: 12px;
    box-shadow: none;
    @media (max-width: 1023px) {
      right: auto;
      top: 60px;
      left: 62px;
    }
    @media (max-width: 767px) {
      max-width: 100%;
      left: auto;
      .loader-container {
        background: $white;
      }
    }
    .cemetery-panoramic-view,
    .fa-times {
      cursor: pointer;
    }
    .title-results {
      background: $theme-table-nth;
      min-height: 54px;
      .title-results-left {
        display: flex;
        align-items: center;
        a {
          margin-right: 30px;
        }
      }
    }
    .reservation-confirmation {
      padding: 119px 163px;
      text-align: center;
      .check-circle-img {
        margin-bottom: 15px;
      }
      p {
        line-height: 175%;
        max-width: 100%;
        text-align: center;
        margin-bottom: 21px;
        @include font($lato, 400, 20px, $theme-text);
      }
      .success-btn {
        margin-right: 50px;
      }
      .btn-back {
        color: $modal-dialog-title;
        background: $white;
        margin-top: 0;
        margin-bottom: 0;
        border: 1px solid $modal-dialog-title;
        &:hover {
          color: $modal-dialog-title;
          background: $white !important;
          border: 1px solid $modal-dialog-title;
        }
      }
      @media (max-width: 767px) {
        padding: 20px;
        p {
          font-size: 18px;
          line-height: 140%;
        }
        .button.btn.md:not(.btn-back) {
          min-width: 100px;
        }
        .success-btn {
          margin-right: 20px;
        }
      }
    }
    .section-property-title-container {
      padding: 10px 10px 15px;
      .section-property-title {
        @mixin background($bg-light, $bg) {
          background: rgba($bg, 0.32);
          &:before {
            background: rgba($bg-light, 0.9);
          }
        }
        @mixin backgroundColor($bg-light, $bg) {
          background: $bg;
          &:before {
            background: $bg-light;
          }
        }
        @include flex(space-between, center);
        min-height: 60px;
        padding: 10px 25px;
        position: relative;
        @media (max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
        }
        &:before {
          position: absolute;
          content: '';
          height: 100%;
          width: 9px;
          top: 0;
          left: 0;
        }
        &.undefined {
          background-color: $white;
          box-shadow: 0px 3px 10px rgba(199, 199, 199, 0.25);
        }
        &.empty {
          @include background($theme-empty, $theme-empty);
        }
        &.for.sale {
          @include backgroundColor($theme-for-sale, $theme-for-sale-light);
        }
        &.sold {
          @include backgroundColor($theme-sold, $theme-sold-light);
        }
        &.occupied {
          @include backgroundColor($theme-occupied, $theme-occupied-light);
        }
        &.reserved {
          @include backgroundColor($theme-reserved, $theme-reserved-light);
        }
        &.ti {
          @include backgroundColor($theme-TI, $theme-TI-light);
        }
        &.tb {
          @include backgroundColor($theme-TB, $theme-TB-light);
        }
        &.pipe {
          @include backgroundColor($theme-pipe, $theme-pipe-light);
        }
        &.obstruct {
          @include backgroundColor($theme-obstruct, $theme-obstruct-light);
        }
        &.tree {
          @include backgroundColor($theme-tree, $theme-tree-light);
        }
        &.admin.hold {
          @include backgroundColor($theme-admin-hold, $theme-admin-hold-light);
        }

        .propert-title-left {
          display: flex;
          flex-direction: column;
          padding-right: 20px;
          max-width: 260px;
          span {
            @include font($lato, 400, 13px, $theme-secondary-text);
            line-height: 22px;
            margin-bottom: 3px;
          }
          > a {
            @include font($lato, 700, 16px, $theme-secondary-text);
            line-height: 19px;
            text-decoration: underline;
          }
          @media (max-width: 767px) {
            padding: 0 0 15px;
            max-width: 100%;
          }
        }
        .propert-title-right {
          display: flex;
          .property-title-right-left-part {
            display: flex;
          }
          &.propert-title-right-with-agreements-button {
            @media (max-width: 500px) {
              flex-direction: column;
              .property-title-right-left-part {
                margin-bottom: 15px;
                .view-document-wrapper:last-child span {
                  display: none;
                }
              }
            }
          }
        }
        .link-documents {
          font-size: 13px;
          color: $theme-text;
          border-color: $theme-text;
          padding-bottom: 0;
          &:hover,
          &:focus,
          &:active {
            color: $theme-text !important;
          }
        }
      }
      .view-document-wrapper {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400 !important;
        color: $theme-text;
        text-decoration: underline;
        &.reserve {
          padding-right: 28px;
          position: relative;
          &::after {
            position: absolute;
            font-size: 13px;
            line-height: 16px;
            background: $theme-text;
            content: '';
            display: block;
            width: 1px;
            height: 16px;
            right: 14px;
            top: 0;
          }
        }

        .pipe {
          margin: 0 12px 0 12px;
        }
      }
    }
    .container-table-property-search {
      max-height: calc(90vh - 89px - 54px);
      overflow-y: auto;
      overflow-x: hidden;
      @media (max-width: 767px) {
        max-height: calc(87vh - 220px);
        overflow: auto;
      }
      &.reservation-flow-wrapper {
        width: 100%;
        padding: 20px 15px;
        label {
          line-height: 17px;
          font-size: 16px;
          color: $theme-tertiary-text;
        }
        .radio {
          padding-left: 20px;
          padding-bottom: 20px;
          label {
            font-size: 16px;
            line-height: 19px;
            color: $theme-tertiary-text;
            &.active {
              color: $theme-text;
            }
          }
        }
        td {
          .radio {
            padding-left: unset;
            padding-bottom: unset;
            &:nth-of-type(2) {
              padding-left: unset;
            }
            &:last-of-type {
              margin-left: unset !important;
            }
            label {
              color: unset;
            }
          }
        }
        .button {
          max-width: 210px;
          height: 49px;
          font-weight: 700 !important;
          font-size: 16px;
          line-height: 19px;
          margin-top: 0;
          &.reserve-btn {
            margin-top: 0;
          }
        }
        .contract-details-wrapper {
          padding: 12px 36px 9px;
          margin: 16px -36px;
          background: $theme-light-gray;
          font-style: normal;
          line-height: 17px;
          letter-spacing: 1px;
          text-transform: uppercase;
          @include font($lato, 700, 14px, $theme-tertiary-text);
          p {
            margin-bottom: 0;
          }
          @media (max-width: 767px) {
            margin: 15px 0;
            padding: 12px 15px;
          }
        }
        .submitted-message {
          // .item-card-value {
          color: $error-msg;
          font-size: 15px;
          // }
        }

        // .table-container.table-container-map-results {
        //   .table {
        //     thead tr th {
        //       font-size: 11px;
        //       letter-spacing: 0.4px;
        //       height: 38px;
        //     }
        //     tbody tr td {
        //       font-size: 13px;
        //       line-height: 16px;
        //     }
        //     thead tr th,
        //     tbody tr td {
        //       padding: 5px 10px;
        //       &:nth-of-type(1) {
        //         width: 18%;
        //         padding-left: 20px;
        //       }
        //       &:nth-of-type(2) {
        //         width: 25%;
        //       }
        //       &:nth-of-type(3) {
        //         width: 26%;
        //         word-break: break-word;
        //       }
        //       &:nth-of-type(4) {
        //         width: 26%;
        //       }
        //     }

        //     tbody tr td {
        //       &:nth-of-type(3) {
        //         div {
        //           display: -webkit-box;
        //           -webkit-line-clamp: 3;
        //           -webkit-box-orient: vertical;
        //           overflow: hidden;
        //           text-overflow: ellipsis;
        //         }
        //       }
        //     }
        //   }
        // }

        .contract-details-value-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-right: 0;
          .section-linked-properties {
            margin-top: 20px;
          }
          .container-cards {
            display: flex;
            flex-wrap: wrap;
            @media (max-width: 767px) {
              width: 100% !important;
            }
          }
          .item-card {
            width: 50%;
            padding-bottom: 24px;
            @media (max-width: 767px) {
              width: 100%;
            }
          }
          .select-wrapper {
            display: flex;
            flex-direction: column;
            font-weight: 400;
            font-size: 16px;
            line-height: 25px;
            color: #979797;
            width: 50%;
            margin-bottom: 0;
            padding-bottom: 24px;
            max-width: 125px;
          }
          .opi-id-table-wrapper {
            .item-card {
              width: 50%;
              padding-bottom: 11px;
            }
            .table {
              margin: 0;
              width: 100%;
              display: table;
              thead {
                tr {
                  max-width: 100%;
                }
              }
              tbody td.column-status {
                display: flex;
              }
            }
          }
          // options-wrapper
          .options-wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            .select-wrapper {
              display: flex;
              flex-direction: column;
              font-weight: 400;
              font-size: 16px;
              line-height: 25px;
              color: #979797;
              width: 50%;
              margin-bottom: 0;
              padding-bottom: 24px;
              max-width: 100px;
            }
          }
          .cards-wrapper {
            width: 100%;
            display: flex;
            @media (max-width: 767px) {
              flex-wrap: wrap;
            }
          }
          .reserve-card {
            width: 25% !important;
            @media (max-width: 767px) {
              width: 50% !important;
            }
          }
          .small-card {
            width: 75% !important;
            display: flex;
          }

          // options-wrapper
          .reserve-property-btn {
            width: 100%;
          }
        }
        .search-opi-id {
          max-width: 560px;
          .button {
            min-width: 100px;
            height: 40px;
            font-weight: 700 !important;
            font-size: 16px;
            line-height: 19px;
            margin-top: 25px;
          }
          @media (max-width: 767px) {
            max-width: 100%;
            flex-direction: column;
            align-items: flex-start !important;
          }
        }
      }
      &.non-reserved {
        padding-top: 0;

        .contract-details-wrapper {
          margin-top: 0;
        }
      }
    }
    .container-property {
      padding: 10px 10px 15px;
      .section-property-details {
        display: flex;
        flex-wrap: wrap;
        padding: 0 9px;
        @media (max-width: 767px) {
          padding: 0;
        }
        .item-card {
          width: 23%;
          padding-right: 15px;
          margin-bottom: 15px;
          .item-card-name {
            font-size: 14px;
          }
          .item-card-value {
            font-size: 13px;
            line-height: 19px;
            word-break: break-word;
            > a {
              color: $theme-text;
              text-decoration: underline;
            }
            a {
              line-height: 12px !important;
            }
          }
          &:nth-of-type(3) {
            width: 31%;
          }
          &:nth-last-of-type(1) {
            width: 100%;
            margin-bottom: 0;
          }
          @media (max-width: 767px) {
            width: 50% !important;
          }
        }
        &.section-property-details-with-owners {
          .item-card.small-width {
            &:nth-last-of-type(2) {
              width: 30%;
            }
            &:nth-last-of-type(1) {
              width: 20%;
            }
          }
          .item-card {
            &:nth-last-of-type(2) {
              width: 46%;
            }
            &:nth-last-of-type(1) {
              width: 54%;
            }
            &.item-card-status {
              padding-right: 0;
            }
            @media (max-width: 767px) {
              width: 50% !important;
            }
          }
        }
        .view-other-owners-dropdown,
        .view-other-properties-dropdown {
          > .btn {
            font-size: 13px;
          }
          .dropdown-menu {
            transform: none !important;
            top: 30px !important;
            a {
              font-size: 13px;
            }
            &:before {
              right: auto;
              left: 35px;
            }
            &[x-placement='top-end']::before {
              bottom: auto;
              transform: rotate(135deg);
              top: 0;
            }
          }
        }
      }
    }

    .table-container.table-container-map-results {
      .table {
        @media (max-width: 767px) {
          display: block !important;
          overflow-x: auto;
        }
        thead tr th {
          font-size: 11px;
          letter-spacing: 0.4px;
          height: 38px;
          word-break: break-word;
        }
        tbody tr td {
          font-size: 13px;
          line-height: 16px;
        }
        thead tr th,
        tbody tr td {
          padding: 5px 10px;
          &:nth-of-type(1) {
            width: 18%;
            padding-left: 20px;
          }
          &:nth-of-type(2) {
            width: 23%;
          }
          &:nth-of-type(3) {
            width: 23%;
            word-break: break-word;
          }
          &:nth-of-type(4) {
            width: 18%;
          }
          &:nth-of-type(5) {
            width: 18%;
          }
          @media (max-width: 767px) {
            width: 180px;
            min-width: 180px;
            &:nth-of-type(3) {
              width: 250px;
              min-width: 250px;
            }
          }
        }

        tbody tr td {
          &:nth-of-type(3) {
            div {
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
      &.opi-id-table-wrapper {
        // overflow-x: auto;
        // max-height: 154px;
        .table {
          thead tr th,
          tbody tr td {
            padding: 5px 5px 5px 10px;
            word-break: break-word;
            &:nth-of-type(1) {
              width: 180px;
              // padding-left: 0;
            }
            &:nth-of-type(2) {
              width: 145px;
            }
            &:nth-of-type(3) {
              width: 95px;
            }
            &:nth-of-type(4) {
              width: 98px;
            }
            &:nth-of-type(5) {
              width: 110px;
            }
            .radio {
              label {
                font-size: 13px;
                line-height: 17px;
              }
              input + span {
                width: 16px;
                height: 16px;
              }
            }
          }

          tr.selected {
            td,
            label {
              font-weight: 700;
            }
          }

          tbody tr td {
            &.selected {
              font-weight: bold;
              .input-radio {
                label.active {
                  font-weight: bold;
                }
              }
            }
          }
        }
      }

      .non-reserved-table {
        margin-bottom: 16px !important;
      }
    }

    .success-container {
      padding: 119px 163px;
      text-align: center;
      img {
        width: 60px;
        height: 60px;
        margin-bottom: 21px;
      }

      p {
        line-height: 175%;
        max-width: 100%;
        text-align: center;
        margin-bottom: 21px;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        font-size: 20px;
        color: #212121;
      }
    }
  }
  .map-tooltip-wrapper {
    padding: 10px 20px;
  }
}
.map-search-container-wrapper {
  position: absolute;
  top: 16px;
  left: 62px;
  z-index: 99;
  @media (max-width: 767px) {
    left: 20px;
    top: 15px;
  }
  .back-btn {
    cursor: pointer;
    margin-right: 8px;
    margin-top: -5px;
  }
  .dropdown {
    display: inline-block;
    background: $white;
    border: 1px solid $theme-tertiary-text;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 6px 12px;
    font-family: $lato;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $black;
    min-width: 250px;
    position: relative;
    cursor: pointer;
    .dropdown-menu {
      top: 20px !important;
      box-shadow: none !important;
      background-color: transparent !important;
      transform: translate3d(0px, 25px, 0px) !important;
      &::before {
        left: 30px;
        border: 4px solid $dropdown-angle-black;
        border-color: $dropdown-angle-black;
      }
    }
    .dropdown-icon {
      margin-top: 2px;
      float: left;
      margin-right: 10px;
    }
    span {
      width: 100%;
      display: block;
      text-align: center;
    }
    &::after {
      position: absolute;
      content: '';
      width: 1px;
      height: 18px;
      background: url(../../../assets/images/drop-line.png);
      background-size: cover;
      top: 6px;
      left: 40px;
    }
    .dropdown-content-wrapper {
      line-height: 16px;
      @include font($lato, 400, 13px, $white);
      background: $dropdown-map-bg;
      opacity: 0.92;
      cursor: pointer;
      padding: 8px 12px;
      border-radius: 2px;
      border-bottom: 2px solid transparent;
      margin-bottom: 2px;
      position: relative;
      @media (max-width: 1023px) {
        opacity: 1;
      }
      .arrowicon-icon {
        position: absolute;
        top: 12px;
        right: 11px;
      }
      .last-submenu {
        height: 300px !important;
      }
      .hover-container-wrapper {
        position: absolute;
        top: 0;
        left: 261px;
        min-width: 220px;
        height: 574px;
        overflow-y: auto;
        display: block;
        @media (max-width: 767px) {
          left: 0;
          border: 1px solid $white;
          top: 100%;
          max-height: 180px;
          background: $dropdown-map-bg;
          z-index: 9;
          .dropdown-content-wrapper {
            position: relative;
            opacity: 1;
            border-bottom: 0.5px solid rgba($white, 0.5);
          }
        }
      }
      &.with-click-wrapper {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 17px;
          box-sizing: border-box;
          right: -14px;
          border: 4px solid #22231f;
          border-color: #22231f;
          transform-origin: 0 0;
          transform: rotate(135deg);
        }
      }
    }
  }
}
// .mousoleum-form {
//   max-width: 300px;
//   .search-opi-id {
//     display: block !important;
//   }
//   .contract-details-wrapper {
//     margin: 16px 0;
//     .item-card {
//       margin-bottom: 0;
//       padding-bottom: 11px;
//     }
//     .opi-id-table-wrapper {
//       overflow-x: auto;
//       max-height: 154px;
//       .reservation-flow-wrapper {
//         .radio {
//           padding-left: 0;
//           padding-bottom: 0;
//           margin-left: 0 !important;
//         }
//       }
//     }
//   }
// }

.section-linked-properties {
  width: 100%;
  .item-card {
    width: 100% !important;
    .item-card-value {
      display: flex;
      flex-wrap: wrap;
      .item-linked-properties {
        a {
          color: $theme-text !important;
          font-weight: 400 !important;
          font-size: 16px !important;
          line-height: 25px;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        span {
          padding: 0 10px 0 5px;
        }
        &:last-child span {
          display: none;
        }
      }
    }
  }
}

.mausoleum-wrapper
  .map-building-module
  .container-property
  .section-property-details
  .item-card
  .item-card-value
  .item-linked-properties
  a {
  line-height: 25px !important;
  text-decoration-line: none;
  &:hover {
    text-decoration: underline;
  }
}

.table-container.table-container-map-results.table-container-map-results-occupied {
  .table {
    thead tr th {
      font-size: 11px;
      letter-spacing: 0.4px;
      word-break: break-word;
    }
    tbody tr td {
      font-size: 13px !important;
      line-height: 16px;
    }
    thead tr th,
    tbody tr td {
      padding: 5px 5px 5px 10px !important;
      &:nth-of-type(1) {
        width: 24% !important;
      }
      &:nth-of-type(2) {
        width: 24% !important;
      }
      &:nth-of-type(3) {
        width: 17% !important;
      }
      &:nth-of-type(4) {
        width: 19% !important;
      }
      &:nth-of-type(5) {
        width: 16% !important;
      }
      @media (max-width: 767px) {
        width: 180px !important;
        min-width: 180px !important;
      }
    }
  }
}

.mausoleum-wrapper .map-building-module .container-property {
  .table-container.table-container-map-results.table-container-map-results-occupied {
    .non-reserved-table.table tbody tr td {
      font-size: 13px !important;
      line-height: 16px;
    }
  }
}

.contract-status {
  display: inline-flex;
  text-transform: uppercase;
  border-radius: 50vh;
  padding: 2px 10px;
  margin: 2px 0 0 20px;
  color: $theme-text;
  font-weight: 400;
  font-size: 10px;
  &.closed,
  &.submitted {
    background-color: rgba($error-msg, 0.5);
  }
  &.cancelled {
    background-color: rgba($error-msg, 0.7);
  }
  &.voided {
    background-color: rgba(255, 193, 7, 0.7);
  }
  &.in-progress {
    background-color: $theme-status-inprogess;
  }
  &.contact-status-non-opi {
    margin: 2px 0 0;
  }
}
.column-status {
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: center;
}

.reservation-button-wrapper {
  width: 100%;
}

.contract-details-wrapper-only-contract {
  display: flex;
  align-items: center;
  .contract-status {
    margin: 0 0 0 40px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    .contract-status {
      margin: 10px 0 0;
    }
  }
}

.select-wrapper-additional-rights label {
  line-height: 25px !important;
  margin-bottom: 8px;
}

.mausoleum-wrapper
  .map-building-module
  .container-property
  .section-property-details {
  .item-card.reserve-card-burials * {
    line-height: 25px !important;
  }
  .select-wrapper-additional-rights label {
    margin-bottom: 12px;
  }
}

.map-search-results-module {
  $shared-width: 18.75%; // calc(75%/4)

  .cards-wrapper.cards-wrapper-occupied {
    .item-card:not(:first-child) {
      width: $shared-width !important;
      @media (max-width: 767px) {
        width: 50% !important;
      }
    }
  }

  .cards-wrapper.cards-wrapper-occupied-secondary {
    .select-wrapper-additional-rights {
      width: 25% !important;
      max-width: unset !important;
      > div {
        max-width: 125px;
      }
      @media (max-width: 767px) {
        width: 50% !important;
      }
    }
    .item-card {
      width: $shared-width !important;
      * {
        line-height: 25px !important;
      }
      @media (max-width: 767px) {
        width: 50% !important;
        * {
          line-height: 19px !important;
        }
      }
    }
    .item-card.card-rights-price {
      width: 37.5% !important;
      @media (max-width: 767px) {
        width: 50% !important;
      }
    }
  }
}

.options-wrapper-contract,
.wrapper-reserve-price {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.wrapper-reserve-price {
  .select-wrapper-additional-rights {
    width: 25% !important;
    max-width: unset !important;
    > div {
      max-width: 125px;
    }
  }
  .item-card.card-rights-price {
    width: 37.5% !important;
  }
}

.table-container-map-results.table-container-map-results-opi.table-with-scroll {
  .table td {
    min-height: 55px;
    height: unset;
  }
}

.reservation-property-wrapper {
  @include flex(space-between, center);
  margin-bottom: 20px;
  > div {
    display: flex;
    align-items: center;
    label {
      margin-bottom: 0;
    }
    .input-radio.radio {
      padding-bottom: 0 !important;
      &.input-radio-checked label {
        font-weight: 700;
      }
    }
  }
  .button {
    margin: 0 !important;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    > div {
      margin-bottom: 15px;
    }
  }
  @media (max-width: 500px) {
    > div {
      flex-direction: column;
      align-items: flex-start;
      label {
        margin-bottom: 10px;
      }
      .radio {
        padding-left: 0 !important;
      }
    }
  }
}

.no-results-container.no-results-container-for-contracts {
  p {
    margin-bottom: 20px !important;
  }
  .button {
    @include flex-d(center, center, column);
    height: unset !important;
    span.icon {
      @include flex(center, center);
      @include circle(50px);
      border: 5px solid $theme-text;
      margin-bottom: 15px;
      .fa-plus {
        font-size: 25px;
        color: $theme-text;
      }
    }
  }
}
