.modal {
  .modal-dialog {
    &.fullscreen-wrapper {
      width: auto;
      max-width: 100%;
      .modal-content {
        background-color: transparent !important;
        .modal-close {
          @media (max-width: 767px) {
            right: 20px !important;
          }
        }
        .modal-body {
          padding: 0;
          text-align: center;
          img {
            max-height: 80vh;
            max-width: calc(100% - 20px);
          }
        }

        .modal-header {
          padding: 20px;
          > h5 {
            position: absolute;
          }
          .modal-title {
            i.modal-close {
              padding: 6px 8px;
              border: 2px solid white;
              background: white;
              border-radius: 50%;
              position: absolute;
              right: 0px;
            }
            i.fa-times {
              color: $theme-tertiary-text;
            }
          }
        }

        .modal-header,
        .modal-footer {
          background-color: transparent !important;
          @include box-shadow(none !important);
        }
      }
    }
  }
}
