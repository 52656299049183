.position_button {
  position: absolute;
  top: 48px;
  right: 0;
  @media (max-width: 400px) {
    top: 95px;
    left: 0;
    right: auto;
  }
}

.deceased-wrapper.search-wrapper.burial-reports-wrapper {
  .form-elements-wrapper .row > div {
    @media (max-width: 767px) {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
  @media (max-width: 400px) {
    .page-section .page-header {
      margin-bottom: 80px;
    }
  }
  .report-search-results {
    @media (max-width: 1023px) {
      display: block;
      overflow-x: auto;
      thead tr th,
      tbody tr td {
        min-width: 200px;
        width: 200px;
        &:nth-of-type(4) {
          min-width: 80px;
          width: 80px;
        }
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-last-of-type(1) {
          width: 150px;
          min-width: 150px;
        }
        &:nth-last-of-type(3) {
          width: 300px;
          min-width: 300px;
        }
      }
    }
  }
}
