.modal-dialog {
  &.alertMessage-wrapper {
    width: 100%;
    max-width: 550px;
    .modal-header,
    .modal-footer {
      background-color: transparent !important;
      @include box-shadow(none !important);
    }
  }
}
