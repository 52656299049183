.button.btn {
  @include appearance(none);
  @extend .lato-bold;

  border: 0;
  outline: 0;
  font-size: 16px;
  letter-spacing: 0.2px;
  min-width: 150px;
  border-radius: 4px;
  @media (max-width: 767px) {
    font-size: 14px;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      opacity: 0.5;
    }
  }

  &:hover {
    opacity: 0.7;
    box-shadow: none !important;
  }

  &:focus,
  &:active {
    border: 0;
    box-shadow: none !important;
  }

  &.lg {
    padding: 16px;
  }

  &.md {
    padding: 10px;
    min-width: 125px;
  }

  &.sm {
    padding: 6px;
  }

  &.xs {
    padding: 2px;
  }

  &.link {
    padding: 0;
    min-width: 0;
    text-transform: capitalize !important;
    padding-bottom: 3px;
    border-radius: 0;
    border-bottom: 1px solid;
    text-decoration: none;
  }

  &.text {
    padding: 0;
    min-width: 0;
    border-radius: 0;
  }

  &.btn-white-text {
    color: $white !important;
    &:hover,
    &:focus,
    &:active {
      color: $white !important;
    }
  }

  &.primary {
    background-color: $brand-primary;
    &:hover,
    &:focus,
    &:active {
      background-color: $brand-primary !important;
      color: $white;
    }

    &.outline {
      background-color: $white;
      border: 1px solid $brand-primary;
      color: $brand-primary;
      &:hover,
      &:focus,
      &:active {
        background-color: $white !important;
        color: $brand-primary;
      }
    }

    &.text,
    &.link {
      color: $brand-primary;
      background-color: transparent;
      &:hover,
      &:focus,
      &:active {
        color: $brand-primary;
        background-color: transparent !important;
      }
    }

    &.link {
      border-bottom-color: $brand-primary;
    }
  }

  &.secondary {
    background-color: $brand-secondary;
    &:hover,
    &:focus,
    &:active {
      background-color: $brand-secondary !important;
      color: $white;
    }

    &.outline {
      background-color: $white;
      border: 1px solid $brand-secondary !important;
      color: $brand-secondary;
      &:hover,
      &:focus,
      &:active {
        background-color: $white !important;
        color: $brand-secondary;
      }
    }

    &.text,
    &.link {
      color: $brand-secondary;
      background-color: transparent;
      &:hover,
      &:focus,
      &:active {
        color: $brand-secondary;
        background-color: transparent !important;
      }
    }

    &.link {
      border-bottom-color: $brand-secondary;
    }
  }

  &.tertiary {
    background-color: $brand-tertiary;
    &:hover,
    &:active,
    &:focus {
      background-color: $brand-tertiary !important;
      color: $white;
    }
    &.outline {
      background-color: $white;
      border: 1px solid $brand-tertiary;
      color: $brand-tertiary;
      &:hover,
      &:active,
      &:focus {
        background-color: $white !important;
        color: $brand-tertiary;
      }
    }

    &.text,
    &.link {
      color: $brand-tertiary;
      background-color: transparent;
      &:hover,
      &:active,
      &:focus {
        color: $brand-tertiary;
        background-color: transparent !important;
      }
    }

    &.link {
      border-bottom-color: $brand-tertiary;
    }
  }

  &.default {
    background-color: $brand-default;
    color: $theme-tertiary-text;
    &:hover,
    &:active,
    &:focus {
      background-color: $brand-default !important;
      color: $theme-tertiary-text !important;
    }

    &.outline {
      background-color: $white;
      border: 1px solid $theme-tertiary-text;
      color: $theme-tertiary-text;
      &:hover,
      &:active,
      &:focus {
        background-color: $white !important;
        color: $theme-tertiary-text;
      }
    }

    &.text,
    &.link {
      color: $theme-tertiary-text;
      background-color: transparent;
      &:hover,
      &:active,
      &:focus {
        background-color: transparent !important;
        color: $theme-tertiary-text;
      }
    }

    &.link {
      border-bottom-color: $theme-tertiary-text;
    }
  }
  .cancel-btn {
    height: 12px;
    width: 12px;
    margin-left: 12px;
  }
}
