.user-role-options-wrapper {
  .edit-btn {
    color: #212121 !important;
    font-weight: 400 !important;
  }
  .added-record {
    color: #2e7d32 !important;
  }
  .deleted-record {
    color: #f57c00 !important;
  }
  .more-text {
    position: absolute;
    top: -35px;
    right: 20px;
    &.dropdown > .btn {
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      font-size: 13px;
      color: #979797 !important;
      letter-spacing: 0.4px;
      line-height: 16px;
      text-transform: uppercase;
      width: 65px;
      .fas {
        font-size: 14px;
        line-height: normal;
        padding-left: 6px;
        margin-top: 1px;
        color: #a2a1a1 !important;
      }
    }
    @media (max-width: 767px) {
      top: 92px;
      right: 0;
    }
  }
  &.dropdown {
    &.show {
      > .btn {
        color: #212121 !important;
        font-weight: 700;
        .fas {
          color: #212121 !important;
          font-weight: 700;
        }
      }
    }
    .dropdown-menu {
      padding: 10px 0;
      min-width: 304px;
      &::before {
        top: 10px;
        right: 30px;
        border-color: #f3f3f3;
      }
      .dropdown-header {
        &.dropdown-item:disabled {
          background: #f3f3f3;
          font-family: 'Lato';
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          letter-spacing: 0.4px;
          text-transform: uppercase;
          color: #979797;
        }
      }
    }
  }
  .dropdown.dropdown-search-filter {
    .dropdown-menu {
      height: auto;
      left: -45px !important;
    }
  }
  .table-container-search-results-user {
    table tbody tr.active-record {
      .select-wrapper {
        margin-bottom: 0;
      }
      td {
        font-weight: 700;
        padding: 0;
        &:nth-of-type(2) {
          padding: 5px 15px;
        }
        .select-dropdown__option {
          font-weight: 400 !important;
        }
      }
    }
  }
  .more-icon {
    height: 3.04347825050354px;
    width: 14px;
    top: 7px;
    border-radius: 0px;
    position: absolute;
    right: 5.97%;
    bottom: 88.37%;
  }
}

.modal-add-new-role {
  &.no-scroll-wrapper {
    max-width: 580px !important;
    .modal-content,
    .modal-body {
      overflow: hidden;
      .login {
        height: unset;
        width: 80%;
      }
    }
    .modal-content {
      height: 90% !important;
      width: 100% !important;
    }
  }
  .modal-content {
    height: 83% !important;
  }
  @media (max-width: 767px) {
    .buttons-container {
      flex-direction: column;
      span {
        max-width: 100% !important;
        margin: 0 0 25px;
      }
    }
    &.modal-login {
      .modal-body .login {
        width: 100%;
        padding: 10px;
      }
    }
  }
}
.login .content .form-group input {
  &[disabled] {
    color: #616161 !important;
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    opacity: 0.7 !important;
  }
}

.deceased-wrapper.platted-wrapper.manage-user-roles-wrapper {
  .page-section .page-header {
    @media (max-width: 767px) {
      margin-bottom: 60px;
    }
  }
}
