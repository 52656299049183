.info-panel {
  .panel-body {
    padding: 0;
  }

  .panel-header {
    padding: 10px 25px !important;

    h2 {
      @extend .lato-bold;
      color: $brand-primary;
      font-size: 20px;
      line-height: 24px;
      margin: 0;
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  .info-panel-section {
    padding: 20px 25px 8px;
    box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;

    ul {
      @include flex(flex-start, flex-start);
      list-style: none;
      padding: 0;
      margin: 0;
      flex-wrap: wrap;

      li {
        @include flex(flex-start, flex-start);
        width: 50%;
        font-size: 16px;
        line-height: 19px;
        color: $theme-tertiary-text;
        margin-bottom: 15px;
        strong {
          font-weight: 400 !important;
          &::first-letter {
            text-transform: capitalize;
          }
        }
        span {
          color: $theme-text;
          word-break: break-word;
          padding-left: 3px;
          &::first-letter {
            text-transform: capitalize;
          }

          &:empty {
            position: relative;
            height: 19px;
            &::before {
              position: absolute;
              content: '';
              width: 15px;
              height: 2px;
              background: $theme-text;
              left: 5px;
              top: 11px;
            }
          }
        }
        &:nth-child(odd) {
          padding-right: 5%;
        }
      }
    }
  }
}
