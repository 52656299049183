.deceased-wrapper {
  .search-results-wrapper {
    .table-container-search-results-owners .table {
      thead tr th,
      tbody tr td {
        &:nth-of-type(1) {
          width: calc(50% - 270px);
          @media (max-width: 1023px) {
            width: calc(55% - 220px);
          }
        }
        &:nth-of-type(2) {
          width: 50%;
          @media (max-width: 1023px) {
            width: 45%;
          }
        }
        &:nth-of-type(3) {
          width: 200px;
          border-right: 0;
          @media (max-width: 1023px) {
            width: 150px;
          }
        }
        &:nth-of-type(4) {
          width: 70px;
        }
      }
      @media (max-width: 767px) {
        display: block;
        overflow-x: auto;
        thead tr th,
        tbody tr td {
          &:nth-of-type(1) {
            width: 250px;
            min-width: 250px;
          }
          &:nth-of-type(2) {
            width: 350px;
            min-width: 350px;
          }
          &:nth-of-type(3) {
            width: 200px;
            min-width: 200px;
          }
          &:nth-of-type(4) {
            min-width: 70px;
          }
        }
      }
    }
  }

  .search-results-wrapper {
    .property-message {
      @include font($lato, 700, 13px, $theme-tertiary-text);
      line-height: 16px;
      letter-spacing: 0.4px;
      margin: 25px 0 0;
    }
  }
}
