.status-box {
  padding: 12px 25px;
  font-size: 12px;
  line-height: 15px;
  p {
    margin-bottom: 0;
  }

  i {
    margin-right: 10px;
    font-size: 16px;
  }
}

.status-box.with-errors {
  background-color: rgba(255, 195, 10, 0.1);
  color: $status-msg-color;
}

.status-box.without-errors {
  background-color: rgba(96, 207, 128, 0.1);
  color: $brand-primary;
}
