.landing-section {
  .landing-content-wrapper {
    .home-banner-wrapper {
      max-width: 1024px;
      // height: calc(100vh - 70px);
      padding: 32px;
      // overflow: auto;
      margin: 70px auto 30px;
      @media (max-width: 1023px) {
        padding: 32px 10px;
      }
      @media (max-width: 767px) {
        // height: calc(100vh - 100px);
        margin: 100px auto 0;
        padding: 20px 10px;
      }
      .home-banner-text {
        height: 100%;
        .landing-list-wrapper {
          .landing-list-heading {
            color: $theme-text;
            margin: 0 auto 32px;
            font-size: 24px;
            line-height: 29px;
            @extend .lato-bold;
            @media (max-width: 767px) {
              font-size: 20px;
              margin-bottom: 20px;
            }
          }
          .landing-lists-group {
            margin: 0;
            padding: 0 0 32px 0;
            display: grid;
            grid-gap: 32px;
            grid-auto-rows: auto;
            list-style-type: none;
            grid-template-columns: repeat(auto-fit, minmax(30.33%, 1fr));
            .landing-list {
              text-align: center;
              &[aria-disabled='true'] {
                cursor: not-allowed;
                .landing-list-item {
                  background-color: $brand-default;
                  pointer-events: none;
                }
              }
              .landing-list-item {
                position: relative;
                min-height: 196px;
                background-color: $white;
                padding-bottom: 15px;
                @include border-radius(12px);
                @include box-shadow(0 2px 14px 0 rgba(204, 204, 204, 0.5));
                &:hover {
                  text-decoration: none;
                  > i {
                    opacity: 0.6;
                  }
                }
                > i {
                  color: $brand-primary;
                  font-size: 20px;
                  margin-top: 24px;
                  background-color: rgba(234, 242, 235, 0.8);
                  @include circle(40px);
                  @include transition(all 0.3s ease);
                  @extend .d-flex;
                  @extend .align-items-center;
                  @extend .justify-content-center;
                  &.fa-phone {
                    @include transform(rotate(90deg));
                  }
                }
                > .landing-list-item-badge {
                  position: absolute;
                  top: 65px;
                  right: 25px;
                  font-size: 13px;
                }
                > .landing-list-item-name {
                  color: $theme-text;
                  font-size: 20px;
                  margin: 21px 10px 0;
                  line-height: 24px;
                  @extend .lato-bold;
                }
                > .landing-list-item-desc {
                  color: $theme-secondary-text;
                  margin: 0 13px;
                  font-size: 16px;
                  margin-top: 16px;
                  line-height: 21px;
                  @extend .text-center;
                  @extend .lato-regular;
                }
              }
              &.disabled {
                pointer-events: none;
                opacity: 0.5;
              }
            }
            @media (max-width: 1023px) {
              display: flex;
              flex-wrap: wrap;
              grid-gap: 20px;
              .landing-list {
                width: calc((100% - 40px) / 3);
                min-height: 210px;
                .landing-list-item {
                  height: 100%;
                  min-height: unset;
                }
              }
            }
            @media (max-width: 767px) {
              grid-gap: 40px;
              .landing-list {
                width: calc((100% - 40px) / 2);
                min-height: 180px;
                .landing-list-item {
                  @include flex(center, center);
                  padding: 15px 0;
                  > i {
                    margin-top: 0;
                  }
                  > .landing-list-item-badge {
                    top: 50px;
                  }
                  > .landing-list-item-name {
                    margin-top: 15px;
                    font-size: 18px;
                  }
                  > .landing-list-item-desc {
                    margin-top: 5px;
                  }
                }
              }
            }
            @media (max-width: 600px) {
              grid-gap: 0px;
              justify-content: center;
              .landing-list {
                width: 100%;
                min-height: 140px;
                max-width: 300px;
                &:not(:last-child) {
                  margin-bottom: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}
