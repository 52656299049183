.copyRightClass {
  font-family: 'Arial', sans-serif;
  font-size: 12px;
  color: #555;
  text-align: center;
  margin-top: auto;
  .copyRight {
    display: inline-block;
    margin-bottom: 5px;
  }
}
