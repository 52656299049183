.platted-record-details-wrapper {
  .breadcrumb-truncated-label {
    display: block;
    width: 550px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media (max-width: 767px) {
      width: auto;
    }
  }

  .platted-record-details-container {
    .no-results-container {
      @include flex-d(center, center, column);
      text-align: center;
      padding: 3rem 0;
      background: $white;
      width: 100%;
      .icon-holder {
        @include flex(center, center);
        @include circle(40px);
        background: rgba(#eaf2eb, 0.8);
        i {
          color: $brand-primary;
          font-size: 20px;
        }
      }
      p {
        @include font($lato, 400, 18px, $theme-tertiary-text);
        line-height: 27px;
        margin: 12px 0 0;
      }
    }
    .button.btn.md {
      padding: 8px 15px;
    }
    .section-record-header {
      @include flex(space-between, center);
      background: $theme-table-nth;
      padding: 25px;
      border-bottom: 1px solid rgba($theme-tertiary-text, 0.06);
      margin-bottom: 8px;
      .record-header-left {
        @include flex(flex-start, center);
        flex-grow: 1;
        padding-right: 30px;
        .section-name-disc {
          word-break: break-word;
          max-width: 260px;
          padding-right: 15px;
          strong {
            @include font($lato, 700, 18px, $theme-secondary-text);
            line-height: 22px;
            margin-bottom: 6px;
            display: block;
          }
          span {
            font-size: 16px;
            color: $theme-text;
            line-height: 19px;
          }
        }
        .item-card {
          border-left: 1px solid rgba($theme-tertiary-text, 0.2);
          padding-left: 30px;
          .item-card-name {
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            text-transform: uppercase;
            letter-spacing: 0.4px;
            margin-bottom: 7px;
          }
        }
      }
      .record-header-right {
        @include flex(flex-start, center);
        .btn-garden {
          min-width: 225px;
          margin-right: 25px;
        }
        .dropdown-with-more {
          .dropdown-menu {
            left: 12px !important;
          }

          & > .btn,
          .fas {
            &.fa-ellipsis-h {
              color: $theme-tertiary-text;
            }
          }
        }
      }
      @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 15px;
        .record-header-left {
          padding: 0 0 20px;
          width: 100%;
          .section-name-disc {
            max-width: calc(100% - 160px);
          }
        }
        .record-header-right {
          width: 100%;
          justify-content: space-between;
          .btn-garden {
            min-width: 200px;
          }
        }
      }
      @media (max-width: 500px) {
        .record-header-left {
          flex-direction: column;
          align-items: flex-start;
          .section-name-disc {
            max-width: 100%;
            padding: 0 0 7px;
            border-bottom: 1px solid rgba($theme-tertiary-text, 0.2);
            strong {
              font-size: 16px;
              margin-bottom: 0;
            }
            span {
              font-size: 14px;
            }
          }
          .item-card {
            padding: 7px 0 0;
            line-height: 20px;
            border-left: 0;
            .item-card-name {
              margin-bottom: 0;
            }
            .item-card-value {
              font-size: 14px;
            }
          }
        }
      }
      .record-header-right {
        .btn-garden {
          font-size: 13px;
          min-width: 180px;
          margin-right: 15px;
        }
      }
    }
    .record-details-body {
      display: flex;
      align-items: flex-start;
      .no-results-container {
        p {
          margin-bottom: 12px;
        }
        .button.btn.secondary.link {
          font-weight: 400 !important;
          text-transform: none !important;
          padding-bottom: 0;
        }
      }
      .sidebar-gareden-search {
        width: 365px;
        margin-right: 12px;
        box-shadow: 0px 2px 4px rgba(231, 231, 231, 0.5);
        border-radius: 4px;
        background: $white;
        @media (max-width: 1023px) {
          width: 300px;
        }
        .section-header {
          background: $theme-table-nth;
          padding: 10px 15px;
          border-bottom: 1px solid rgba($theme-tertiary-text, 0.06);
          min-height: 65px;
          position: relative;
          border-radius: 4px 4px 0 0;
          h3 {
            @include font($lato, 700, 18px, $theme-secondary-text);
            line-height: 22px;
            margin: 0 0 4px;
          }
          .pagination-section {
            padding-right: 0;
            .pagination-label,
            .per-page {
              display: none;
            }
            .records-page {
              padding: 0;
              font-size: 14px;
              color: $theme-tertiary-text;
              line-height: 17px;
              span {
                font-weight: 400;
                color: $theme-tertiary-text;
              }
            }
            nav.nav-pagination {
              position: absolute;
              right: 20px;
              top: calc(50% - 15px);
              ul .page-item .page-link {
                padding: 0 12px;
              }
            }
            @media (max-width: 1023px) {
              > div:last-child {
                max-width: 200px;
              }
            }
          }
        }
        .input-field.with-icon.search-icon {
          padding: 10px 0;
          border-bottom: 1px solid rgba($theme-tertiary-text, 0.2);
          .input-fields {
            border: none;
            @media (max-width: 1023px) {
              font-size: 14px;
            }
          }
          @media (max-width: 767px) {
            padding: 0;
            &::after {
              top: 19px;
            }
          }
        }
        .results-count {
          @include font($lato, 600, 14px, $brand-primary);
          line-height: 17px;
          padding: 12px 15px;
          display: block;
          border-bottom: 1px solid rgba($theme-tertiary-text, 0.2);
        }
        .list-results {
          max-height: calc(
            100vh - 70px - 60px - 41px - 37px - 100px - 8px - 65px - 75px
          );
          overflow-x: hidden;
          overflow-y: auto;
          &.list-results-with-count {
            max-height: calc(
              100vh - 70px - 60px - 41px - 37px - 100px - 8px - 65px - 75px -
                41px
            );
          }
          @media (max-width: 767px) {
            max-height: none;
            &.list-results-with-count {
              max-height: none;
              overflow: unset;
              word-break: break-all;
            }
          }
          ul {
            margin: 0;
            padding: 0;
            li.garden-result {
              @include flex(space-between, center);
              min-height: 68px;
              padding: 10px 15px;
              cursor: pointer;
              &:not(:last-child) {
                border-bottom: 1px solid rgba($theme-tertiary-text, 0.2);
              }
              @media (max-width: 767px) {
                padding: 6px 10px;
                position: relative;
              }
              .garden-result-left {
                .garden-name {
                  @include font($lato, 500, 16px, $theme-secondary-text);
                  line-height: 19px;
                  margin-bottom: 5px;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: calc(365px - 15px - 15px - 45px);
                  @media (max-width: 1023px) {
                    width: calc(300px - 15px - 15px - 45px);
                  }
                  @media (max-width: 767px) {
                    width: calc(100% - 60px);
                  }
                }
                .ref-no {
                  @include font($lato, 700, 12px, $theme-tertiary-text);
                  line-height: 14px;
                  letter-spacing: 0.4px;
                  text-transform: uppercase;
                }
              }
              .dropdown-garden {
                position: static;
                > .btn .fas {
                  font-size: 14px;
                }
                .dropdown-menu {
                  left: 190px !important;
                  &[x-placement='top-end'] {
                    left: 170px !important;
                    &[index='top'] {
                      transform: translate3d(100px, 45px, 0px) !important;
                    }
                  }
                  @media (max-width: 1023px) {
                    &[x-placement='top-end'] {
                      left: 105px !important;
                    }
                  }
                  @media (max-width: 767px) {
                    left: auto !important;
                    right: 0;
                    top: 50px !important;
                    transform: none !important;
                    &[x-placement='top-end'] {
                      left: auto !important;
                      transform: none !important;
                      &[index='top'] {
                        transform: none !important;
                      }
                    }
                    &::before {
                      left: auto !important;
                      right: 10px !important;
                    }
                  }
                  .dropdown-item.disabled {
                    cursor: not-allowed;
                    color: $theme-tertiary-text !important;
                    pointer-events: unset;
                    background-color: rgba($theme-tertiary-text, 0.06);
                    i {
                      color: $theme-tertiary-text !important;
                    }
                  }
                  &:before {
                    right: auto;
                    left: 47px;
                    border-color: transparent transparent $white $white;
                    box-shadow: 0px 10px 15px rgba(199, 199, 199, 0.5);
                  }
                }
              }
              &:hover,
              &:active,
              &.active {
                background: $theme-light-gray;
                .garden-result-left {
                  .garden-name {
                    color: $theme-text;
                  }
                  .ref-no {
                    color: $theme-secondary-text;
                  }
                }
              }
            }
          }
        }
      }
      @media (max-width: 767px) {
        flex-direction: column;
        .loader-container {
          width: 100%;
        }
        .sidebar-gareden-search {
          margin: 0 auto 25px;
          width: 100%;
        }
      }
    }
  }
}

.modal-dialog.modalProperties.modal-add-edit-garden {
  .modal-content,
  .modal-body {
    max-height: 100vh;
    overflow: auto;
  }
  &.no-scroll-wrapper {
    .modal-content,
    .modal-body {
      overflow: hidden;
    }
  }
  @media (max-width: 767px) {
    padding: 0 10px;
    .modal-content,
    .modal-body {
      max-height: 90vh;
    }
    &.no-scroll-wrapper {
      .modal-content,
      .modal-body {
        overflow: auto;
      }
    }
  }
  .modal-body {
    min-width: 700px;
    .form-add-edit-garden {
      padding-bottom: 35px;
      .input-field {
        margin-bottom: 30px;
      }
      .container-textarea {
        position: relative;
        margin-bottom: 55px;
        .length-note {
          @include font($lato, 400, 14px, $theme-tertiary-text);
          font-style: italic;
          line-height: 17px;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
    .buttons-container {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 15px;
      @media (max-width: 767px) {
        padding: 15px 0 0;
      }
      .button.btn.md {
        padding: 8px 10px;
        min-width: 120px;
      }
      .role-message-wrapper {
        max-width: 312px;
        margin-right: 40px;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: $theme-tertiary-text;
      }
    }
    .permission-wrapper {
      font-family: 'Lato';
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      margin-bottom: 0;
      padding-bottom: 16px;
      color: $theme-tertiary-text;
    }
    @media (max-width: 767px) {
      min-width: 0;
      width: 100%;
      .form-add-edit-garden {
        padding-bottom: 0;
        .container-textarea {
          margin-bottom: 40px;
          .length-note {
            right: auto;
            top: calc(100% + 5px);
          }
        }
      }
    }
  }
  &.modal-deceased-wrapper {
    max-width: 100% !important;
    width: 100%;
    .modal-header {
      padding: 24px 45px;
      @media (max-width: 767px) {
        padding: 15px;
      }
    }
    .modal-body {
      width: 100%;
      position: relative;
      padding: 24px 48px;
      @media (max-width: 767px) {
        padding: 30px 15px;
      }
      .form-add-edit-garden {
        @media (max-width: 767px) {
          .row {
            max-width: 100%;
            flex-direction: column;
            margin: 0;
            > div {
              max-width: 100%;
              padding: 0;
            }
          }
        }
        .form-content-wrapper {
          display: flex;
          max-width: 592px;
          .input-field {
            margin-bottom: 56px;
            width: 100%;
            &:nth-of-type(2) {
              margin-left: 40px;
            }
          }
        }
      }
      .buttons-container {
        justify-content: flex-start;
        padding-bottom: 15px;
        padding-top: 20px;
        .button.btn.md {
          padding: 10px 24px;
          min-width: 111px;
          &.btn-reset {
            background-color: transparent !important;
          }
        }
        @media (max-width: 767px) {
          padding: 0;
        }
      }
      &::before {
        top: 140px;
        left: 0;
        width: 100%;
        height: 1px;
        content: '';
        background-image: url('../../../assets/images/form-before.png');
        position: absolute;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .deceased-wrapper {
        .search-results-wrapper {
          .header-search-results {
            h2 {
              text-transform: initial;
              color: $theme-secondary-text;
            }
          }
          .attachment-img {
            padding-right: 4px;
          }
          .table {
            .properties-checkbox {
              .checkbox-wrapper {
                display: flex;
                margin-bottom: 0;
                label.checkbox-inline {
                  display: flex;
                  flex-direction: row-reverse;
                  align-items: center;
                  input[type='checkbox'] + span {
                    margin-right: 10px !important;
                    @media (max-width: 767px) {
                      margin-left: 0 !important;
                    }
                  }
                }
              }
            }
            thead tr th,
            tbody tr td {
              text-align: left;
              &:nth-of-type(1) {
                width: 251px;
                text-align: left;
              }
              &:nth-of-type(2) {
                width: 181px;
                text-align: left;
              }
              &:nth-of-type(3) {
                width: 171px;
                text-align: left;
              }
              &:nth-of-type(4) {
                width: 190px;
                text-align: left;
              }
              &:nth-of-type(5) {
                width: 250px;
                text-align: left;
              }
              &:nth-of-type(6) {
                width: 360px;
                padding: 17px 15px 24px;
                text-align: left;
              }
            }
            tr {
              &:last-of-type {
                border-bottom: 1px solid $theme-border-gray-fade;
              }
              td {
                .linked-wrapper {
                  color: $brand-tertiary;
                  text-decoration: none;
                  margin-right: 40px;
                  font-weight: 600;
                  .linked-img {
                    padding-right: 4px;
                  }
                }
                .remove-link {
                  font-size: 14px;
                  line-height: 17px;
                  color: $theme-tertiary-text;
                }
              }
            }
          }
        }
        .btn-done {
          float: right;
          padding: 9px 38px 10px;
          margin: 18px 32px 0;
          font-size: 16px;
          line-height: 19px;
          color: $theme-text;
          font-weight: 400 !important;
          border: 2px solid $gainsboro;
          background-color: transparent;
          &:hover {
            background-color: transparent !important;
          }
          &:focus {
            background-color: transparent !important;
          }
          &:active {
            background-color: transparent !important;
          }
        }
        .comment-note-wrapper {
          display: inline-block;
          font-size: 13px;
          line-height: 16px;
          letter-spacing: 0.4px;
          font-weight: 700 !important;
          color: $theme-tertiary-text;
          margin: 32px 28px;
        }
        @media (max-width: 767px) {
          margin-top: 30px;
          .search-results-wrapper {
            .table {
              display: block;
              overflow: auto;
              thead tr th,
              tbody tr td {
                &:nth-of-type(1) {
                  min-width: 200px;
                  width: 200px;
                }
                &:nth-of-type(2),
                &:nth-of-type(3),
                &:nth-of-type(4) {
                  min-width: 120px;
                  width: 120px;
                }
                &:nth-of-type(5) {
                  min-width: 200px;
                  width: 200px;
                }
                &:nth-of-type(6) {
                  width: 250px;
                  min-width: 250px;
                }
              }
            }
          }
          .comment-note-wrapper {
            margin: 20px 0 0;
            display: block;
          }
          .btn-done {
            float: none;
            margin: 20px 0 0;
          }
        }
      }
    }
  }
  &.modal-property-wrapper {
    max-width: 100% !important;
    width: 100%;
    padding: 0 10px;
    .modal-content {
      max-height: 90vh;
    }
    .modal-header {
      padding: 26px 48px;
      @media (max-width: 767px) {
        padding: 15px;
      }
    }
    .modal-body {
      width: 100%;
      position: relative;
      padding: 32px 48px 0;
      @media (max-width: 767px) {
        padding: 30px 15px;
      }
      .form-add-edit-garden {
        .form-content-wrapper {
          display: flex;
          .input-field {
            margin-bottom: 30px;
          }
          .dropdown {
            label {
              color: #212121;
              font-size: 16px;
              line-height: 19px;
              margin-bottom: 10px;
            }
            span {
              background: $white;
              border: 1px solid $theme-tertiary-text;
              box-sizing: border-box;
              display: block;
              padding: 15px;
              width: 276px;
              height: 56px;
              outline: 0;
              font-weight: 400;
              font-size: 16px;
              border-radius: 4px;
            }
            .dropdown-arrow {
              margin-left: 15px;
            }
          }
        }

        @media (max-width: 767px) {
          .form-content-wrapper {
            flex-direction: column;
            > div {
              max-width: 100%;
              flex: 0 0 100%;
            }
            .input-field {
              margin-left: 0 !important;
            }
          }
          .input-field {
            width: 100%;
          }
        }
      }
      .buttons-container {
        justify-content: flex-start;
        padding-bottom: 15px;
        padding-top: 26px;
        .button.btn.md {
          padding: 10px 24px;
          min-width: 111px;
          &.btn-reset {
            background-color: transparent !important;
          }
        }
        @media (max-width: 767px) {
          padding: 0;
        }
      }
      &::before {
        top: 265px;
        left: 0;
        width: 100%;
        height: 1px;
        content: '';
        background-image: url('../../../assets/images/form-before.png');
        position: absolute;
        @media (max-width: 1023px) {
          top: 380px;
        }
        @media (max-width: 767px) {
          display: none;
        }
      }
      .deceased-wrapper {
        margin: 17px -45px 0;
        .search-results-wrapper {
          .header-search-results {
            h2 {
              text-transform: initial;
              color: $theme-secondary-text;
            }
          }
          .attachment-img {
            padding-right: 4px;
          }
          .table {
            .properties-checkbox {
              .checkbox-wrapper {
                display: flex;
                margin-bottom: 0;
                label.checkbox-inline {
                  display: flex;
                  flex-direction: row-reverse;
                  align-items: center;
                  input[type='checkbox'] + span {
                    margin-right: 10px !important;
                    @media (max-width: 767px) {
                      margin-left: 0 !important;
                    }
                  }
                }
              }
            }
            thead tr th,
            tbody tr td {
              text-align: left;
              &:nth-of-type(1) {
                width: 529px;
                text-align: left;
              }
              &:nth-of-type(2) {
                width: 127px;
                text-align: left;
              }
              &:nth-of-type(3) {
                width: 247px;
                text-align: left;
              }
              &:nth-of-type(4) {
                width: 406px;
                text-align: left;
                padding: 15px 15px 24px;
              }
            }
            tr {
              &:last-of-type {
                border-bottom: 1px solid $theme-border-gray-fade;
              }
              td {
                .linked-wrapper {
                  color: $brand-tertiary;
                  text-decoration: none;
                  margin-right: 40px;
                  font-weight: 600;
                  .linked-img {
                    padding-right: 4px;
                  }
                }
                .remove-link {
                  font-size: 14px;
                  line-height: 17px;
                  color: $theme-tertiary-text;
                }
              }
            }
          }
        }
        .btn-done {
          float: right;
          padding: 9px 38px 10px;
          margin: 18px 32px 0;
          font-size: 16px;
          line-height: 19px;
          color: $theme-text;
          font-weight: 400 !important;
          border: 2px solid $gainsboro;
          background-color: transparent;
          &:hover {
            background-color: transparent !important;
          }
          &:focus {
            background-color: transparent !important;
          }
          &:active {
            background-color: transparent !important;
          }
        }
        .comment-note-wrapper {
          display: inline-block;
          font-size: 13px;
          line-height: 16px;
          letter-spacing: 0.4px;
          font-weight: 700 !important;
          color: $theme-tertiary-text;
          margin: 32px 28px;
        }
        @media (max-width: 1023px) {
          .header-search-results {
            flex-direction: column;
            align-items: flex-start !important;
            h2 {
              margin-bottom: 15px !important;
            }
            .pagination-section {
              justify-content: flex-start;
              min-width: 0;
            }
          }
        }
        @media (max-width: 767px) {
          margin: 30px 0 0;
          .search-results-wrapper {
            .table {
              display: block;
              overflow: auto;
              thead tr th,
              tbody tr td {
                &:nth-of-type(1) {
                  min-width: 200px;
                  width: 200px;
                }
                &:nth-of-type(2) {
                  min-width: 120px;
                  width: 120px;
                }
                &:nth-of-type(3) {
                  min-width: 200px;
                  width: 200px;
                }
                &:nth-of-type(4) {
                  width: 250px;
                  min-width: 250px;
                }
              }
            }
          }
          .header-search-results {
            .pagination-section {
              flex-direction: column;
              align-items: flex-start !important;
              > div {
                &:first-child {
                  margin-bottom: 15px;
                }
                .records-page {
                  padding-left: 0;
                }
              }
            }
          }
          .comment-note-wrapper {
            margin: 20px 0 0;
            display: block;
          }
          .btn-done {
            float: none;
            margin: 20px 0 0;
          }
        }
        @media (max-width: 500px) {
          .header-search-results {
            .pagination-section {
              margin-bottom: 50px;
            }
          }
        }
      }
    }
  }
  .no-results-container {
    padding: 2em 0 !important;

    p {
      margin-bottom: 0 !important;
    }
  }
  &.delete-document-wrapper {
    .form-add-edit-garden {
      position: relative;
      .delete-lenght-note {
        font-style: italic;
        line-height: 17px;
        position: absolute;
        right: 0;
        bottom: 110px;
        @include font($lato, 400, 16px, $theme-tertiary-text);
      }
      .input-field {
        margin-bottom: 40px;
      }
      @media (max-width: 767px) {
        .delete-lenght-note {
          bottom: 65px;
        }
        .container-textarea {
          margin-bottom: 50px;
        }
      }
    }
    .modal-body {
      .buttons-container {
        justify-content: flex-start;
      }
      .buttons-container-reason {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 15px;
        @media (max-width: 767px) {
          padding-bottom: 0;
        }
      }
    }
  }
  &.processing-wrapper {
    max-width: 531px !important;
    .modal-content {
      .modal-body {
        padding: 0;
        min-width: 100%;
        text-align: center;
        button {
          margin-bottom: 58px;
          background: $white;
          box-sizing: border-box;
          border-radius: 4px;
          font-style: normal;
          font-size: 16px;
          line-height: 19px;
          border: 1px solid $modal-dialog-title;
          color: $modal-dialog-title;
        }
      }
      .modal-header {
        padding: 28px 0 33px;
      }
    }
    .modal-title {
      font-style: normal;
      line-height: 24px;
      text-align: center;
      @include font($lato, 700, 20px, $brand-secondary);
    }
    p {
      font-style: normal;
      line-height: 32px;
      text-align: center;
      @include font($lato, 500, 20px, $theme-text);
      padding: 32px 0 43px;
      margin-bottom: 0;
    }

    .indoor-maps-popup-title {
      padding: 32px 90px;
    }
  }
}

.modal-dialog.modalProperties.modal-add-edit-garden.modal-edit-image {
  max-width: 100% !important;
  .modal-body {
    width: calc(100vw - 120px);
    height: calc(100vh - 150px);
    position: relative;
    padding: 10px 0 0;
    background: url(../../../assets/images/editor-bg.svg) repeat !important;
    .loader-container {
      height: 100%;
    }
    .buttons-container {
      position: absolute;
      bottom: 15px;
      right: 10px;
      z-index: 9;
      padding-bottom: 0;
      @media (max-width: 1100px) {
        position: static;
      }
    }
  }
  .image-editor-wrapper-tui {
    position: relative;
    height: 100%;
    .zoom {
      position: absolute;
      left: calc(50% - 25px);
      top: 10px;
      color: $theme-primary-text;
      z-index: 1000;
      display: flex;
      align-items: center;
      font-size: 14px;
      text-align: center;
      bottom: 10px;
      top: initial;
      span {
        cursor: pointer;
        font-size: 25px;
        text-align: center;
        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
        &:first-child {
          margin-right: 18px;
        }
        &:last-child {
          margin-left: 18px;
        }
      }
      @media (max-width: 1100px) {
        position: static;
      }
    }
  }
  .tui-image-editor-container {
    background: transparent;
    .color-picker-value {
      width: 20px;
      height: 20px;
    }
    .clockwise {
      padding-top: 10px !important;
    }
    .tui-image-editor-controls-logo {
      display: none !important;
    }
    .tui-image-editor-controls {
      background: rgb(123, 185, 65);
      .tui-image-editor-controls-buttons {
        display: none;
      }
      .tui-image-editor-menu {
        text-align: center;
        .tui-image-editor-item {
          padding: 3px;
          .tui-image-editor-icpartition,
          &.tie-btn-delete,
          &.tie-btn-deleteAll {
            display: none;
          }
          &.tie-btn-rotate {
            margin-right: 200px;
          }
          .svg_ic-menu {
            width: 20px;
            height: 20px;
            use.normal.use-default {
              fill: $theme-primary-text;
              stroke: $theme-primary-text;
            }
          }
          &:not([class|='tie']) {
            padding: 0;
            margin: 0;
          }
        }
      }
    }
    .tui-image-editor-main-container {
      background-color: transparent !important;
      top: 64px;
      top: 0;
      .tui-image-editor-main {
        height: 100%;
        top: 0;
      }
      .tui-image-editor-header {
        display: none;
      }
      .tui-image-editor-wrap {
        height: 100%;
        padding-top: 10px;
        canvas {
          max-height: 100% !important;
        }
      }
    }
  }
}

.image-editor-wrapper-tui {
  .tui-image-editor {
    top: initial !important;
    bottom: 60px !important;
  }
  .open-file {
    position: absolute;
    left: 30px;
    z-index: 1000;
    line-height: 64px;
    font-size: 16px;
    cursor: pointer;
    bottom: 0;
    @media (max-width: 1100px) {
      position: static;
    }
  }
  .fileInput {
    display: none;
  }
  .tie-crop-button {
    label {
      vertical-align: middle !important;
    }
  }
}

.tie-crop-button {
  .tui-image-editor-button {
    background: rgba(97, 207, 128, 0.2);
    padding: 5px;
    border-radius: 5px;
    padding-right: 10px;
    cursor: pointer;

    label {
      cursor: pointer;
      color: #fff !important;
    }

    &.cancel {
      background: #e0d5cd33;
    }
  }
}

.tui-image-editor-submenu {
  font-size: 12px !important;
  height: 50px !important;
  > div {
    padding-bottom: 5px !important;
  }

  .svg_ic-submenu {
    width: 20px;
    height: 20px !important;
  }
}
.tui-image-editor-menu-filter {
  .tui-image-editor-submenu-item {
    height: unset !important;
  }
}
.tui-image-editor-menu-text {
  .tui-image-editor-submenu-item {
    .tui-image-editor-range-wrap {
      display: inline-block !important;
    }
    .tui-image-editor-range-value {
      color: white !important;
    }
  }
}

.tui-image-editor-submenu-item {
  height: 50px !important;
  li {
    display: inline-block !important;
    vertical-align: middle !important;
  }
  .tui-image-editor-range-value {
    color: white !important;
  }
  .tui-image-editor-button.preset {
    margin-bottom: 0 !important;
  }
}

.tui-image-editor-menu-rotate {
  .tui-image-editor-partition {
    display: none !important;
  }
}

@media (max-width: 1100px) {
  .modal-dialog.modalProperties.modal-add-edit-garden.modal-edit-image {
    .image-editor-wrapper-tui {
      .editor-extra-options {
        @include flex(flex-start, center);
        position: absolute;
        bottom: 0;
        right: 15px;
        > div {
          display: flex;
          .zoom {
            margin: 0 30px;
            span {
              margin-right: 10px;
              &:last-child {
                margin-left: 10px;
              }
            }
          }
        }
        .buttons-container {
          .button.btn.md {
            min-width: 100px;
          }
        }
      }
      .tui-image-editor-controls {
        .tui-image-editor-menu {
          text-align: left;
          padding-left: 10px;
          .tui-image-editor-item.tie-btn-rotate {
            margin-right: 30px;
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .modal-dialog.modalProperties.modal-add-edit-garden.modal-edit-image {
    .image-editor-wrapper-tui {
      .editor-extra-options {
        width: 50%;
        flex-direction: column;
        align-items: flex-end;
        height: 120px;
        right: 10px;
      }
      .tui-image-editor-controls {
        display: flex;
        align-items: center;
        height: 120px;
        padding: 5px 0;
        .tui-image-editor-menu {
          width: 50%;
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          .tui-image-editor-item {
            width: 15%;
            margin: 0;
            text-align: center;
            &.tie-btn-rotate {
              margin-right: 0;
            }
            &:not([class|='tie']) {
              display: none;
            }
          }
        }
      }
    }
    .tui-image-editor-container .tui-image-editor-main-container {
      bottom: 120px;
    }
  }
}

@media (max-width: 767px) {
  .modal-dialog.modalProperties.modal-add-edit-garden.modal-edit-image {
    .modal-body {
      width: 100%;
    }
    .image-editor-wrapper-tui {
      .tui-image-editor {
        width: 98% !important;
        height: 98% !important;
      }
      .editor-extra-options {
        padding: 0 10px;
        right: 0;
      }
    }
  }
}

@media (max-width: 500px) {
  .modal-dialog.modalProperties.modal-add-edit-garden.modal-edit-image {
    .tui-image-editor-container .tui-image-editor-main-container {
      bottom: 130px;
    }
    .image-editor-wrapper-tui {
      .tui-image-editor-controls {
        align-items: flex-start;
        justify-content: center;
        height: 130px;
        .tui-image-editor-menu {
          width: 100%;
          .tui-image-editor-item {
            padding: 0;
            .svg_ic-menu {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
      .editor-extra-options {
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
        height: 130px;
        bottom: 6px;
        > div {
          .open-file {
            font-size: 14px;
          }
          .zoom {
            margin: 0 15px;
          }
        }
        .buttons-container {
          flex-direction: column;
          .button.btn {
            padding: 5px 10px;
            &:last-child {
              margin: 5px 0 0 !important;
            }
          }
        }
      }
    }
  }
}

.delete-length-note {
  position: absolute;
  height: 19px;
  left: 72%;
  bottom: 10px;

  font-style: italic;
  line-height: 19px;

  // color: #979797;
  @include font($lato, 400, 16px, $theme-tertiary-text);
}
.reason-title {
  color: $theme-text;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
}

.place-button-links {
  position: absolute;
  top: 22px;
  right: 560px;
  @media (max-width: 1100px) {
    right: 470px;
  }
  button {
    &:last-child {
      margin-left: 25px;
    }
  }
  .remove-link {
    font-size: 14px;
    line-height: 17px;
    color: $theme-tertiary-text;
    text-decoration: underline;
    cursor: pointer;
  }
  @media (max-width: 1023px) {
    right: 0;
    top: 40px;
  }
  @media (max-width: 500px) {
    right: auto;
    left: 15px;
    top: 160px;
    padding-right: 0 !important;
  }
}
