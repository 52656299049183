.container-profile-details {
  .deceased-edit-personal {
    .details-title {
      font-size: 16px;
    }
    .card-details-container,
    .card-details-container-wrapper {
      @media (max-width: 1023px) {
        .row {
          > div {
            max-width: 50%;
            flex: 0 0 50%;
          }
        }
        .input-field {
          input {
            min-width: 0;
          }
          &.datepicker-wrapper {
            input {
              padding-right: 30px;
            }
            .react-datepicker-wrapper::after {
              right: -15px;
            }
          }
        }
      }
      @media (max-width: 600px) {
        .row {
          > div {
            max-width: 100%;
            flex: 0 0 100%;
          }
        }
      }
    }
  }
}
