.profile-wrapper {
  .page-header-right {
    .btn-edit {
      min-width: 115px;
      position: relative;
      top: -20px;
      color: $white !important;
      text-transform: uppercase;
    }
  }
  .view-profile-wrapper {
    background-color: $white;
    box-shadow: 0px 3px 10px rgba(199, 199, 199, 0.25);
    .title-profile {
      @include font($lato, 700, 18px, $theme-tertiary-text);
      line-height: 22px;
      padding: 15px 25px;
      border-bottom: 1px solid rgba($theme-tertiary-text, 0.2);
      margin: 0;
      @media (max-width: 1023px) {
        padding: 15px;
      }
    }
    .profile-info {
      padding: 30px 25px;
      display: flex;
      .item-card {
        width: 30%;
      }
      .input-field {
        margin-bottom: 0;
      }
      @media (max-width: 1023px) {
        padding: 15px 15px 0;
        flex-wrap: wrap;
        .item-card {
          width: calc(50% - 15px);
          margin-right: 15px;
          margin-bottom: 15px;
        }
      }
      @media (max-width: 600px) {
        .item-card {
          width: 100%;
          margin-right: 0;
        }
      }
    }
    .options-container {
      padding: 20px 25px;
      border-top: 1px solid rgba($theme-tertiary-text, 0.2);
      button:first-child {
        min-width: 115px;
        margin-right: 40px;
      }
    }
    .profile-form-wrapper {
      width: 100%;
      .input-field {
        input {
          width: 96%;
          height: 52px;
          border-radius: 4px;
        }
      }
    }
  }
}

.profile-card {
  margin-right: 15px;
}

.profile-card-added {
  .item-card-value {
    margin-right: 15px;
    color: $green !important;
    font-weight: 600 !important;
  }
}

.profile-card-deleted {
  .item-card-value {
    margin-right: 15px;
    color: $brand-secondary !important;
    font-weight: 600 !important;
  }
}
