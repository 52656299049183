.wrapper {
  margin-left: 30px;
  .minusButton {
    background: $brand-tertiary;
    color: $white;
    font-weight: 800;
    height: 30px;
    width: 28px;
    border-radius: 4px 0px 0px 4px;
  }
  .plusButton {
    background: $brand-tertiary;
    color: $white;
    font-weight: 800;
    height: 30px;
    width: 28px;
    border-radius: 0px 4px 4px 0px;
  }
  .quantitySpan {
    align-items: center;
    justify-content: center;
    border-top: 1px solid #c5c5c5;
    border-bottom: 1px solid #c5c5c5;
    width: 35px;
    height: 30px;
    text-align: center;
    padding: 5px 15px;
    color: $theme-text;
  }
}
