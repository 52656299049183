// basic colors
$white: #fff;
$black: #000;

// brand colors
$brand-primary: #2e7d32;
$brand-secondary: #f57c00;
$brand-tertiary: #60cf80;
$brand-default: #e0e0e0;

// theme colors
$theme-text: #212121;
$theme-primary-text: #333;
$theme-secondary-text: #616161;
$theme-tertiary-text: #979797;
$theme-placeholder-text: $theme-tertiary-text;
$theme-success: #60cf80;
$theme-priority-low: #33ea8f;
$theme-priority-high: #fda831;
$theme-table-nth: #f7fbf8;
$theme-followup: #fcf59e;
$table-row-alt-bg: #f7f7f7;
$theme-modal-tagbg: #f1f1f1;
$error-msg: #d0021b;
$dark-green: #195714;
$theme-light-gray: #f3f3f3;
$theme-border-gray: #ababab;
$theme-user-black: #303030;
$theme-modal-tag: #b0b0b0;
$selected-dates-color: #ebf9f5;
$status-msg-color: #bc9f4e;
$dropdown-modal-tags: #858585;
$dropdown-map-bg: #171717;
$dropdown-angle-black: #22231f;
$theme-border-gray-fade: rgba(151, 151, 151, 0.2);
$theme-row-box-shadow: rgba(0, 0, 0, 0.1);
$theme-dropdown-shadow: rgba(0, 0, 0, 0.0829054);
$theme-rdbms-border: rgba(0, 0, 0, 0.33);
$theme-rdbms-btn: rgba(235, 255, 0, 0.85);
$border-checkbox: #d5d5d5;
$theme-btn-cancel: #848484;
$gainsboro: #e0e0e0;
$tab-bg: #cecece;
$nav-link-color: #0e5641;
$highlighted-icons: #0d5641;
$bg-name: #01a453;
$tab-active-bg: #f4f4f4;
$links-color: #337ab7;
$maps-button-inactive-color: #565656;
$theme-toggle-disable: #d9d0d0;
$search-error-bg: rgba(245, 124, 0, 0.2);
$search-error-icon-bg: #d67512;
//maps-colors
$theme-occupied: #e35e5a;
$theme-occupied-light: #ecc5c4;
$theme-occupied-box: #e35e5a;
$theme-for-sale: #468f4a;
$theme-for-sale-light: #adeab1;
$theme-empty: #f3dcfc;
$theme-reserved: #ffcc00;
$theme-reserved-light: #fff3c2;
$theme-sold: #916aa5;
$theme-sold-light: #f3dcfc;
$theme-TB: #c54a46;
$theme-TB-light: #ffeadc;
$theme-TI: #2ba931;
$theme-TI-light: #ccf0d6;
$theme-admin-hold: #575757;
$theme-admin-hold-light: #e0e0e0;
$theme-tree: #711c19;
$theme-tree-light: #eadada;
$theme-pipe: #1f5ca4;
$theme-pipe-light: #dae4f0;
$theme-obstruct: #212121;
$theme-obstruct-light: #ded9d9;

// Status Colors
$theme-contactLeft: #dbcbff;
$theme-scheduled-funeral: #917ac5;
$theme-scheduled-funeralbg: #dbcbff;
$theme-scheduled-cemetery: #2b86ed;
$theme-scheduled-cemeterybg: #e9f2fd;
$addendum-label: #9a4e51;
$workOrder-headerText: #ffc0c0;
$modal-dialog-title: #2e7d32;
$nero: #282828;
$table-border-color: #e0e0e0;
$theme-success-text: #337a2e;
$theme-approval: #756e0e;
$theme-error-text: #ff2828;
$theme-assigned-text: #c4ffc0;
$theme-status-success: #a0e6b2;
$theme-status-inprogess: #fcf59e;
$theme-static-color: #767676;
$theme-border-light: #d8d8d8;

// react toggle colors
$react-toggle-track-bg: #4d4d4d;
$react-toggle-track-checked: #19ab27;
$react-toggle-track-checked-disabled: #128d15;
$react-toggle-thumb-bg: #fafafa;
$react-toggle-focus-bg: #0099e0;
