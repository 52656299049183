.multi-select-wrap {
  width: 100%;
  .dropdown-heading-value {
    > span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &.dropdown-disabled {
    pointer-events: none;
    .dropdown {
      pointer-events: none !important;
      .dropdown-heading {
        background-color: #f2f2f2 !important;
        .dropdown-heading-value {
          span {
            color: $theme-text !important;
          }
        }
      }
    }
  }
  &.case-role-filter {
    margin-bottom: 40px;
    .dropdown {
      .dropdown-content {
        top: auto !important;
        bottom: 100%;
      }
    }
  }
  .error-msg {
    position: absolute;
  }

  &.no-icon {
    .dropdown-heading-dropdown-arrow {
      display: none !important;
    }
  }
  label {
    color: $theme-text;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 15px;
    .input-mandatory {
      color: $error-msg;
    }
  }
  .dropdown {
    .dropdown-content {
      left: 0 !important;
      width: 100%;
      border: 1px solid $brand-default !important;
      position: absolute !important;
      margin-top: 0 !important;
      overflow-y: auto !important;
      max-height: 200px !important;

      .select-panel {
        .select-list label[aria-selected='true'],
        .item-renderer,
        .select-item .item-renderer {
          width: 100%;
          cursor: pointer;
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          justify-content: space-between;

          input[type='checkbox'] {
            width: auto !important;
            display: none;

            &:checked {
              + span {
                width: 100%;
                display: flex !important;
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
                padding: 0;

                &::after {
                  content: '\f00c';
                  font-weight: 900;
                  color: $white;
                  margin-left: 10px;
                  border: 1px solid $brand-primary;
                  font-size: 10px;
                  padding: 2px;
                  border-radius: 50%;
                  background-color: $brand-primary;

                  @extend .custom-fontawesome;
                }
              }
            }
          }
        }

        .select-list label[aria-selected='false'] .item-renderer,
        label[aria-selected='false'] .item-renderer {
          flex-direction: row;

          > input {
            display: none;
          }
        }

        .select-item {
          margin: 0 !important;
          padding: 5px 17px !important;
          min-height: 50px;
          border-bottom: 1px solid $brand-default !important;
          background-color: transparent !important;

          @extend .d-flex;

          @extend .align-items-center;

          @extend .justify-content-start;

          .item-renderer > span {
            color: $theme-secondary-text;
            cursor: pointer !important;
            padding: 0 !important;
            font-size: 16px;
          }
        }

        .select-list li {
          &:last-child .select-item {
            border: none !important;
          }
        }
      }

      &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        left: 20px;
        box-sizing: border-box;
        border: 3px solid black;
        border-color: transparent transparent $white $white;
        transform-origin: 0 0;
        transform: rotate(135deg);
        box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.07);
      }
    }

    .dropdown-heading {
      height: 55px !important;
      cursor: pointer !important;
      border: 1px solid $theme-tertiary-text !important;
      padding: 0 0 !important;
      box-shadow: none;
      border-radius: 4px !important;

      .dropdown-heading-value {
        display: flex;
        padding: 0 16px !important;
        align-items: center;
        justify-content: space-between;
        span {
          color: $theme-text;
          font-size: 16px;
          line-height: 19px;
        }

        &::after {
          content: '\f107';
          font-weight: 900;
          color: $theme-table-nth;
          cursor: pointer;

          @extend .custom-fontawesome;
        }
      }
    }
  }
}
