.sidebar-wrapper {
  background: rgba($theme-secondary-text, 0.3);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transform: translate(100%);
  z-index: 1048;
  &.active {
    transform: translate(0);
  }
  .sidebar {
    width: 400px;
    background: $white;
    height: 100vh;
    padding-top: 95px;
    .nav {
      .nav-item {
        margin-bottom: 8px;

        .nav-link,
        a {
          @include font($lato, 400, 18px, $theme-text);
          @include flex(flex-start, center);
          padding: 15px 10px 15px 75px;
          text-decoration: none;
          .icon-container {
            display: flex;
            width: 24px;
            justify-content: center;
            margin-right: 40px;
            i {
              color: $theme-secondary-text;
              margin-top: -1px;
            }
          }
          &:hover,
          &:active,
          &.active {
            background: rgba($theme-light-gray, 0.3);
          }
        }
        &.disabled {
          pointer-events: none;
          opacity: 0.3;
        }
        &:last-child {
          margin-bottom: 40px;
        }
      }
      .items-search {
        margin-top: 15px;
        padding: 25px 0 10px;
        border-top: 1px solid rgba($theme-tertiary-text, 0.2);
        .items-list {
          max-height: calc(
            100vh - (2 * 57px) - 72px - 50px - 40px - 95px - 16px - 48px
          );
          overflow-y: auto;

          .nav-item {
            margin-bottom: 8px;
          }
        }
        span.description {
          @include font($lato, 700, 13px, $theme-tertiary-text);
          line-height: 16px;
          display: block;
          letter-spacing: 1px;
          font-style: normal;
          text-transform: uppercase;
          padding: 0 15px 20px 65px;
        }
      }
    }
    @media (max-width: 767px) {
      width: 100%;
      padding-top: 0;
      height: unset;
      position: relative;
      .nav {
        display: block;
        height: calc(100vh - 152px);
        margin-top: 100px;
        overflow-y: auto;
        .nav-item {
          margin-bottom: 0;
          a {
            padding: 12px 10px 12px 20px;
            font-size: 16px;
            .icon-container {
              margin-right: 20px;
            }
          }
        }
        .items-search {
          padding-top: 15px;
          span.description {
            padding: 0 15px 15px 20px;
          }
          .items-list {
            max-height: unset;
            .nav-item {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .container-logout-button {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 400px;
    padding: 20px 15px 20px 65px;
    border-top: 1px solid rgba($theme-tertiary-text, 0.2);
    background-color: $white;
    button {
      @include font($lato, 400 !important, 18px, $theme-secondary-text);
      min-width: 0;
      text-transform: capitalize !important;
      color: $theme-secondary-text !important;
      img {
        padding-right: 35px;
      }
      &:hover,
      &:active {
        color: $theme-secondary-text !important;
      }
    }
    @media (max-width: 767px) {
      padding: 12px 10px 12px 20px;
      width: 100%;
      position: static;
      button img {
        padding-right: 22px;
      }
    }
  }
}
