.heading {
  @extend .d-flex;

  height: 94px;
  margin: 40px 0 24px;
  padding: 0 24px;
  background-color: $white;
  align-items: center;
  justify-content: space-between;

  h5 {
    @extend .lato-bold;
    color: $brand-primary;
    margin: 0;
    font-size: 32px;
    line-height: 39px;
  }
}

span.add-new-facility {
  @extend .lato-bold;

  top: 0;
  color: $brand-secondary;
  right: 15px;
  cursor: pointer;
  position: absolute;
  font-size: 14px;
  line-height: 24px;
}
