@import '../../../assets/styles/color';

.error-wrapper {
  background: $white;
  border: 1px solid rgba($theme-placeholder-text, 0.2);
  height: 200px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .error-msg-text {
    font-size: 18px;
    margin-bottom: 0;
  }

  button {
    margin-top: 8px;
    margin-bottom: 0;
  }
}
