@import '../../../assets/styles/color';

.item-card {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: $theme-tertiary-text;
  .item-card-name {
    line-height: 20px;
  }
  .item-card-value {
    color: $theme-text;
    word-break: break-all;
  }
  &.item-card-horizontal {
    flex-direction: row;
    .item-card-value {
      padding-left: 5px;
      line-height: 12px !important;
    }
  }

  .super-admin-reserve-status {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button.btn {
      font-size: 13px;
      display: flex;
      align-items: center;

      img {
        margin-right: 12px;
      }
    }

    .save-container {
      display: flex;
    }
    + .reservation-status-dropdown-container {
      @media (max-width: 767px) {
        .select-wrapper {
          max-width: 150px;
        }
      }
      .select-wrapper .select-dropdown__menu .select-dropdown__menu-list {
        max-height: 150px;
        .select-dropdown__option {
          padding: 14px 12px;
          &::after {
            right: 0;
          }
        }
      }
    }
    @media (max-width: 767px) {
      justify-content: flex-start;
      .save-container,
      .edit-btn {
        margin-left: 40px;
      }
    }
  }
}

.reservation-status-dropdown-container {
  .select-wrapper {
    margin-bottom: 0 !important;

    .select-dropdown__value-container {
      padding-top: 0px;
      padding-bottom: 0px;
      line-height: 19px;

      .select-dropdown__single-value {
        font-size: 13px;
      }
    }
  }
}
