.table-container-contacts {
  tbody tr td,
  thead tr th {
    &:nth-of-type(odd) {
      width: 30%;
    }
    &:nth-of-type(even) {
      width: 20%;
    }
  }
}
