// datalist input styling
.datalist-input-container {
  position: relative;
  .datalist-items {
    box-shadow: 0px 10px 15px rgba(199, 199, 199, 0.5);
    border: none;
    overflow: auto;
    position: absolute;
    width: 100%;
    max-height: 250px;
    top: 100%;
    z-index: 2;
    @media (max-width: 767px) {
      height: 200px;
    }
    > li {
      list-style: none;
      color: $theme-text;
      border-bottom: 1px solid rgba($theme-placeholder-text, 0.3);
      padding: 14px 40px 14px 15px;
      font-weight: 400;
      font-size: 16px;
      cursor: pointer;
      background: $white;
      &.active-item,
      &:hover {
        background-color: $gainsboro;
      }
      &.active-item {
        position: relative;
        &::after {
          top: 50%;
          right: 5px;
          padding: 4px;
          border-radius: 50%;
          font-size: 10px;
          content: '\f00c';
          position: absolute;
          font-weight: 900;
          color: $white;
          background-color: $brand-primary;
          @include translate(-50%, -50%);
          @extend .custom-fontawesome;
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
