.geo-location-wrapper {
  .container-geo-file-upload {
    .geo-location-header {
      @include flex(space-between, center);
      background: $theme-table-nth;
      min-height: 100px;
      padding: 10px 30px;
      .geo-location-header-left {
        flex-grow: 1;
        padding-right: 50px;
        h3 {
          @include font($lato, 700, 20px, $theme-text);
          line-height: 24px;
          margin: 0 0 10px;
        }
        span {
          @include font($lato, 400, 16px, $theme-tertiary-text);
          line-height: 19px;
        }
      }
      .geo-location-header-right {
        display: flex;
      }
      button {
        min-width: 165px;
        font-size: 14px;
      }

      .btn-activity-history {
        margin-right: 43px;
        line-height: 17px;
        letter-spacing: 0.5px;
        padding: 12px 19px;
        background: #f57c00;
        border: 1px solid #f57c00;
        box-sizing: border-box;
        border-radius: 4px;

        color: $brand-secondary;
        background: $white;
        border: 1px solid $brand-secondary;
        &:hover {
          color: $brand-secondary;
          border: 1px solid $brand-secondary;
          background-color: $white !important;
        }
        &:focus {
          color: $brand-secondary;
          border: 1px solid $brand-secondary;
          background-color: $white !important;
        }
        &:active {
          color: $brand-secondary;
          border: 1px solid $brand-secondary;
          background-color: $white !important;
        }
      }
      @media (max-width: 1023px) {
        padding: 10px 20px;
        button {
          min-width: 120px;
        }
        .btn-activity-history {
          min-width: 160px;
          padding: 10px;
          margin-right: 25px;
        }
      }
      @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 15px;
        .geo-location-header-left {
          margin-bottom: 20px;
          padding-right: 0;
        }
        .geo-location-header-right {
          button {
            min-width: 120px;
          }
        }
      }
    }
    .upload-file-section {
      background: $white;
      padding: 40px 30px;
      position: relative;
      .geolocation-garden-dropdown,
      .file-category {
        position: relative;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        .select-wrapper {
          max-width: 50%;
          margin-bottom: 0;
          label {
            @include font($lato, 500, 20px, $theme-tertiary-text);
            margin-bottom: 18px;
          }
          @media (max-width: 1023px) {
            max-width: 45%;
          }
          @media (max-width: 767px) {
            width: 100%;
            max-width: 350px;
            margin-bottom: 15px;
          }
        }
        .files-example {
          display: flex;
          a {
            @include font($lato, 500 !important, 18px, $black);
            text-decoration: underline;
            border-bottom-color: $black;
            padding-bottom: 0;
            margin-bottom: -25px;
            margin-left: 30px;
            line-height: 22px;
            display: block;
            &:hover,
            &:focus,
            &:active {
              color: $black !important;
            }
            @media (max-width: 1023px) {
              font-size: 16px;
            }
          }
        }
        @media (max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 40px;
          &.geolocation-garden-dropdown {
            margin-bottom: 25px;
          }
          .files-example {
            flex-direction: column;
            align-items: flex-start;
            a {
              margin: 0;
              &:first-child {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
      .upload-container {
        padding-top: 32px;
        margin-top: 32px;
        border-top: 1px solid rgba($theme-tertiary-text, 0.2);
        button {
          min-width: 215px;
        }
      }
      @media (max-width: 767px) {
        padding: 20px 15px;
        position: relative;
      }
    }
  }
}

.custom-file-upload {
  @include flex(flex-start, center);
  .button-browse {
    margin-right: 22px;
    margin-bottom: 0;
    min-width: 150px !important;
  }
  .no-file {
    @include font($lato, 400, 18px, $theme-tertiary-text);
    line-height: 22px;
    &.file-size-wrapper {
      position: absolute;
      bottom: 130px;
    }
  }
  .selected-file {
    @include flex(flex-start, center);
    span.file-name {
      @include font($lato, 700, 18px, $black);
      line-height: 22px;
      text-decoration: underline;
      word-break: break-word;
    }
    span.close-icon {
      @include flex(center, center);
      @include circle(25px);
      color: rgba($black, 0.5);
      background: $theme-table-nth;
      cursor: pointer;
      margin-left: 15px;
    }
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    .button-browse {
      margin: 0 0 30px;
      min-width: 180px !important;
    }
  }
}

.modal-dialog.modalProperties.modal-upload-error {
  .modal-header {
    .modal-title {
      color: $brand-secondary;
      text-transform: capitalize;
      text-align: center;
    }
    button.close {
      padding: 5px;
      &:before {
        font-size: 25px;
      }
    }
  }
  .modal-body {
    max-width: 530px;
    > div {
      padding: 40px 0 60px;
      text-align: center;
      p {
        @include font($lato, 500, 20px, $theme-text);
        line-height: 32px;
        margin: 0 0 35px;
      }
      button {
        min-width: 130px;
      }
    }
  }
  @media (max-width: 767px) {
    padding: 0 10px;
    .modal-body {
      max-width: 100%;
      > div {
        padding: 15px 0;
        p {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
  }
}
.container-geo-file-upload {
  .file-size-limit {
    color: #979797;
    margin-top: 5px;
    @media (max-width: 767px) {
      position: absolute;
      bottom: 155px;
    }
  }
}
