@import '../../../assets/styles/fonts';
@import '../../../assets/styles/mixins';
@import '../../../assets/styles/color';
@import '../../../assets/styles/bootstrap-utils';

div.breadcrumbs {
  div.breadcrumb {
    margin: 0;
    padding: 0;
    background-color: transparent !important;
    @extend .align-items-center;
  }

  .breadcrumb-item {
    line-height: 17px;
    a {
      @extend .lato-regular;
      font-size: 14px;
      line-height: 14px;
      color: $theme-secondary-text !important;
    }

    span {
      @extend .lato-bold;
      color: $brand-primary;
    }
  }

  i.next-icon {
    margin: 0 8px;
    line-height: 14px;
    font-size: 11px;
    color: $theme-secondary-text;
  }

  i.home-icon {
    color: $brand-secondary;
  }
}
