.table-container-search-results {
  .new-properties-wrapper {
    .properties-checkbox {
      .checkbox-wrapper {
        display: flex;
        margin-bottom: 0;
        label.checkbox-inline {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          font-size: 13px;
          input[type='checkbox'] + span {
            margin-right: 10px !important;
            @media (max-width: 767px) {
              margin-left: 0 !important;
            }
          }
        }
      }
    }
    .properties-title {
      margin-bottom: 0;
    }
    thead tr th,
    tbody tr td {
      &:nth-of-type(1) {
        min-width: calc(100% - 200px) !important;
      }
      &:nth-of-type(2) {
        min-width: 200px !important;
      }
    }
    tbody tr td {
      label.checkbox-inline {
        font-size: 16px !important;
      }
    }
    @media (max-width: 500px) {
      display: block;
      overflow-x: auto;
      thead tr th,
      tbody tr td {
        &:nth-of-type(1) {
          min-width: 250px !important;
        }
        &:nth-of-type(2) {
          min-width: 250px !important;
        }
      }
    }
  }
}

.assign-garden-wrapper {
  .property-name {
    display: inline-block;
    margin-right: 10px;
    font-size: 16px;
    a {
      color: $black;
    }
  }
  .assign-garden-note {
    font-style: italic;
    @include font($lato, 400, 16px, $theme-tertiary-text);
  }
}

.more-text {
  position: absolute;
  top: 57px;
  right: 20px;
  &.dropdown > .btn {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 13px;
    color: #979797 !important;
    letter-spacing: 0.4px;
    line-height: 16px;
    text-transform: uppercase;
    width: 65px;
    .fas {
      font-size: 14px;
      line-height: normal;
      padding-left: 6px;
      margin-top: 1px;
      color: #a2a1a1 !important;
    }
  }
  @media (max-width: 767px) {
    right: 0;
  }
}

.button-garden {
  @media (max-width: 767px) {
    margin-right: 0 !important;
    margin-top: 25px !important;
  }
}

.container-button-options {
  .button.btn.md {
    @media (max-width: 767px) {
      min-width: 110px;
    }
  }
}

.modal-dialog.modal-add-edit-garden.modal-add-edit-tour-group.modal-create-edit-garden {
  .form-add-edit-garden {
    @media (max-width: 767px) {
      .row > div {
        max-width: 100%;
        flex: 0 0 100%;
      }
      .buttons-container-reason {
        display: flex;
        button:last-child {
          min-width: 90px;
        }
      }
    }
  }
}

.deceased-wrapper .user-role-options-wrapper {
  position: relative;
  @media (max-width: 1023px) {
    .header-search-results {
      flex-direction: column;
      align-items: flex-start !important;
      h2 {
        margin-bottom: 15px !important;
      }
      .pagination-section {
        justify-content: flex-start;
        min-width: 0;
      }
    }
  }
  @media (max-width: 767px) {
    .header-search-results {
      .pagination-section {
        flex-direction: column;
        align-items: flex-start !important;
        > div {
          &:first-child {
            margin-bottom: 15px;
          }
          .records-page {
            padding-left: 0;
          }
        }
      }
    }
  }
  @media (max-width: 500px) {
    .pagination-section {
      margin-bottom: 50px;
    }
  }
  .btn-assign-all {
    position: absolute;
    right: 530px;
    top: 20px;
    @media (max-width: 1100px) {
      right: 470px;
    }
    @media (max-width: 1023px) {
      right: 20px;
      top: 40px;
    }
    @media (max-width: 500px) {
      right: auto;
      left: 15px;
      top: 160px;
    }
  }
}

.modal-dialog.modalProperties.modal-add-edit-garden.assign-all-properties-popup
  .modal-content {
  .modal-body .table-container {
    max-height: calc(100vh - 40px - 65px - 45px - 70px);
    overflow-y: auto;
    margin-bottom: 20px;
  }
}
