.deceased-wrapper {
  &.platted-wrapper {
    position: relative;
    @media (max-width: 767px) {
      .page-section .page-header {
        margin-bottom: 150px;
      }
    }
    .err-msg-custom {
      position: absolute;
      top: calc(2rem + 45px - 10px);
      left: 290px;
    }
    .deceased-search-wrapper {
      margin-bottom: 25px;
      .panel-body {
        padding: 0;
        .form-elements-wrapper {
          padding: 30px 25px 0;
        }
        .buttons-footer {
          padding: 20px 25px;
          border-top: 1px solid rgba($theme-tertiary-text, 0.2);
          button:first-child {
            min-width: 120px;
            margin-right: 40px;
          }
        }
      }
      .advanced-search-wrapper {
        > button {
          margin-bottom: 30px;
          i {
            padding-left: 5px;
          }
        }
        h3 {
          @extend .lato-bold;
          font-size: 14px;
          color: $theme-tertiary-text;
          line-height: 17px;
          letter-spacing: 0.4px;
          text-transform: uppercase;
          margin: 0 0 18px;
        }
      }
    }
    .search-results-wrapper {
      .header-search-results {
        @include flex(space-between, center);
        background-color: $theme-table-nth;
        min-height: 58px;
        padding: 15px;
        h2 {
          @include font($lato, 700, 18px, $theme-secondary-text);
          text-transform: uppercase;
          margin-bottom: 0;
          line-height: 22px;
          display: inline-block;
          margin: 0 56px 0 9px;
        }
        .header-leftsection-wrapper {
          @media (max-width: 1100px) {
            display: flex;
            flex-direction: column;
            width: 100%;
            h2 {
              margin: 0 0 10px !important;
            }
          }
          @media (max-width: 767px) {
            margin-bottom: 10px;
          }
        }
      }
      .table-container .table {
        thead tr th,
        tbody tr td {
          &:nth-of-type(1) {
            padding-left: 25px;
          }
        }
        .dropdown-with-dots {
          .dropdown-menu {
            left: 15px !important;
            &::before {
              right: 12px;
            }
          }
        }
        .no-results-container {
          padding: 7rem 0;
          p {
            margin-bottom: 5rem;
            @media (max-width: 767px) {
              margin-bottom: 0 !important;
            }
          }
        }
      }
      .table-container-search-results {
        .table {
          thead tr th,
          tbody tr td {
            text-align: left;

            &:nth-of-type(1) {
              width: 240px;
              text-align: left;
              word-break: break-word;
            }
            &:nth-of-type(2) {
              width: 654px;
              text-align: left;
              word-break: break-word;
            }
            &:nth-of-type(3) {
              width: 168px;
              border-right: 0;
              text-align: left;
              @media (max-width: 1023px) {
                width: 190px;
              }
            }
            &:nth-of-type(4) {
              width: 168px;
              text-align: left;
            }
          }
        }
        tbody td {
          .checkbox-wrapper {
            &.records-checkbox-wrapper {
              margin-bottom: 0;
              label {
                &.checkbox-inline {
                  input[type='checkbox'] {
                    + span {
                      width: 16px;
                      border: 2px solid $border-checkbox;
                      height: 16px;
                      margin-left: 0 !important;
                      @include border-radius(2px);
                      @include transition(all 0.3s ease-in-out);
                    }
                  }
                }
              }
            }
          }
          a {
            line-height: 19px;
            @include font($lato, 400, 16px, $brand-secondary);
          }
        }
        @media (max-width: 767px) {
          .table {
            display: block;
            overflow-x: auto;
            thead tr th,
            tbody tr td {
              width: 150px;
              min-width: 150px;
              &:nth-of-type(1) {
                width: 200px;
                min-width: 200px;
              }
              &:nth-of-type(2) {
                width: 300px;
                min-width: 300px;
              }
              &:nth-last-of-type(1) {
                width: 80px;
                min-width: 80px;
              }
            }
          }
        }
      }
      .table-container-search-results-user {
        .table {
          @media (max-width: 767px) {
            display: block;
            overflow-x: auto;
          }
          thead tr th,
          tbody tr td {
            text-align: left;
            word-break: break-word;
            &:nth-of-type(1) {
              width: 240px;
            }
            &:nth-of-type(2) {
              width: 300px;
            }
            &:nth-of-type(3) {
              width: 400px;
            }
            &:nth-of-type(4) {
              width: 150px;
              font-weight: 600;
            }
            &:nth-of-type(5) {
              width: 168px;
              text-align: center;
            }
            @media (max-width: 1023px) {
              &:nth-of-type(1) {
                width: 20%;
              }
              &:nth-of-type(2) {
                width: calc(60% - 200px);
              }
              &:nth-of-type(3) {
                width: 20%;
              }
              &:nth-of-type(4) {
                width: 100px;
              }
              &:nth-of-type(5) {
                width: 100px;
              }
            }
            @media (max-width: 767px) {
              &:nth-of-type(1) {
                width: 200px;
                min-width: 200px;
              }
              &:nth-of-type(2) {
                width: 250px;
                min-width: 250px;
              }
              &:nth-of-type(3) {
                width: 150px;
                min-width: 150px;
              }
              &:nth-of-type(4) {
                width: 120px;
                min-width: 120px;
              }
              &:nth-of-type(5) {
                width: 120px;
                min-width: 120px;
              }
            }
          }
        }
        tbody td {
          .checkbox-wrapper {
            &.records-checkbox-wrapper {
              margin-bottom: 0;
              label {
                &.checkbox-inline {
                  input[type='checkbox'] {
                    + span {
                      width: 16px;
                      border: 2px solid $border-checkbox;
                      height: 16px;
                      margin-left: 0 !important;
                      @include border-radius(2px);
                      @include transition(all 0.3s ease-in-out);
                    }
                  }
                }
              }
            }
          }
          a {
            line-height: 19px;
            @include font($lato, 400, 16px, $brand-secondary);
          }
        }
      }
    }
    .btn-platted,
    .btn-activity-history {
      position: absolute;
      top: 45px;
      right: 43px;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.5px;
      padding: 12px 19px;
      background: $brand-secondary;
      border: 1px solid $brand-secondary;
      box-sizing: border-box;
      border-radius: 4px;
      @media (max-width: 1023px) {
        right: 0;
      }
      @media (max-width: 767px) {
        right: auto;
        top: 160px;
      }
    }
    .btn-report {
      position: absolute;
      top: 45px;
      right: 5px;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.5px;
      padding: 12px 19px;
      background: $brand-primary;
      border: 1px solid $brand-primary;
      box-sizing: border-box;
      border-radius: 4px;
    }
    .comment-note-wrapper {
      display: inline-block;
      font-size: 13px;
      line-height: 15.6px;
      letter-spacing: 0.4px;
      font-weight: 700 !important;
      color: $theme-tertiary-text;
      margin: 24px 2px;
    }
    .btn-activity-history {
      right: 310px;
      color: $brand-secondary;
      background: $white;
      border: 1px solid $brand-secondary;
      &:hover {
        color: $brand-secondary;
        border: 1px solid $brand-secondary;
        background-color: $white !important;
      }
      &:focus {
        color: $brand-secondary;
        border: 1px solid $brand-secondary;
        background-color: $white !important;
      }
      &:active {
        color: $brand-secondary;
        border: 1px solid $brand-secondary;
        background-color: $white !important;
      }
      @media (max-width: 1023px) {
        right: 270px;
      }
      @media (max-width: 767px) {
        right: auto;
        top: 100px;
      }
    }
    .btn-activity-history-user {
      position: absolute;
      top: 45px;
      right: 105px !important;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.5px;
      padding: 12px 19px;
      background: $brand-secondary;
      border: 1px solid $brand-secondary;
      box-sizing: border-box;
      border-radius: 4px;
    }
    .btn-activity-history-user {
      right: 297px;
      color: $brand-secondary;
      background: $white;
      border: 1px solid $brand-secondary;
      &:hover {
        color: $brand-secondary;
        border: 1px solid $brand-secondary;
        background-color: $white !important;
      }
      &:focus {
        color: $brand-secondary;
        border: 1px solid $brand-secondary;
        background-color: $white !important;
      }
      &:active {
        color: $brand-secondary;
        border: 1px solid $brand-secondary;
        background-color: $white !important;
      }
    }
    .img {
      padding: 0px 8px;
    }
    .user-role-options-wrapper {
      .dropdown.dropdown-search-filter .dropdown-menu {
        @media (max-width: 1100px) {
          top: 250px !important;
          left: 0 !important;
        }
        @media (max-width: 767px) {
          top: calc(100% + 5px) !important;
          transform: none !important;
          .filter-search-wrapper .filter-search {
            input {
              padding: 15px 10px 15px 30px;
            }
            i {
              top: 19px;
              left: 10px;
            }
          }
          .filter-form-wrapper {
            padding: 20px 15px 5px;
          }
        }
      }

      .refresh-btn {
        position: absolute;
        right: 115px;
        top: -35px;
        cursor: pointer;
        border-radius: 0px;
        .img {
          padding: 0px 8px;
        }
        .refresh-text {
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          letter-spacing: 0.4px;
          text-transform: uppercase;
          color: $theme-tertiary-text;
        }
        .dropdown-header {
          color: $theme-tertiary-text;
          background: $theme-light-gray;
          text-transform: uppercase;
          font-family: 'Lato';
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
        }

        @media (max-width: 767px) {
          left: 0;
          top: 95px;
          right: auto;
        }
      }
    }
  }
}
