.modalProperties {
  max-width: 900px !important;
  height: 100%;
  .title {
    color: $theme-text;
    font-size: 20px;
    .modal-title {
      max-width: 80%;
      line-height: 25px;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 100% !important;
    .title {
      font-size: 18px;
      .modal-title {
        line-height: 23px;
      }
    }
  }
}

.modal-dialog {
  .title .modal-title,
  .modal-title {
    font-size: 20px;
    color: $theme-text;
  }
  .modal-header {
    button.close {
      position: absolute;
      right: 25px;
      padding: 8px;
      margin: 0;
      display: flex;
      opacity: 1;
      span {
        display: none;
      }
      &:before {
        font-family: 'Font Awesome 5 Free';
        content: '\f00d';
        color: $theme-tertiary-text;
        font-size: 16px;
      }
    }
  }
}

.modal-dialog .modal-body {
  .row {
    .col-sm-4 {
      &:nth-of-type(3n) {
        .react-datepicker-popper {
          left: auto !important;
          right: 0 !important;
          .react-datepicker:before {
            left: auto;
            right: 60px;
          }
          .react-datepicker-time__input {
            height: auto !important;
          }
        }
      }
    }
  }
}
