$white-smoke: #efefef;
$cinnabar: #e54545;
$cinnabar2: #ef0909;
$grey2: #424242;

.header {
  width: calc(100%);

  .custom-container {
    padding: 0 75px;
    width: 100%;
    &.navbar {
      position: fixed;
      top: 0;
      z-index: 1049;
      background: $white;
      @include box-shadow(0 3px 10px 0 $brand-default);
    }
    @media (max-width: 1000px) {
      padding: 0 10px;
    }
    @media (max-width: 767px) {
      flex-direction: column;
      padding-top: 5px;
      padding-bottom: 6px;
      min-height: 100px;
    }

    .custom-navbrand {
      width: 224px;

      @media (min-width: 1440px) {
        min-width: 272px;
      }

      height: 70px;

      .toggle-navigation {
        margin: 0 0 0 25.2px;
        display: none !important;
      }

      .sidebar-icon {
        cursor: pointer;
      }

      .navbar-brand {
        color: $theme-primary-text;
        font-size: 1rem;
        margin-left: 1rem;

        @extend .robotoslab-bold;

        .cypress-logo {
          width: 182px;
        }
      }
      @media (max-width: 767px) {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
        justify-content: space-between !important;
        flex-direction: row-reverse;
        .navbar-brand {
          margin: 0;
          img {
            max-width: 120px;
          }
        }
      }
    }
    .navbar-right-wrapper {
      @media (max-width: 767px) {
        margin: 0 auto 0 0 !important;
      }
      .custom-location-wrapper {
        position: relative;

        &.padding-left-wrapper {
          .multiselect-dropdown-container {
            .multi-select
              .dropdown
              .dropdown-heading
              .dropdown-heading-value
              > span {
              padding-right: 24px;
            }
          }
        }
      }
      .user-section-wrapper {
        @extend .d-flex;
        @extend .align-items-center;
        @extend .justify-content-center;
        .user-profile {
          min-width: 40px;
          margin-right: 9px;
          @include circle(40px);
          object-fit: cover;
          @media (max-width: 767px) {
            @include circle(35px);
            min-width: 35px;
          }
        }
        p {
          margin-bottom: 0;
          @extend .lato-regular;
          font-style: normal;
          font-size: 16px;
          line-height: 19px;
          color: $theme-user-black;
          span {
            margin-left: 5px;
            max-width: 300px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
            vertical-align: top;
          }
        }
      }
    }
  }
}
