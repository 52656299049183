.map-search-wrapper {
  .map-search-module {
    &.map-tour {
      .loader-container {
        height: 100vh;
        .loader-section {
          background: rgba(0, 0, 0, 0.1);
        }
      }
      left: 0px;
      width: 580px;
      min-width: auto;
      height: 100vh;
      top: 0;
      right: auto;
      transition: all 0.5s ease 0s;
      -webkit-transition: all 0.5s ease 0s;
      &.close-slider {
        left: -580px;
        transition: all 0.5s ease 0s;
        -webkit-transition: all 0.5s ease 0s;
      }
      @media (max-width: 767px) {
        max-width: calc(100% - 22px);
        width: calc(100% - 22px);
        background: $white;
        padding: 0;
        height: calc(100vh - 100px);
        .loader-container {
          height: unset;
        }
        &.close-slider {
          left: calc(-100% + 22px);
          // .arrow {
          //   right: auto;
          //   left: 0;
          // }
        }
      }
      .panel-container,
      .panel-body {
        box-shadow: none !important;
        padding: 0 !important;
      }
      .nav-tabs {
        border-bottom: 0.6px solid $tab-bg;
        border-top: 0.6px solid $tab-bg;
        padding: 0 64px;
        position: relative;
        .arrow {
          position: absolute;
          top: -1px;
          cursor: pointer;
          height: 100%;
          right: -25px;
          padding: 10px;
          // background: $white;
          background: #979797;
          box-shadow: 0px 4px 4px $theme-row-box-shadow;
          i {
            // color: $theme-tertiary-text;
            color: $white;
            transform: translateY(50%);
            -ms-transform: translateY(50%);
          }
        }
        @media (max-width: 1023px) {
          padding: 0 10px;
        }
        @media (max-width: 767px) {
          padding: 0;
          .arrow {
            padding: 2px 8px;
            right: -22px;
            width: 22px;
            height: 100%;
            i {
              color: $white;
              // color: $theme-tertiary-text;
              transform: translateY(50%);
              -ms-transform: translateY(50%);
            }
          }
        }
      }
      .nav-item {
        border: hidden;
        margin-bottom: unset;
      }
      .nav-link {
        border: none;
        font-family: $inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: $theme-secondary-text;
        margin-bottom: 0;
        padding: 18px;
        cursor: pointer;
        @media (max-width: 767px) {
          padding: 14px 8px;
          font-size: 14px;
        }
        &.active {
          border-bottom: 2px solid $nav-link-color;
          font-weight: 600;
          color: $nav-link-color;
        }
        i {
          padding-right: 7px;
          @media (max-width: 767px) {
            padding-right: 3px;
          }
        }
      }
      .tab-content {
        padding: 0 25px 0 62px;
        height: 90vh;
        overflow: auto;
        @media (max-width: 1023px) {
          padding-left: 10px;
        }
        @media (max-width: 767px) {
          padding: 0 10px;
          height: calc(100% - 55px);
        }
        .heading-wrapper {
          // padding: 18px 0 16px;
          h6 {
            font-family: $inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: $nav-link-color;
            padding-bottom: 4px;
            margin-bottom: 0;
          }
          p {
            font-family: $inter;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: $theme-tertiary-text;
            margin-bottom: 0;
          }
        }
        .search-records-wrapper {
          .advanced-search-wrapper {
            > button {
              margin-bottom: 10px;
              font-weight: 600 !important;
              i {
                padding-left: 5px;
              }
            }
            h3 {
              @extend .lato-bold;
              font-size: 14px;
              color: $theme-tertiary-text;
              line-height: 17px;
              letter-spacing: 0.4px;
              text-transform: uppercase;
              margin: 0 0 18px;
            }
          }
          padding: 16px 0;
          .search-records-content-wrapper {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            &.dob-dod-wrapper {
              input {
                &.lg {
                  height: 42px;
                  min-width: 60px;
                  max-width: 60px;
                }
              }
            }
            .input-field {
              width: 50%;
              padding-bottom: 18px;
              @media (max-width: 600px) {
                width: 100%;
                padding-right: 0 !important;
              }
              &:nth-of-type(1) {
                padding-right: 16px;
              }
              &:nth-of-type(3) {
                padding-right: 16px;
              }
              .dob-wrapper {
                max-width: 200px;
                .input-field {
                  width: 60px;
                  padding-bottom: 0;
                  label {
                    font-family: $inter;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 9px;
                    line-height: 11px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    color: $theme-tertiary-text;
                    margin-bottom: 3px;
                  }
                  input {
                    padding: 13px 8px;

                    &.error-field {
                      border: 0.8px solid $brand-secondary;
                    }
                  }
                }

                .focus-field {
                  .select-wrapper {
                    .select-dropdown__control {
                      border: 0.8px solid $brand-secondary !important;
                    }
                  }
                }
              }
            }
            .select-wrapper {
              label {
                font-family: $inter;
                font-style: normal;
                font-weight: 600;
                font-size: 9px;
                line-height: 11px;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: $theme-tertiary-text;
                margin-bottom: 3px;
              }
            }
            .dob-wrapper {
              position: relative;
              .select-wrapper {
                width: 60px;
                margin-bottom: 0;
                .select-dropdown__control {
                  min-height: 42px;
                  max-height: 42px;
                  .select-dropdown__indicators {
                    max-height: 42px !important;
                  }
                  .select-dropdown__input {
                    max-width: 20px !important;
                    padding-top: 0px !important;
                    input {
                      min-width: 0;
                    }
                  }
                  .select-dropdown__single-value {
                    // font-size: 13px !important;
                    margin: 0;
                    font-family: $inter;
                    font-style: normal;
                    font-weight: normal;
                    // line-height: 16px;
                    // letter-spacing: -0.2px;
                    color: $theme-text;
                  }
                  .select-dropdown__placeholder {
                    font-size: 13px;
                    margin: 0;
                    font-family: $inter;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 16px;
                    letter-spacing: -0.2px;
                    color: $theme-tertiary-text;
                  }
                }
                &.lg {
                  .select-dropdown__value-container {
                    max-height: 42px;
                    padding: 0px 2px 2px 8px;
                  }
                }
                .select-dropdown__menu {
                  .select-dropdown__menu-list {
                    min-height: 90px;
                    .select-dropdown__option {
                      font-size: 12px;
                      padding: 6px 20px 2px 6px;
                      overflow: hidden;
                      max-width: 100px;
                      height: 30px;
                      text-overflow: ellipsis;
                    }
                  }
                }
                .select-dropdown__option--is-selected {
                  &::after {
                    right: -6px;
                    padding: 3px;
                    font-size: 12px;
                  }
                }
              }
              display: flex;
              justify-content: space-between;
              align-items: center;
              .error-msg {
                padding: 0 !important;
                position: absolute;
                top: 64px;
                &:nth-of-type(2) {
                  right: 70px;
                }
              }

              &.focus-field {
                .select-wrapper {
                  .select-dropdown__control {
                    border: 0.8px solid $brand-secondary !important;
                  }
                }
              }
            }
          }

          .error-message {
            background-color: $search-error-bg;
            border-radius: 4px;
            padding: 7px 10px;
            width: 52%;
            font-family: $lato;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 16px;
            color: $black;
            i {
              padding-right: 5px;
              color: $search-error-icon-bg;
            }
          }
          &.focus-field {
            .input-field {
              input {
                border: 0.8px solid $brand-secondary;
              }
              .select-wrapper {
                .select-dropdown__control {
                  border: 0.8px solid $brand-secondary !important;
                }
              }
            }
          }
          .tertiary {
            min-width: 102px;
            margin-right: 40px;
            margin-top: 6px;
            background: $bg-name;
          }
          .default {
            margin-top: 6px;
          }
          .btn {
            font-style: normal;
            font-weight: normal !important;
            font-size: 16px;
            line-height: 19px;
            text-transform: capitalize !important;
          }
        }
        .search-results-wrapper {
          padding: 16px 0 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 0.6px solid $tab-bg;
          .search-content-wrapper {
            h6 {
              font-family: $inter;
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 22px;
              color: $nav-link-color;
              margin-bottom: 0;
              padding-bottom: 4px;
            }
            p {
              font-family: $inter;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;
              color: $theme-tertiary-text;
              margin-bottom: 0;
            }
          }
          .back-btn-wrapper {
            padding-bottom: 0;
            .back-btn > span {
              font-family: $inter !important;
              font-style: normal;
              font-weight: 400 !important;
              font-size: 15px;
              line-height: 18px;
              color: $highlighted-icons;
              text-transform: capitalize;
            }
            i {
              color: $highlighted-icons;
              font-size: 15px;
            }
          }
          // .search-options-wrapper {
          // }
        }
        .no-result-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 50px 0;
          i {
            font-size: 40px;
            color: $theme-tertiary-text;
          }
          span {
            font-family: $inter;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: $nav-link-color;
            padding: 22px 0 6px;
          }
          p {
            font-family: $inter;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: $theme-tertiary-text;
            margin-bottom: 0;
          }
        }
        .panel-container {
          .panel-header {
            padding: 18px 14px;
            border-bottom: 0.6px solid $tab-bg;
            @media (max-width: 767px) {
              padding: 15px 10px;
            }
          }
        }
      }
      .input-field {
        label {
          font-size: 13px;
          line-height: 16px;
          margin-bottom: 8px;
          font-weight: 500;
          font-family: $inter;
        }
      }
      .css-tlfecz-indicatorContainer {
        padding: 0 !important;
      }
      .panel-container {
        margin-bottom: 0;
      }
      .infinite-scroll-component {
        height: auto !important;
        ul {
          list-style: none;
          padding: 0;
          position: relative;
          margin-bottom: 0;
          li {
            cursor: pointer;
            position: relative;
            padding: 12px 0 11px 45px;
            img {
              position: absolute;
              top: 20px;
              left: 10px;
            }
            .profile-avtar {
              display: block;
            }
            .profile-avtar-selected {
              display: none;
            }
            span {
              font-family: $inter;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              color: $nav-link-color;
              padding-bottom: 6px;
            }
            p {
              font-family: $inter;
              font-style: normal;
              font-weight: normal;
              font-size: 13px;
              line-height: 16px;
              margin-bottom: 0;
              color: $theme-secondary-text;
            }
            &:hover {
              background: $tab-active-bg;
              span {
                color: $theme-obstruct;
                font-weight: 600;
              }
              p {
                color: $highlighted-icons;
              }
              // .profile-avtar {
              //   display: none;
              // }
              // .profile-avtar-selected {
              //   display: block;
              // }
            }
            &.active {
              background: $tab-active-bg;
              padding: 12px 0 11px 45px;
              span {
                color: $theme-obstruct;
                font-weight: 600;
              }
              p {
                color: $highlighted-icons;
              }
              // .profile-avtar {
              //   display: none;
              // }
              // .profile-avtar-selected {
              //   display: block;
              // }
            }
          }
        }
      }
      .back-btn-wrapper {
        padding-bottom: 8px;
        padding-top: 18px;
        i {
          color: $highlighted-icons;
        }
        span {
          font-style: normal;
          font-weight: 400 !important;
          font-size: 15px;
          line-height: 18px;
          font-family: $inter !important;
          color: $highlighted-icons;
        }
      }
      .name-wrapper {
        padding: 12px 15px;
        background: $bg-name;
        display: flex;
        align-items: center;
        .big-image {
          max-width: unset !important;
          height: 30% !important;
          width: 30% !important;
        }
        img {
          max-width: 42px;
          height: 42px;
          width: 42px;
          margin-right: 17px;
        }
        a {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          color: $white;
          font-family: $inter;
          cursor: pointer;
        }
        @media (max-width: 767px) {
          padding: 10px;
          img {
            width: 35px;
            height: 35px;
          }
          a {
            font-size: 16px;
          }
        }
      }
      .profile-data-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 16px 0 8px 15px;
        width: 100%;
        border-bottom: 0.6px solid $tab-bg;
        @media (max-width: 767px) {
          padding: 15px 10px;
        }
        @media (max-width: 500px) {
          flex-direction: column;
          button:first-child {
            margin-bottom: 10px;
          }
        }
        &.border-line {
          position: relative;
          &::after {
            content: '';
            position: absolute;
            background: url(../../../assets/images/tour-divider.svg) no-repeat;
            width: 1px;
            height: 200px;
            top: 15px;
            left: 42%;
            @media (max-width: 767px) {
              display: none;
            }
          }
        }
        .input-field {
          width: 50%;
          padding-bottom: 14px;
          .item-card {
            .item-card-name {
              font-style: normal;
              font-weight: normal;
              font-size: 13px;
              line-height: 16px;
              padding-bottom: 4px;
              color: $theme-secondary-text;
              font-family: $inter;
            }
            .item-card-value {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              color: $nav-link-color;
              font-family: $inter;
            }
          }
          @media (max-width: 767px) {
            width: 100%;
          }
        }
        .links-wrapper {
          width: 50%;
          padding-bottom: 23px;
          text-align: left;
          font-style: normal;
          font-weight: 500 !important;
          font-size: 14px;
          line-height: 17px;
          font-family: $inter;
          color: $links-color !important;
          text-transform: capitalize !important;
          i {
            color: $theme-obstruct;
            margin-right: 15px;
            font-size: 14px;
          }
          @media (max-width: 767px) {
            width: auto;
            padding-bottom: 5px;
          }
        }
      }
      .other-plots-wrapper {
        h3 {
          padding-top: 20px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          margin-bottom: 0;
          font-family: $inter;
          color: $nav-link-color;
        }
        .no-occupant {
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          line-height: 15px;
          color: $theme-secondary-text !important;
        }
        ul {
          list-style: none;
          padding: 0;
          position: relative;
          margin-bottom: 0;
          li {
            padding: 16px 0 16px 45px;
            cursor: pointer;
            position: relative;
            img {
              position: absolute;
              top: 24px;
              left: 10px;
            }
            span {
              font-family: $inter;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              color: $nav-link-color;
              padding-bottom: 6px;
            }
            p {
              font-family: $inter;
              font-style: normal;
              font-weight: normal;
              font-size: 13px;
              line-height: 16px;
              margin-bottom: 0;
              color: $theme-secondary-text !important;
            }
          }
        }
      }
      .map-selected-wrapper {
        ul {
          list-style: none;
          padding: 0;
          position: relative;
          margin-bottom: 0;
          li {
            padding: 12px 0 11px 45px;
            cursor: pointer;
            position: relative;
            img {
              position: absolute;
              top: 20px;
              left: 10px;
            }
            span {
              font-family: $inter;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              color: $nav-link-color;
              padding-bottom: 6px;
            }
            p {
              font-family: $inter;
              font-style: normal;
              font-weight: normal;
              font-size: 13px;
              line-height: 16px;
              margin-bottom: 0;
              color: $theme-secondary-text;
            }
            &:hover {
              background: $tab-active-bg;
              span {
                color: $theme-obstruct;
              }
              p {
                color: $highlighted-icons;
              }
            }
            &.active {
              background: $tab-active-bg;
              span {
                color: $theme-obstruct;
              }
              p {
                color: $highlighted-icons;
              }
            }
          }
        }
      }
      .input-field {
        input {
          font-family: $inter !important;
        }
      }
    }
  }
  .tour-information {
    color: $nav-link-color;
    margin-top: 18px;
    padding: 0;
    font-size: 14px;
    line-height: 17px;
    font-family: $inter;
    .back-to-list:hover {
      cursor: pointer;
      text-decoration: underline;
    }
    &.tour-information-with-search {
      @include flex(flex-start, center);
      position: relative;
      > span {
        margin-right: 20px;
        min-width: 120px;
      }
      .fa-search {
        position: absolute;
        left: 150px;
        z-index: 2;
        color: $theme-secondary-text;
        @media (max-width: 500px) {
          top: 50px;
          left: 10px;
        }
      }
      .datalist-input-container {
        width: 100%;
        max-width: 300px;
        .input-field input {
          padding-left: 30px;
        }
      }
      @media (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
        > span {
          margin: 0 0 15px;
        }
      }
    }
  }

  .tour-group-btn {
    margin-top: 30px;
    i.fa-plus {
      font-size: 15px;
      padding-right: 3px;
    }
    @media (max-width: 767px) {
      margin: 25px 0;
    }
  }

  .tour-information-wrapper {
    position: relative;
    border-bottom: 0.6px solid $tab-bg;
    padding: 24px 22px;
    cursor: pointer;

    &.tour-information-detail-wrapper {
      padding: 0;
      border-bottom: none;
    }

    .location-detail-wrapper {
      padding: 5px 30px;

      .actions-container {
        border-bottom: 0.6px solid $tab-bg;
        padding: 23px 0 0 15px;
      }

      .description-container {
        padding: 10px 0;
        span {
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: $theme-secondary-text;
        }
      }

      .caraousel-container {
        border-bottom: 0.6px solid $tab-bg;
        padding-bottom: 10px;

        .carousel-indicators {
          display: none;
        }

        .carousel-item {
          text-align: center;
          height: 300px;
          img {
            max-width: 413px;
            max-height: 300px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .carousel-control-prev-icon,
        .carousel-control-next-icon {
          border: 4px solid $highlighted-icons;
          background-color: $highlighted-icons;
          border-radius: 50%;
          opacity: 1;
        }
      }
    }

    @media (max-width: 767px) {
      padding: 15px 0 15px 10px;
      .location-detail-wrapper {
        padding: 5px;
        .carousel-control-prev,
        .carousel-control-next {
          width: 20px;
        }
        .carousel-item {
          img {
            max-width: calc(100% - 50px) !important;
          }
        }
      }
    }

    &.toggleOpen {
      background: $tab-active-bg;

      .title-text {
        color: $theme-text;
        font-weight: 600;
      }

      .title-icon {
        color: $nav-link-color;
      }

      .title-link {
        span {
          color: $theme-secondary-text;
        }
      }

      .left-spacing {
        color: $theme-secondary-text;
      }
    }

    .flex {
      display: flex;
      align-items: center;
    }

    .icon-container {
      width: 18px;
      text-align: center;
      line-height: 0;
      .fas.fa-archway {
        color: $nav-link-color;
      }
      img {
        max-width: 100%;
        height: 16px;
        object-fit: cover;
      }
    }

    .title-text {
      margin-left: 25px;
      color: $nav-link-color;
      font-size: 18px;
      line-height: 22px;
      font-weight: 500;
      font-family: $inter;
      @media (max-width: 767px) {
        margin-left: 15px;
        font-size: 16px;
        max-width: calc(100% - 110px);
      }
    }

    .title-icon {
      color: $nav-link-color;
      font-size: 16px;
    }

    .title-link {
      position: absolute;
      right: 40px;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.2px;
      color: $links-color;
      border-bottom: none;
      .btn {
        border-bottom: none !important;
      }
      span {
        color: $links-color;
        font-family: $inter !important;
        font-style: normal;
        font-weight: 500 !important;
      }
      @media (max-width: 767px) {
        right: 34px;
      }
    }
    .left-spacing {
      margin-left: 6px;
      position: absolute;
      font-size: 20px;
      color: $links-color;
    }
    .detail-section {
      margin: 15px 0;
      color: $nav-link-color;
      font-weight: 500;
      cursor: pointer;
      @media (max-width: 767px) {
        margin-bottom: 0;
      }
      .text-header {
        color: $theme-secondary-text;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
      }
      .detail-index {
        padding: 18px 24px;
        @media (max-width: 767px) {
          padding: 15px;
        }
        &.detail-index-list:hover {
          background: $white;
          color: $theme-text;
          .arrow-open {
            display: block;
            padding-left: 7px;
            font-weight: 700;
          }
          .serial-number {
            @include flex(center, center);
            @include circle(28px);
            min-width: 28px;
            background-color: $bg-name;
            color: $white;
            border: 1px solid $bg-name;
            margin-right: 20px;
            font-family: $inter;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
          }

          .item-sub-text {
            margin-left: 48px;
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: $theme-secondary-text;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .selected-link {
            margin-left: 5px;
            font-weight: 600;
          }
          .item-name {
            color: $theme-text;
            font-weight: 700;
          }
        }

        .picture-name {
          display: flex;
          flex-direction: column;
          font-size: 13px;

          .title {
            font-size: 20px;
            font-weight: 700;
          }
          @media (max-width: 767px) {
            line-height: 22px;
          }
        }

        &.detail-page {
          background-color: $bg-name;
          color: $white;
          .serial-number {
            @include flex(center, center);
            @include circle(28px);
            min-width: 28px;
            background-color: $white;
            color: $theme-text;
            border: 1px solid $white;
            margin-right: 20px;
            font-family: $inter;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
          }
          .item-name {
            color: $white;
          }
        }

        .serial-number {
          @include flex(center, center);
          @include circle(28px);
          background-color: $nav-link-color;
          color: $white;
          border: 1px solid $nav-link-color;
          margin-right: 20px;
          font-family: $inter;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
        }
        .item-sub-text {
          margin-left: 48px;
          font-family: $inter;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 20px;
          color: $theme-secondary-text;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .item-name {
          font-family: $inter;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: $nav-link-color;
        }
        .arrow-open {
          display: none;
        }
      }
    }
  }

  .map-tour-toggles-menu {
    position: absolute;
    top: 12px;
    right: 25px;
    z-index: 99;
    .toggle-header {
      padding: 16px 20px;
      border-bottom: 1px solid $theme-border-gray-fade;
      span {
        font-weight: bold;
        color: $brand-primary;
        font-family: $lato;
        font-style: normal;
        font-size: 18px;
        line-height: 22px;
      }
    }

    .toggle-row {
      padding: 16px 16px 0 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      label {
        margin: 0;
      }
      span {
        font-family: $lato;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        margin-left: 17px;
        color: $theme-text;
        margin-bottom: 8px;
      }
      &:last-of-type {
        padding-bottom: 24px;
      }
    }

    .default {
      background-color: $white;
      color: $theme-text;
    }
    .dropdown {
      .cemetery {
        font-weight: normal !important;
        font-size: 14px;
        line-height: 17px;
        color: $theme-text;
        background: $white;
        box-shadow: 0px 2px 4px rgba(208, 208, 208, 0.6);
        border-radius: 4px;
        padding: 7px 16px;
        min-width: 96px;
        width: 96px;
        position: absolute;
        right: 205px;
        &:hover {
          background-color: $white !important;
          color: $theme-text !important;
          opacity: 1;
        }
        &:focus {
          background-color: $white !important;
          color: $theme-text !important;
        }
        &:active {
          background-color: $white !important;
          color: $theme-text !important;
        }
        @media (max-width: 767px) {
          right: 110px;
        }
      }
      .dropdown-menu {
        top: 45px !important;
        min-width: 258px;
        background: $white;
        box-shadow: 0px 2px 4px rgba(208, 208, 208, 0.5);
        border-radius: 4px !important;
        left: -135px !important;
        &::before {
          right: 105px;
        }
        @media (max-width: 767px) {
          left: auto !important;
          right: 0 !important;
          transform: none !important;
        }
      }
    }
    .buttons-group {
      .nav-tabs {
        border-bottom: none;
        .road-trail-wrapper {
          box-shadow: 0px 2px 4px rgba(208, 208, 208, 0.5);
          border-radius: 4px;
          font-style: normal;
          font-weight: normal !important;
          font-size: 14px;
          line-height: 17px;
          padding: 7px 16px;
          min-width: 96px;
          width: 96px;
          color: $theme-secondary-text;
          margin-bottom: 0;
          border: none;
          background: $white;
          cursor: default;
          &.active {
            border: 1px solid $theme-secondary-text;
            border-radius: 4px 0 0 4px;
            color: $theme-text;
            background-color: $white !important;
          }
          &.hybrid {
            &.active {
              border-radius: 0 4px 4px 0;
            }
          }
        }
      }
      @media (max-width: 767px) {
        .nav {
          flex-direction: column;
        }
      }
    }
  }
  .tooltip-data {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 0;
    padding: 15px 25px;
  }
}

.popup-content-wrapper {
  padding: 0;
  max-height: 530px;
  overflow: auto;
  margin: 20px 0;
  ul {
    list-style: none;
    padding: 0;
    position: relative;
    margin-bottom: 0;
    li {
      padding: 12px 20px;
      cursor: pointer;
      h6 {
        font-family: $lato;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: $theme-text;
        padding-bottom: 4px;
        margin-bottom: 0;
      }
      p {
        font-family: $inter;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 0;
        color: $theme-secondary-text;
      }
      &:hover {
        background: $tab-active-bg;
        h6 {
          color: $theme-obstruct;
        }
        p {
          color: $highlighted-icons;
        }
      }
      &.active {
        background: $tab-active-bg;
        h6 {
          color: $theme-obstruct;
        }
        p {
          color: $highlighted-icons;
        }
      }
    }
  }

  .property-title {
    color: $bg-name;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    padding: 12px 20px;
  }

  .tour-layout-list-hr {
    opacity: 0.2;
    border: 1px solid $theme-tertiary-text;
    margin: 12px 0 0 0;
  }

  .tour-layout-list-title {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: $theme-obstruct;
  }

  .tour-layout-list-subtitle {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: $theme-secondary-text;
  }

  .map-loading {
    padding: 0 16px;

    span {
      padding-bottom: 8px;
      font-size: 14px;
    }

    .spinner {
      margin-top: 8px;
      display: flex;
      justify-content: center;
    }
  }

  .map-no-data {
    padding: 0 16px;
    display: flex;
    flex-direction: column;

    .property-title {
      padding: 0;
    }

    span.sub-title {
      font-size: 14px;
    }
  }
}

.tour-name-field {
  width: 50% !important;
}

.mapboxgl-map.atlas-map {
  .azure-maps-control-container {
    margin-right: 25px;
  }
}

.map-search-wrapper.map-search-wrapper-manage-map-tour {
  .mapboxgl-map.atlas-map {
    height: 100% !important;
    .mapboxgl-canvas.atlas-map-canvas {
      height: 100% !important;
    }
  }
  .arrow {
    top: 0px !important;
  }

  .wrapper-header-map-tour {
    @include flex(space-between, center);
    position: absolute;
    padding: 10px 25px 10px 62px;
    background-color: $white;
    height: 85px;
    z-index: 999;
    width: 100%;
    border-bottom: 0.6px solid $tab-bg;
    .back-btn-wrapper {
      padding-bottom: 10px;
    }
    h2 {
      font-family: $lato;
      font-weight: 700;
      font-size: 28px;
      color: $nav-link-color;
      margin: 0;
    }
    .section-right {
      .btn.button {
        min-width: 190px;
        min-height: 46px;
        text-transform: uppercase;
        &.btn-tour-group {
          margin-left: 25px;
          i.fa-plus {
            padding-right: 5px;
          }
        }
      }
    }
    @media (max-width: 1023px) {
      padding: 10px;
    }
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      height: 140px;
      h2 {
        font-size: 24px;
        margin-bottom: 10px;
      }
      .section-right {
        .btn.button {
          min-width: 135px;
          font-size: 12px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          &.btn-tour-group {
            margin-left: 15px;
          }
        }
      }
    }
  }
  .map-tour-toggles-menu {
    top: 100px;
    @media (max-width: 767px) {
      top: 120px;
    }
  }
  .map-search-module {
    &.map-tour {
      height: calc(100vh - 85px);
      top: 85px;
      .loader-container {
        height: unset;
      }
      .nav-tabs {
        border-top: 0;
      }
      .tab-content {
        max-height: calc(100vh - 160px);
      }
      @media (max-width: 1023px) {
        z-index: 100;
      }
      @media (max-width: 767px) {
        height: calc(100vh - 195px);
        top: 140px;
        .tab-content {
          max-height: calc(100vh - 250px);
        }
      }
    }
  }
  .container-tour-information {
    position: relative;
    border-bottom: 0.6px solid #cecece;
    padding-right: 20px;
    padding-bottom: 20px;
    @media (max-width: 767px) {
      padding-bottom: 15px;
    }
    &.toggle-open-wrapper {
      background-color: $tab-active-bg;
    }
    .tour-information-wrapper {
      border-bottom: none;
      padding-bottom: 0;
    }
    .button-holder {
      display: flex;
      justify-content: flex-end;
      .button i.fa-plus {
        padding-right: 5px;
        font-size: 13px;
      }
    }
    .dropdown-wrapper {
      position: absolute;
      right: -10px;
      top: calc(35px - 14px);
      .dropdown .dropdown-menu {
        top: 10px !important;
        .disabled.dropdown-item {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
      @media (max-width: 767px) {
        top: 15px;
        .dropdown .dropdown-menu {
          top: 20px !important;
          max-height: 250px;
          overflow-y: auto;
          min-width: 0;
          width: 250px;
        }
      }
    }
    .wrapper-tour-location {
      position: relative;
      padding-right: 20px;
      .dropdown-wrapper-location {
        right: -7px;
        top: calc(32px - 14px);
        .dropdown .dropdown-menu {
          @media (max-width: 767px) {
            top: 10px !important;
          }
        }
      }
      &:hover {
        background-color: $white;
      }
    }
  }
}

.modal-dialog.modalProperties.modal-add-edit-garden.modal-add-edit-tour-group {
  .modal-content .modal-body {
    min-width: 800px;
    .form-add-edit-garden {
      padding-bottom: 25px;
      .container-name-icon {
        margin-bottom: 70px;
        align-items: flex-end;
        .input-field {
          margin-bottom: 0;
        }
      }
      .container-textarea {
        margin-bottom: 45px;
        .length-validate-note {
          float: right;
          font-style: italic;
        }
      }
      .buttons-container {
        padding-bottom: 0;
      }
    }
    @media (max-width: 767px) {
      min-width: 0;
      .form-add-edit-garden {
        .container-name-icon {
          > div {
            max-width: 100%;
            flex: 0 0 100%;
            &:nth-of-type(1) {
              margin-bottom: 30px;
            }
          }
        }
        .custom-file-upload {
          flex-direction: row;
          align-items: center;
          .button-browse {
            margin-bottom: 0;
            margin-right: 20px;
          }
        }
        .file-size-limit {
          top: auto;
        }
      }
    }
  }
  .container-custom-file-upload {
    .file-size-limit {
      position: absolute;
      bottom: -25px;
    }
    .custom-file-upload {
      min-height: 50px;
      .button-browse {
        min-width: 150px !important;
        padding: 7px 10px;
      }
      .selected-file {
        align-items: flex-start;
        .file-name {
          @include flex(center, center);
          @include circle(50px);
          border: 1px solid rgba($brand-primary, 0.2);
          .tour-icon {
            max-width: 20px;
            max-height: 20px;
          }
        }
        .close-icon {
          margin-left: -15px;
          margin-top: -7px;
        }
      }
    }
  }
}

.modal-add-edit-tour-location {
  .modal-body {
    max-height: calc(100vh - 150px) !important;
    .datalist-input-container {
      + span {
        position: absolute;
        top: calc(100% + 5px);
        line-height: 1.2;
        color: $error-msg;
        strong {
          color: #333;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
  .custom-file-upload .no-file {
    font-size: 17px;
  }
  .tour-gallery-warpper {
    display: flex;
    gap: 25px;
    flex-wrap: nowrap;
    overflow: auto;
    margin: -10px 0 40px;
    .gallery-item {
      position: relative;
      img {
        max-width: 250px;
        object-fit: cover;
        @media (max-width: 767px) {
          max-width: 150px;
        }
      }
      .close-icon {
        @include flex(center, center);
        @include circle(24px);
        background-color: $white;
        color: $theme-tertiary-text;
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
      }
    }
  }
}

.modal-dialog.modalProperties.modal-add-edit-garden.delete-document-wrapper.delete-tour-location-wrapper {
  .form-add-edit-garden {
    .delete-lenght-note {
      bottom: auto;
      top: 190px;
    }
    .input-field {
      margin-bottom: 60px;
    }
  }
  .checkbox-wrapper-delete {
    margin-top: -40px;
    .checkbox-delete {
      display: flex;
      label.checkbox-inline {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        span.label {
          font-weight: 700;
        }
        input[type='checkbox'] + span {
          margin: 0 10px 0 0 !important;
        }
      }
    }
  }
}

.modal-dialog.modalProperties.modal-add-edit-garden.modal-reorder-tour-location {
  .modal-content .modal-body {
    padding-left: 100px;
    max-height: 80vh;
    max-width: 700px;
    p.note {
      margin: 0 0 40px;
    }
    ul.tour-location-list {
      list-style: none;
      padding: 0;
      margin: 0 0 30px;
      counter-reset: list;
      max-width: 80%;
      li {
        display: flex;
        flex-direction: column;
        padding: 15px 10px 15px 70px;
        position: relative;
        margin-bottom: 10px;
        .title {
          font-family: $inter;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: $nav-link-color;
        }
        .description {
          font-family: $inter;
          font-weight: normal;
          font-size: 13px;
          line-height: 20px;
          color: $theme-secondary-text;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin: 5px 0 0;
        }
        &::before {
          position: absolute;
          content: '';
          background: url(../../../assets/images/reorder-union.png) no-repeat;
          background-size: cover;
          width: 6px;
          height: 10px;
          top: 19px;
          left: 0;
        }
        &::after {
          position: absolute;
          counter-increment: list;
          content: counter(list);
          @include flex(center, center);
          @include circle(28px);
          color: $white;
          background-color: $nav-link-color;
          font-family: $inter;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          left: 20px;
          top: 9px;
        }
        &:empty {
          &:before,
          &::after {
            display: none;
          }
        }
      }
    }
    @media (max-width: 767px) {
      max-width: 100%;
      padding-left: 15px;
      p.note {
        margin-bottom: 20px;
      }
      ul.tour-location-list {
        max-width: 100%;
        li {
          margin-bottom: 0;
        }
      }
    }
  }
}

.modal-dialog.modalProperties.modal-add-edit-garden.modal-confirmation {
  .modal-content .modal-body {
    .description {
      @include font($lato, 400, 18px, $theme-tertiary-text);
      line-height: 29px;
      margin-bottom: 40px;
    }
    .buttons-container {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 15px;
    }
  }
}
