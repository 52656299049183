.permissions-wrapper {
  position: relative;
  .btn-add-role {
    position: absolute;
    top: 45px;
    right: 43px;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
    padding: 12px 19px;
    background: $brand-secondary;
    box-sizing: border-box;
    border-radius: 4px;
  }

  @media (max-width: 767px) {
    .page-section .page-header {
      margin-bottom: 80px;
    }
    .btn-add-role {
      left: 0;
      right: auto;
      top: 105px;
    }
  }

  .search-results-wrapper {
    .header-search-results {
      @include flex(space-between, center);
      background-color: $theme-table-nth;
      min-height: 58px;
      padding: 15px;
      h2 {
        @include font($lato, 700, 18px, $theme-secondary-text);
        text-transform: uppercase;
        margin-bottom: 0;
        line-height: 22px;
        display: inline-block;
        margin: 0 56px 0 9px;
      }
      @media (max-width: 767px) {
        .header-leftsection-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          h2 {
            margin: 0 0 15px;
          }
        }
      }
    }
    .table-container .table {
      tbody tr {
        &:nth-last-of-type(-n + 3) {
          .select-wrapper .select-dropdown__menu {
            top: auto;
            bottom: 100%;
            &:before {
              top: auto;
              bottom: -6px;
              transform: rotate(315deg);
            }
          }
        }
      }
      thead tr th,
      tbody tr td {
        &:nth-of-type(1) {
          padding-left: 10px;
        }
      }
      .dropdown-with-dots {
        .dropdown-menu {
          left: 15px !important;
          &::before {
            right: 12px;
          }
        }
      }
      .no-results-container {
        padding: 7rem 0;
        p {
          margin-bottom: 5rem;
        }
      }
    }
    .table-container-search-results {
      .table {
        display: flex;
        flex-flow: column;
        @media (max-width: 1100px) {
          display: block;
          overflow-x: auto;
        }

        thead tr th {
          height: 58px !important;
        }

        thead tr th,
        td {
          text-align: left;
          &:nth-of-type(1) {
            width: 10% !important;
          }
          &:nth-of-type(2) {
            width: 8% !important;
          }
          &:nth-of-type(3) {
            width: 8% !important;
          }
          &:nth-of-type(4) {
            width: 8% !important;
          }
          &:nth-of-type(5) {
            width: 10% !important;
          }
          &:nth-of-type(6) {
            width: 10% !important;
          }
          &:nth-of-type(7) {
            width: 10% !important;
          }
          &:nth-of-type(8) {
            width: 10% !important;
          }
          &:nth-of-type(9) {
            width: 8% !important;
          }
          &:nth-of-type(10) {
            width: 11% !important;
          }
          &:nth-of-type(11) {
            width: 7% !important;
          }
          @media (max-width: 1100px) {
            width: 150px !important;
            min-width: 150px !important;
          }
        }

        tbody {
          overflow-y: scroll;
          max-height: 500px;
        }

        tbody.empty-results-tbody {
          overflow: visible !important;
          max-height: 368px !important;

          tr {
            height: 368px;

            td {
              height: 100%;
              width: 100% !important;
              padding: 0px !important;

              div.no-results-container {
                width: 100% !important;
              }
            }
          }
        }

        .edit-btn {
          color: #212121 !important;
          font-weight: 400 !important;
        }

        tbody tr td {
          &.added-record {
            color: #2e7d32 !important;
          }
          &.deleted-record {
            color: #f57c00 !important;
          }
          &:nth-of-type(10) {
            font-weight: 700 !important;
            .select-dropdown__option {
              font-weight: 400 !important;
            }
          }
          .icon-holder {
            i {
              height: 12px;
              width: 12px;
              font-size: 12px;
              margin-right: 8px;
            }
          }
        }

        tbody tr.active-record {
          .select-wrapper {
            margin-bottom: 0;
          }
          td {
            &:nth-of-type(1) {
              padding-left: 10px;
            }
            &:nth-of-type(11) {
              padding-left: 15px;
              padding-right: 3px;
            }
            font-weight: 700;
            padding: 0;
            .select-dropdown__option {
              font-weight: 400 !important;
            }
          }
        }
      }
      tbody td {
        .checkbox-wrapper {
          &.records-checkbox-wrapper {
            margin-bottom: 0;
            label {
              &.checkbox-inline {
                input[type='checkbox'] {
                  + span {
                    width: 16px;
                    border: 2px solid $border-checkbox;
                    height: 16px;
                    margin-left: 0 !important;
                    @include border-radius(2px);
                    @include transition(all 0.3s ease-in-out);
                  }
                }
              }
            }
          }
        }
        a {
          line-height: 19px;
          @include font($lato, 400, 16px, $brand-secondary);
        }
      }
    }
    .table-container-search-results-user {
      .table {
        thead tr th,
        tbody tr td {
          text-align: left;
          word-break: break-word;
          &:nth-of-type(1) {
            width: 240px;
          }
          &:nth-of-type(2) {
            width: 300px;
          }
          &:nth-of-type(3) {
            width: 400px;
          }
          &:nth-of-type(4) {
            width: 150px;
          }
          &:nth-of-type(5) {
            width: 168px;
          }
        }
      }
      tbody td {
        .checkbox-wrapper {
          &.records-checkbox-wrapper {
            margin-bottom: 0;
            label {
              &.checkbox-inline {
                input[type='checkbox'] {
                  + span {
                    width: 16px;
                    border: 2px solid $border-checkbox;
                    height: 16px;
                    margin-left: 0 !important;
                    @include border-radius(2px);
                    @include transition(all 0.3s ease-in-out);
                  }
                }
              }
            }
          }
        }
        a {
          line-height: 19px;
          @include font($lato, 400, 16px, $brand-secondary);
        }
      }
    }
  }
  .select-wrapper {
    .select-dropdown__menu {
      &::before {
        left: 45%;
      }
    }
  }
}
