.sync-events-wrapper {
  position: relative;
  .event-list-filters-wrapper {
    margin-bottom: 25px;
    .react-datepicker__close-icon {
      padding-right: 40px;
    }
    .event-list-filters {
      background-color: white;
      padding-top: 25px;
    }
  }
  .event-list-wrapper {
    .header-event-list {
      @include flex(space-between, center);
      background-color: $theme-table-nth;
      min-height: 66px;
      padding: 5px 25px;
      h2 {
        @include font($lato, 700, 18px, $brand-primary);
        text-transform: uppercase;
        margin-bottom: 0;
      }
    }
    .table-container .table {
      thead tr th,
      tbody tr td {
        &:nth-of-type(1) {
          padding-left: 25px;
        }
      }
      .dropdown-with-dots {
        .dropdown-menu {
          left: 15px !important;
          &::before {
            right: 12px;
          }
        }
      }
      .no-results-container {
        padding: 7rem 0;
        p {
          margin-bottom: 5rem;
        }
      }
    }
    .table-container-event-list {
      .table {
        thead tr th,
        tbody tr td {
          &:nth-of-type(1) {
            width: 5%;
          }
          &:nth-of-type(2) {
            width: 15%;
          }
          &:nth-of-type(3) {
            width: 10%;
          }
          &:nth-of-type(4) {
            width: 15%;
          }
          &:nth-of-type(5) {
            width: 5%;
          }
        }
      }
    }
  }
}

.modal-dialog.terminal-like-modal {
  min-width: 400px;
  .modal-content {
    .modal-header {
      background-color: gold !important;
      padding: 20px 30px !important;
      button::before {
        color: black;
      }
    }
    .modal-body {
      background-color: black;
      padding: 20px 30px !important;
      max-height: 400px;
      overflow: auto;
      white-space: nowrap;
      pre {
        padding: 20px;
        color: white;
      }
    }
  }
}
