.wrapper-create-call-flow {
  padding-bottom: 20px;
  .item-card.item-card-name {
    color: $theme-text;
  }
}
.wrapper-create-new-call {
  .breadcrumbs {
    margin-bottom: 20px;

    .breadcrumb-item {
      span {
        font-size: 14px;
        line-height: 14px;
        color: #616161 !important;
        font-weight: 500 !important;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      &:last-child {
        span:hover {
          text-decoration: none;
          cursor: initial;
        }
      }
    }
  }
  #form-search-caller-details {
    label {
      color: $theme-text;
      font-size: 16px;
      line-height: 19px;
    }
    .input-field {
      margin-bottom: 30px;
      label span {
        color: $theme-text;
      }
    }
    @media (max-width: 767px) {
      .row > div {
        max-width: 50%;
        flex: 0 0 50%;
      }
    }
    @media (max-width: 600px) {
      .row > div {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }
  #form-search-beneficiary {
    label {
      color: $theme-text;
      font-size: 16px;
      line-height: 19px;
      span {
        color: $theme-text;
      }
    }
    button {
      margin-top: 25px;
    }
    .checkbox-wrapper {
      display: flex;
      margin: 25px 0 0;
      label.checkbox-inline {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        span.label {
          font-weight: 700;
        }
        input[type='checkbox'] + span {
          margin: 0 10px 0 0 !important;
        }
      }
    }
    @media (max-width: 767px) {
      .row > div {
        max-width: 50%;
        flex: 0 0 50%;
      }
    }
    @media (max-width: 600px) {
      .row > div {
        max-width: 100%;
        flex: 0 0 100%;
        .input-field {
          margin-bottom: 30px;
        }
      }
      .checkbox-wrapper {
        margin-top: 0;
      }
    }
  }
  .table-container {
    .section-title-button {
      @include flex(space-between, center);
      margin-bottom: 15px;
      button.button-add-decedent.link {
        height: unset !important;
        font-size: 13px;
        padding-bottom: 0;
        min-width: 0;
        margin-top: 0 !important;
        &.button-add-decedent-small {
          font-size: 14px;
        }
      }
      .item-card.item-card-name {
        margin-bottom: 0;
      }
    }
    .table {
      tbody tr td {
        font-size: 13px;
        line-height: 16px;
        &.column-status {
          .input-radio {
            display: block;
          }
        }
        .radio {
          padding: 0 !important;
          margin: 0 !important;
          label {
            font-size: 13px !important;
            color: $theme-text !important;
          }
          input + span {
            width: 16px !important;
            height: 16px !important;
          }
        }
      }
      tbody tr.selected {
        td,
        label {
          font-weight: 700;
        }
      }
      thead tr th {
        font-size: 11px;
      }
      tbody tr td,
      thead tr th {
        padding: 5px 10px;
      }
    }
    .wrapper-buttons {
      margin-top: 25px;
      button {
        margin-top: 0 !important;
      }
      button:first-child {
        margin-right: 30px;
      }
      &.wrapper-buttons-clear {
        button:first-child {
          margin-right: 10px;
        }
      }
    }
    &.table-simillar-caller-details {
      margin-top: 25px;
      .table {
        tbody tr td,
        thead tr th {
          &:nth-of-type(1) {
            width: 20%;
          }
          &:nth-of-type(2) {
            width: 20%;
          }
          &:nth-of-type(3) {
            width: 20%;
          }
          &:nth-of-type(4) {
            width: 15%;
          }
          &:nth-of-type(5) {
            width: 15%;
          }
          &:nth-of-type(6) {
            width: 10%;
          }
        }
        @media (max-width: 767px) {
          display: block;
          overflow-x: auto;
          tbody tr td,
          thead tr th {
            width: 150px;
            min-width: 150px;
            &:nth-of-type(1) {
              width: 200px;
              min-width: 200px;
            }
            &:nth-of-type(5) {
              width: 250px;
              min-width: 250px;
            }
          }
        }
      }

      .table-buttons-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;

        .table-buttons-left {
          display: flex;
          align-items: center;

          button {
            height: 40px !important;
            font-size: 13px !important;
          }
        }

        .table-buttons-right {
          button {
            height: 40px !important;
            font-size: 13px !important;
          }
        }
      }
    }
    &.table-beneficiary-details {
      margin-top: 25px;
      .table {
        tbody tr td,
        thead tr th {
          &:nth-of-type(1) {
            width: 20%;
          }
          &:nth-of-type(2) {
            width: 20%;
          }
          &:nth-of-type(3) {
            width: 25%;
          }
          &:nth-of-type(4) {
            width: 9%;
          }
          &:nth-of-type(5) {
            width: 9%;
          }
          &:nth-of-type(6) {
            width: 9%;
          }
          &:nth-of-type(7) {
            width: 8%;
          }
        }
      }
      @media (max-width: 767px) {
        .section-title-button {
          flex-direction: column;
          align-items: flex-start;
          .item-card-name {
            margin-bottom: 5px;
          }
        }
        .table {
          display: block;
          overflow-x: auto;
          tbody tr td,
          thead tr th {
            width: 150px;
            min-width: 150px;
            &:nth-of-type(1) {
              width: 200px;
              min-width: 200px;
            }
            &:nth-of-type(3) {
              width: 250px;
              min-width: 250px;
            }
          }
        }
      }
    }
    &.table-contract-details {
      margin-top: 25px;
      .item-card-name {
        margin-bottom: 15px;
      }
      .table {
        tbody tr td,
        thead tr th {
          &:nth-of-type(1) {
            width: 28%;
          }
          &:nth-of-type(2) {
            width: 23%;
          }
          &:nth-of-type(3) {
            width: 15%;
          }
          &:nth-of-type(4) {
            width: 18%;
          }
          &:nth-of-type(5) {
            width: 16%;
          }
        }
        @media (max-width: 767px) {
          display: block;
          overflow-x: auto;
          tbody tr td,
          thead tr th {
            width: 150px;
            min-width: 150px;
            &:nth-of-type(1) {
              width: 200px;
              min-width: 200px;
            }
          }
        }
      }
    }
  }
}

.wrapper-call-success-message {
  @include flex-d(flex-start, center, column);
  .status-message {
    margin: 0 0 30px;
    text-align: center;
    .fa-check-circle {
      color: $brand-tertiary;
      font-size: 50px;
    }
    span {
      color: $theme-text;
      font-size: 20px;
      display: block;
      margin-top: 25px;
      @media (max-width: 600px) {
        font-size: 18px;
        br {
          display: none;
        }
      }
    }
  }
  .opi-ids {
    @include flex-d(flex-start, flex-end, column);
    margin-bottom: 45px;
    .item-card {
      align-items: center;
      width: 100% !important;
      margin-bottom: 15px;
      .item-card-value {
        padding-top: 0 !important;
        text-transform: capitalize;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    @media (max-width: 600px) {
      .item-card {
        flex-direction: column;
        align-items: flex-start;
        .item-card-value {
          padding: 5px 0 0 !important;
        }
      }
    }
  }
  button.button-proceed {
    min-width: 280px !important;
    @media (max-width: 600px) {
      min-width: 250px !important;
      font-size: 14px !important;
    }
  }
  &.wrapper-contract-success-message {
    padding-top: 20px;
    .status-message {
      margin-bottom: 80px;
      @media (max-width: 767px) {
        margin-bottom: 25px;
      }
    }
  }
}

.wrapper-contracts-beneficiary-listing {
  .section-searched-data {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    .item-card {
      width: 25%;
    }
    @media (max-width: 767px) {
      margin-bottom: 0;
      .item-card {
        width: 50%;
        margin-bottom: 20px;
      }
    }
  }
}

.wrapper-create-contract {
  padding-left: 20px;
  @media (max-width: 767px) {
    padding-left: 0;
  }
  #form-create-contract {
    label {
      color: $theme-text;
      font-size: 16px;
      line-height: 19px;
    }
    .select-wrapper {
      max-width: 320px;
    }
    .checkbox-create-contract {
      display: flex;
      label.checkbox-inline {
        display: flex;
        flex-direction: row-reverse;
        input[type='checkbox'] + span {
          margin: 0 10px 0 0 !important;
        }
      }
    }
  }
}

.container-property.container-property-create-call {
  height: 100%;
  .loader-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    .section-property-details.property-content-scroll {
      max-height: unset;
      flex-grow: 2;
      @media (max-width: 767px) {
        max-height: calc(100vh - 262px - 20px - 50px - 95px);
      }
    }
  }
}
