@import '../../../assets/styles/color';
@import '../../../assets/styles/mixins';

.datepicker-wrapper {
  .daterange-container {
    .react-datepicker-wrapper {
      width: 100%;
    }
  }
}

.react-datepicker-popper {
  font-family: $lato !important;
  transform: none !important;
  top: 100% !important;
  padding-top: 10px;
  &[data-placement^='bottom'],
  &[data-placement^='top'] {
    margin: 0 !important;
  }
  .react-datepicker {
    border: none;
    border-radius: 0;
    padding: 15px 30px 15px 15px;
    position: relative;
    box-shadow: 0 3px 13px rgba($black, 0.08);
    @media (max-width: 767px) {
      padding: 0;
    }
    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      left: 60px;
      box-sizing: border-box;
      border: 6px solid black;
      border-color: transparent transparent $white $white;
      transform-origin: 0 0;
      transform: rotate(135deg);
      box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.07);
    }
    .react-datepicker__triangle {
      display: none;
    }
    .react-datepicker__header {
      border: none;
      background-color: $white;
      padding-top: 0;
      .react-datepicker__header__dropdown {
        > div {
          margin: 0 5px;
          .react-datepicker__month-select,
          .react-datepicker__year-select {
            background-color: $white;
            height: 24px;
          }
        }
      }
      .react-datepicker__current-month {
        margin-bottom: 5px;
      }
      .react-datepicker__day-names {
        .react-datepicker__day-name {
          width: 2rem;
          line-height: 2rem;
          font-size: 15px;
        }
      }
    }
    .react-datepicker__month-container {
      float: none;
      margin-bottom: 15px;
    }
    .react-datepicker__month {
      margin: 0;
      .react-datepicker__week {
        .react-datepicker__day {
          font-size: 15px;
          color: $theme-text;
          width: 2rem;
          line-height: 2rem;
          border-radius: 50% !important;
          &.react-datepicker__day--today {
            background-color: $brand-tertiary;
          }
          &.react-datepicker__day--selected {
            color: $white;
            background-color: $brand-primary;
          }
          &.react-datepicker__day--outside-month {
            color: $theme-tertiary-text;
          }
          &.react-datepicker__day--disabled {
            color: $theme-tertiary-text;
            cursor: not-allowed;
          }
        }
      }
    }
    .react-datepicker__input-time-container {
      @include flex(center, center);

      float: none;
      text-align: unset;
      margin: 0;
      .react-datepicker-time__caption {
        font-size: 16px;
        color: $theme-text;
        font-weight: 500;
      }
      .react-datepicker-time__input-container {
        margin-left: 15px;
        display: block;
        .react-datepicker-time__input {
          height: auto;
          margin-left: 0;
          input {
            margin-left: 0;
            width: 120px;
            min-width: 0;
            padding: 5px 10px;
          }
        }
      }
    }
  }
}

.date-picker-at-end {
  .react-datepicker-popper {
    left: auto !important;
    right: 0;
    .react-datepicker:before {
      left: auto;
      right: 60px;
    }
    @media (max-width: 767px) {
      left: 0 !important;
      right: auto;
      .react-datepicker:before {
        left: 60px;
        right: auto;
      }
    }
  }
}
