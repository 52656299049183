.filters-buttons-container {
  display: flex;
  align-items: center;

  .btn-refresh {
    width: 120px;
    height: 43px;
    margin-right: 16px;

    span {
      margin-right: 4px;
    }
  }
}
