.login {
  @include flex-d(center, center, column);
  height: 100vh;
  width: 100%;
  @media (max-width: 767px) {
    padding: 40px 10px;
  }

  @media (max-width: 767px) and (max-height: 500px) {
    height: unset;
  }

  .subject {
    @include font($lato, 400, 18px, $theme-tertiary-text);
    line-height: 21px;
    margin: 0 0 20px;
  }

  .content {
    border: 1px solid rgba(151, 151, 151, 0.2);
    width: 410px;
    border-radius: 4px;
    background-color: $white;
    box-shadow: 0px 2px 4px rgba(208, 208, 208, 0.2);

    .logo-container {
      @include flex(center, center);
      min-height: 100px;
      padding: 10px;
      border-bottom: 1px solid $brand-default;

      .logo {
        width: 212px;
        object-fit: cover;
        max-width: 100%;
      }
    }

    .form-login {
      padding: 35px 40px 60px;
      .login-button {
        margin: 50px 0 0;
        width: 100%;
        text-transform: capitalize !important;
      }
    }

    .form-group {
      display: flex;
      flex-direction: column-reverse;
      margin: 0 0 20px;
      height: 50px;

      .form-error {
        color: $theme-error-text;
      }

      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }

      label {
        @include font($lato, 700, 16px, $theme-secondary-text);
        @include transition(all 0.3s ease-in-out);
        margin: 0 0 3px;
        line-height: 19px;
        position: relative;
      }

      input {
        @include font($lato, 400, 15px, $theme-text !important);
        line-height: 21px;
        @include border-radius(0);
        @include transition(all 0.3s ease-in-out);
        border: 0;
        border-bottom: 1px solid rgba($theme-tertiary-text, 0.6);
        height: 30px;
        padding: 10px 0;
        outline: none;
        background-color: transparent !important;
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          transition: background-color 5000s ease-in-out 0s;
        }
        &:focus + label {
          font-size: 12px;
          line-height: 14px;
          top: -4px;
        }
      }
    }
    @media (max-width: 767px) {
      width: 100%;
      max-width: 410px;
      .logo-container {
        min-height: 80px;
        img {
          width: 180px;
        }
      }
      .form-login {
        padding: 25px 20px 35px;
        .login-button {
          margin-top: 30px;
        }
      }
    }
  }
}
